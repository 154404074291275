import React, { useState } from "react";
import { IContact } from "./types";
// import IMessage from '../entities/Meta/message';

export const ChatContext: any = React.createContext<{
  currentContact: IContact;
  handleContact(value: IContact): void;
}>({
  currentContact: {} as IContact,
  handleContact: () => {},
});
interface Props {
  children: any;
}

const ChatProvider: React.FC<Props> = ({ children }) => {
  const [currentContact, setCurrentContact] = useState<IContact>(
    {} as IContact
  );
  const handleContact = (value: IContact) => setCurrentContact(value);
  return (
    <ChatContext.Provider value={{ currentContact, handleContact }}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
