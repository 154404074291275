import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  SetStateAction,
} from "react";
import EmpresaContext from "../../../../../../contexts/Empresa";
import api from "../../../../../../services/api";

import Select2 from "react-select2-wrapper";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import CurrencyInput from "react-currency-input";
import {
  Spinner,
  Button,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";

const FormProdutos = ({ history, produtoId, onClose, empresaId }, ...props) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [produto, setProduto] = useState({});
  const [nome, setNome] = useState(null);
  const [tipoPtoduto, setTipoProduto] = useState(null);
  const [situacao, setSituacao] = useState("A");
  const [preco, setPreco] = useState(null);

  const [tiposProdutos, setTiposProdutos] = useState([]);

  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(null);
  const [saving, setSaving] = useState(false);

  const notificationAlert = useRef();

  useEffect(() => {
    if (tiposProdutos.length === 0) loadTiposProdutos();
  }, []);

  useEffect(() => {
    if (produtoId) loadProduto(produtoId);
  }, [produtoId]);

  useEffect(() => {
    if (produto.id) {
      setNome(produto.nome);
      setPreco(produto.preco);
      setTipoProduto(produto.tipo_produto_id);
      setSituacao(produto.situacao);
    }
  }, [produto]);

  async function loadProduto(id) {
    try {
      const response = await api.get(
        `common/empresas/${empresaId}/produtos/${id}`
      );
      setProduto(response.data);
    } catch (error) {
      console.log(error);
      throwError("Não foi possível carregar tipos de produtos.");
    }
  }

  async function loadTiposProdutos() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaId}/tipos-produtos`,
        {
          params: {
            situacao: "A",
          },
        }
      );
      setTiposProdutos(response.data);
    } catch (error) {
      console.log(error);
      throwError("Não foi possível carregar tipos de produtos");
    }
  }

  const afterSave = (msg) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
        title={msg}
        onConfirm={() => onClose()}
        confirmBtnBsStyle="success"
        showConfirm={false}
        btnSize=""
      />
    );
    setTimeout(onClose(), 2000);
  };

  async function handleSave() {
    setSaving(true);
    if (produto.id) {
      await update();
    } else {
      await insert();
    }
    setSaving(false);
  }

  async function update() {
    try {
      await api.put(
        `/common/empresas/${empresaId}/produtos/${produto.id}`,
        bind()
      );
      afterSave("Produto salvo");
    } catch (error) {
      setSaving(false);
      console.error(error);
      notify("danger", "Não foi possível salvar produto");
      updateErros(error);
    }
  }

  async function insert() {
    try {
      await api.post(`/common/empresas/${empresaId}/produtos`, bind());
      afterSave("Produto salvo");
    } catch (error) {
      setSaving(false);
      console.error(error);
      notify("danger", "Não foi possível salvar produto");
      updateErros(error);
    }
  }

  function updateErros(error) {
    try {
      setErros({});
      if (error) {
        const errors = {};
        for (let e of error.response.data) {
          errors[e.field] = e.message;
        }
        setErros(errors);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function bind() {
    return {
      nome,
      situacao,
      tipo_produto_id: tipoPtoduto,
      preco,
    };
  }

  function throwError(text) {
    if (notify) notify("danger", text);
  }

  const notify = (type, msg) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      {alert}
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader
            style={{
              position: "sticky",
              top: 0,
              zIndex: 200,
            }}
          >
            <h1>{produtoId ? "Alterar Produto" : "Novo Produto"}</h1>
          </CardHeader>
          <CardBody outline body>
            <Row>
              <Col sm="12" md="6" lg="6">
                <FormGroup>
                  <label className="form-control-label">Nome*</label>
                  <Input
                    className="form-control"
                    placeholder="Nome do produto..."
                    type="text"
                    value={nome}
                    onChange={({ target }) => setNome(target.value)}
                  />
                  <small class="text-danger">{erros.nome || ""}</small>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="6">
                <FormGroup>
                  <label className="form-control-label">Tipo produto*</label>
                  <Select2
                    className="form-control"
                    value={tipoPtoduto}
                    options={{
                      placeholder: "Selecione um tipo produto...",
                    }}
                    onSelect={(e) => setTipoProduto(e.target.value)}
                    data={tiposProdutos.map((item) => ({
                      id: item.id,
                      text: item.nome,
                    }))}
                  />
                  <small class="text-danger">
                    {erros.tipo_produto_id || ""}
                  </small>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label">Valor*</label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>R$</InputGroupText>
                    </InputGroupAddon>
                    <CurrencyInput
                      className="form-control"
                      value={preco}
                      placeholder="Valor do produto..."
                      onChangeEvent={(e, maskedvalue, floatvalue) =>
                        setPreco(floatvalue)
                      }
                    />
                  </InputGroup>
                  <small class="text-danger">{erros.preco || ""}</small>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="6">
                <FormGroup>
                  <label className="form-control-label">Situação*</label>
                  <Select2
                    className="form-control"
                    value={situacao}
                    defaultValue="A"
                    options={{
                      placeholder: "Selecione a situação...",
                    }}
                    onSelect={(e) => setSituacao(e.target.value)}
                    data={[
                      { id: "A", text: "Ativo" },
                      { id: "I", text: "Inativo" },
                    ]}
                  />
                  <small class="text-danger">{erros.situacao || ""}</small>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className=""
            style={{
              position: "sticky",
              bottom: 0,
              border: "2 solid",
              boxShadow: "0px 0px 2px 2px #f5f5f5",
              zIndex: 100,
            }}
          >
            <Row>
              <Col>
                <div className="float-right ">
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onClose()}
                  >
                    Voltar
                  </Button>
                  <Button
                    disabled={saving}
                    color="primary"
                    onClick={handleSave}
                  >
                    <Spinner
                      hidden={!saving}
                      className="mr-2"
                      color="light"
                      size="sm"
                    />
                    Salvar
                  </Button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default FormProdutos;
