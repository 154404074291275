import React, { useState, useEffect } from 'react';
import api from "../../../../../../../services/api";
import {
    Empresa
} from "../../../../../../../entities/Common";

import { Container, CircleContainer } from '../ColorCircle/styles';
import ColorCircle from "../ColorCircle";

import {
    Button,
    FormGroup,
    Input,
    Spinner,
    PopoverBody,
    UncontrolledPopover,
    Row,
    Col,
    Modal
} from "reactstrap";


interface INew {
    empresa?: Empresa,
    onHidde(): void,
    onSuccess(): void
    show: boolean,
    notify(type: string, msg: string): void
}

interface Error {
    nome?: string,
    cor?: string
}

const New: React.FC<INew> = ({
    empresa,
    onHidde,
    onSuccess,
    show,
    notify
}) => {

    const [estagio, setEstagio] = useState({ nome: '', cor: '#0847d6' })
    const [nome, setNome] = useState('')
    const [cor, setCor] = useState('#0847d6') //azul primary
    const [saving, setSaving] = useState(false)


    const [error, setError] = useState({} as Error)

    useEffect(() => {
        setError({} as Error)
        setNome('')
        setCor('#0847d6') //azul primary
        setEstagio({ nome: '', cor: '#0847d6' })
    }, [show])

    function handleEditColor(e: any, estagio: any, cor: any) {
        setCor(cor)
    }

    async function handleSave(e: React.MouseEvent) {
        e.preventDefault()
        setError({} as Error)
        try {
            setSaving(true)
            await api.post(`common/empresas/${empresa?.id}/estagios`, {
                nome,
                cor
            }
            )
            notify('success', 'Estagio adicionado')
            onHidde()
            onSuccess()
        } catch (error) {
            notify('danger', 'Não conseguimos adicionar este estagio')
            // @ts-ignore
            const response = error.response
            if (response && response.status === 400 && response.data) {
                const messages = await response.data
                const errorMessage = {} as any
                for (let msg of messages) {
                    errorMessage[msg.field as string] = msg.message
                }
                setError(errorMessage)

            }
        } finally {
            setSaving(false)
        }
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={show}
            toggle={onHidde}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    Novo Estagio
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={onHidde}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Container>
                    <Row>
                        <Col>
                            <FormGroup>
                                <legend className="w-auto">
                                    <label className="form-control-label" >
                                        Nome*
                                    </label>
                                </legend>
                                <Input
                                    placeholder='Nome...'
                                    className="form-control"
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                />
                                <small className="text-danger">
                                    {error.nome || ""}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col className='col-12' >
                            <FormGroup>
                                <legend className="w-auto">
                                    <label className="form-control-label" >
                                        Cor*
                                        <Button
                                            id='help-aplicacao'
                                            size='sm'
                                            color='link'>
                                            ?
                                        </Button>
                                        <UncontrolledPopover
                                            placement="top"
                                            target="help-aplicacao"
                                        >
                                            <PopoverBody>
                                                A cor será usada para facilitar a identificação do estagio nos dashboards e outros relatórios
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </label>
                                </legend>
                                <CircleContainer >
                                    <ColorCircle
                                        estagio={estagio}
                                        handleSaveColor={handleEditColor}
                                        width={60}
                                        height={60}
                                    />
                                </CircleContainer>
                                <small className="text-danger">
                                    {error.cor || ""}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal-footer">
                <Button
                    color="link"
                    type="button"
                    disabled={saving}
                    onClick={onHidde}
                >
                    Voltar
                </Button>
                <Button
                    color="primary"
                    type="button"
                    disabled={saving}
                    onClick={handleSave}
                >
                    {
                        saving &&
                        <Spinner
                            color='secondary'
                            size='sm'
                            className='mr-2'
                        />
                    }
                    {`Salvar e Adicionar a Empresa`}
                </Button>
            </div>
        </Modal>
    )
}

export default New;