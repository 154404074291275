

import React, { useState, useEffect } from 'react';
import Empresa from '../../../../../../entities/Common/Empresa';
import Pagamento from '../../../../../../entities/Common/Pagamento';
import { appendScript, removeScript } from '../../../../../../hooks'
import api from "../../../../../../services/api";


import {
    Modal,
    Button,
} from "reactstrap";


// Propriedades do meu componente
type Props = {
    // Lista de sites
    pagamento: Pagamento,
    empresa: Empresa,
    show: boolean,
    hide(): void,
    success(): void,
    // Método para passar notificações para o usuário
    notify(type: string, msg: string): void
}



const Frame: React.FC<Props> = ({
    pagamento,
    empresa,
    show,
    hide,
    success,
    notify,
}) => {

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [ppp, setPpp] = useState({});

    useEffect(() => {
        if (window.addEventListener) {
            window.addEventListener("message", messageListener, false);
            console.log("addEventListener successful", "debug");
        } else {
            console.log("Could not attach message listener", "debug");
            throw new Error("Can't attach message listener");
        }
    }, [])

    //@ts-ignore
    async function messageListener(event) {
        try {
            //this is how we extract the message from the incoming events, data format should look like {"action":"inlineCheckout","checkoutSession":"error","result":"missing data in the credit card form"}
            var data = JSON.parse(event.data);
            //insert logic here to handle success events or errors, if any
            if (data.action === 'checkout') {
                console.log('CHECKOUT')
                console.log({data})
                pagamento.return_checkout = data
                pagamento.status_checkout = data.result.state

                await handleUpdate()
            }
            
        } catch (exc) {

        }
    }

    async function initPaypalPlusIframe() {
        console.log('entrou no initPaupalPlusIframe')

        const return_api = pagamento.return_api
        //@ts-ignore
        const userInfo = JSON.parse(pagamento.return_api);
        //@ts-ignore
        const links = userInfo?.links
        //@ts-ignore
        const linkAproval = links.filter(item => item.rel == 'approval_url')
        const url = linkAproval[0].href

        if (!url) {
            notify('warning', 'Página de pagamento não encontrada')
            return
        }

        const pppConfig = {
            approvalUrl: url,
            payerEmail: 'james.adm@live.com',
            payerFirstName: 'James',
            payerLastName: 'Souza',
            payerPhone: '557988414953',
            payerTaxId: '84211245520',
            language: 'pt_BR',
            country: 'BR',
            placeholder: 'ppplus' + pagamento.id,
            mode: 'sandbox',
            disableContinue: "continueButton",
            enableContinue: "continueButton",
            disallowRememberedCards: 'true'
        };
        //@ts-ignore
        setPpp(window.PAYPAL.apps.PPP(pppConfig))
    }

    async function onClickContinue() {
        console.log('ON CLICK CONTINUE')
        //@ts-ignore
        console.log({ ppp })
        //@ts-ignore
        ppp.doContinue()
    }

    async function handleUpdate() {
        try {
          if (pagamento.id) {
            await update()
          }
          //escondo o modal
          hide()
          //recarrego a listagem por trás
          success()
        } catch (error: any) {
          console.log(error)
          notify('danger', 'Não foi possível adicionar pagamento')
          if (error.response)
            //updateErros(error.response.data)
            console.log(error.response)
        } finally {
          //setSaving(false)
        }
      }

    async function update() {
        await api.put(`/common/empresas/${empresa.id}/pagamentos/${pagamento.id}`,
          {
            ...pagamento
          })
      }

    useEffect(() => {
        if (show) {
            console.log('Entrou no UseEffect Show')
            initPaypalPlusIframe();
        }
    }, [show])

    useEffect(() => {
        appendScript(
            'https://www.paypalobjects.com/webstatic/ppplusdcc/ppplusdcc.min.js',
            () => setScriptLoaded(true)
        );
    }, [])


    return (
        // <>
        //     <Modal
        //         className="modal-dialog-centered"
        //         isOpen={show}
        //         toggle={hide}
        //     >

        //         <div className="modal-header">
        //             <h5 className="modal-title" id="exampleModalLabel">
        //                 {pagamento.id ? 'ALTERAR FRAME PAYPAL' : 'NOVO FRAME PAYPAL'}
        //             </h5>
        //             <button
        //                 aria-label="Close"
        //                 className="close"
        //                 data-dismiss="modal"
        //                 type="button"
        //                 onClick={hide}
        //             >
        //                 <span aria-hidden={true}>×</span>
        //             </button>
        //         </div>

        //         <div>
        //             <h1>DIV ppplus</h1>
        //             <div id={'ppplus'+pagamento.id}></div>
        //         </div>

        //     </Modal>

        // </>
        <>
            <div id={'divpagamento' + pagamento.id} style={{ display: show ? "block" : "none" }} >
                <div id={'ppplus' + pagamento.id}></div>
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center'
                    }}
                >
                    <Button
                        color="link"
                        type="button"
                        onClick={hide}
                    >
                        Fechar
                    </Button>

                    <Button
                        id='continueButton'
                        color="primary"
                        type="button"
                        className="btn-icon btn-3"
                        onClick={onClickContinue}

                    >
                        <span className="btn-inner--text">Pagar</span>
                    </Button>

                </div>
            </div>

        </>


    );
}

export default Frame;