import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../contexts/Empresa";
import api from "../../../services/api";
import ReactQuill from "react-quill";
import ReactBSAlert from "react-bootstrap-sweetalert";

import {
    Button,
    Modal,
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup
} from "reactstrap";

export default function PonderacaoButton({ publicacao, ...props }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [ponderacaoText, setPonderacaoText] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [alert, setAlert] = useState(null)

    useEffect(() => {
        setPonderacaoText('')
    }, [showModal])

    async function handlePonderacaoAdd() {
        try {
            const data = {
                texto: ponderacaoText
            }
            const url = `/common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacao.id}/ponderar`
            await api.post(url, data)
            throwAlert(null)
            onPonderacaoAdded('Ponderação adicionada')
        } catch (err) {
            console.error(err)
            throwError('Não foi possível salvar ponderação')
        }
    }

    function throwError(err) {
        if (props.onError) {
            props.onError(err)
        }
    }

    function throwAlert(alert) {
        if (props.alert) {
            props.alert(alert)
        }
    }

    function onPonderacaoAdded(msg) {
        if (props.onSuccess) {
            props.onSuccess(msg)
        }
    }

    const confirmAlertUpdateFase = (
        title,
        msg,
        confirmFunction,
        confirmButtonLabel = "Ok",
        confirmButtonColor = "primary"
    ) => {
        return (
            <ReactBSAlert
                warning
                focus
                style={{ display: "block", }}
                closeOnClickOutside={true}
                title={title}
                onConfirm={confirmFunction}
                onCancel={() => throwAlert(null)}
                showCancel
                confirmBtnBsStyle="primary"
                confirmBtnText="Ok"
                cancelBtnText="Cancelar"
                btnSize=""
            >
                {msg}
            </ReactBSAlert>
        )
    };

    function isPonderacaoValidText() {
        return (ponderacaoText && ponderacaoText.trim() != '' && ponderacaoText.length > 15)
    }

    function createModal() {
        return (
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={showModal}
                toggle={() => setShowModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent p-2">
                            <div className="text-muted text-center mt-1 mb-1">
                                <large><b>Adicione suas ponderações</b></large>
                            </div>

                        </CardHeader>
                        <CardBody className="">

                            <Form role="form">
                                <FormGroup>
                                    <ReactQuill
                                        theme="snow"
                                        modules={{
                                            toolbar: [
                                                ["bold", "italic"],
                                                ["link", "blockquote"],
                                                [
                                                    {
                                                        list: "ordered"
                                                    },
                                                    {
                                                        list: "bullet"
                                                    }
                                                ]
                                            ]
                                        }}

                                        onChange={setPonderacaoText}

                                    />
                                    <small className="text-muted   my-2">
                                        A fase será alterada retornando para a de <b>criação</b>.<br></br>
                                        Essa ponderação será apresentado no corpo da publicação...
                                    </small>
                                    <br />
                                </FormGroup>

                                <div className="text-center">
                                    <Button
                                        disabled={!isPonderacaoValidText()}
                                        className="my-2"
                                        color="primary"
                                        type="button"
                                        onClick={e => {
                                            e.preventDefault();
                                            if (!isPonderacaoValidText()) {
                                                return
                                            }
                                            setShowModal(false)
                                            throwAlert(
                                                confirmAlertUpdateFase("Confirmar ponderação",
                                                    "Ao confirmar, a publicação retornará para a fase de CRIAÇÃO",
                                                    async () => { await handlePonderacaoAdd() },
                                                    "Sim",
                                                    "primary"
                                                )
                                            )
                                        }}
                                    >
                                        Salvar ponderações
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        )
    }

    return (
        <>
            {alert}
            {
                (publicacao && publicacao.id && publicacao.faseAtual.is_aprovado) &&
                <div>
                    <Button
                        size={props.buttonSize ? props.buttonSize : 'md'}
                        color="warning"
                        type="button"
                        onClick={setShowModal}>
                        Ponderar
                    </Button>
                    {createModal()}
                </div>



            }
        </>
    );
}
