import React, { useState, useEffect } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import Table from "../../../components/Table";

import {
    Spinner
} from "reactstrap";

import "./styles.css"

export default ({ itens, pageProperties, notify, ...props }) => {

    const headerStyle = { fontSize: 9 }

    const [columns] = useState([
        {
            headerStyle,
            dataField: 'nome',
            text: 'Estágio<br/>  &nbsp;',
            headerFormatter: headerFormatter,
        },
        {
            dataField: "valor_atual_abertas",
            text: 'Estão no Estágio<br/>Abertas',
            headerFormatter: headerFormatter,
            headerAlign: 'center',
            align: 'center',
            headerStyle
        },
        {
            dataField: "valor_atual_encerradas",
            text: 'Estão no Estágio<br/>Encerradas',
            headerFormatter: headerFormatter,            
            headerAlign: 'center',
            align: 'center',
            headerStyle
        },
        {
            dataField: 'passaram',
            text: 'Passaram pelo estágio<br/>  &nbsp;',
            headerFormatter: headerFormatter,
            headerAlign: 'center',
            align: 'center',
            headerStyle
        },
        {
            dataField: 'saltaram',
            text: 'Saltaram o estágio<br/>  &nbsp;',
            headerFormatter: headerFormatter,
            headerAlign: 'center',
            align: 'center',
            headerStyle
        },
        {
            dataField: 'total',
            text: 'Total<br/>  &nbsp;',
            headerFormatter: headerFormatter,
            headerAlign: 'center',
            align: 'center',
            headerStyle,
            formatter: (cell, row) => totalFormatter(row)
        },
        {
            dataField: 'funil',
            text: 'Funil<br/>  &nbsp;',
            headerFormatter: headerFormatter,
            headerAlign: 'center',
            align: 'center',
            headerStyle,
            formatter: (cell, row) => funilFormatter(row)
        }
    ])

    function headerFormatter(column) {
        return (
            <>
                <div
                    id={`column-${column.text}`}
                >
                    <div dangerouslySetInnerHTML={{ __html: column.text }} />
                </div>
            </>
        );
    }

    function totalFormatter(row) {
        var total = (parseInt(row.valor_atual_abertas) + parseInt(row.valor_atual_encerradas) + parseInt(row.passaram) + parseInt(row.saltaram))
        return total
    }

    function funilFormatter(row) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 20 }}
                title={row.funil}
            >
                {
                    row.funil &&
                    <div
                        style={{ width: (row.funil / row.totalOportunidades * 100) * 2, height: 5, backgroundColor: row.cor, borderRadius: 3 }}
                        title={row.funil}
                    >
                    </div>
                }
            </div>
        )
    }


    return (
        <>
            {
                itens && pageProperties ?
                    <Table
                        columns={columns}
                        data={itens}
                        pageProperties={pageProperties}
                        onTableChange={props.onTableChange}
                    />
                    :
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center'
                    }}>
                        <Spinner
                            color='primary'
                        />
                    </div>
            }
        </>
    );
}
