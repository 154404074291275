import React, { useState, useEffect, useContext } from 'react';
import api from "../../../../../../services/api";
import Empresa from "../../../../../../entities/Common/Empresa"
import Pagamento from "../../../../../../entities/Common/Pagamento"
import AuthContext from "../../../../../../contexts/Auth"


//@ts-ignore
import Select2 from "react-select2-wrapper";
import {
  Button,
  Input,
  Row,
  Col,
  FormGroup,
  Modal
} from "reactstrap";
import Show from '../../../../../marketing/marcacoes/Show';
import { AxiosResponse } from 'axios';

// import { Container } from './styles';

type Props = {
  notify(type: string, msg: string): void,
  empresa: Empresa,
  //A interrogação diz que o item é opicional
  pagamento: Pagamento,
  //O item que diz se está mostrando ou não
  show: boolean,
  //O item que é chamado quando queremos fechar o modal
  hide(): void,
  //Função para atualizar lista de tras
  success(): void
}

type Erro = {
  nome?: string,
  //path?: string,
  situacao?: string,
  plano?: string,
  meioPagamento?: string,
}

const Form: React.FC<Props> = ({
  notify,
  empresa,
  pagamento,
  show,
  hide,
  success
}) => {

  const [nome, setNome] = useState('')
  //const [path, setPath] = useState('')
  const [situacao, setSituacao] = useState('A')

  const [plano, setPlano] = useState(null)
  const [planos, setPlanos] = useState([])

  const [meioPagamento, setMeioPagamento] = useState(null)
  const [meiosPagamento, setMeiosPagamento] = useState([])

  const { auth, hasPermission } = useContext(AuthContext)

  const [saving, setSaving] = useState(false)
  const [erros, setErros] = useState<Erro>({} as Erro)

  useEffect(() => {

    setNome(pagamento.nome)
    //setPath(site.path)
    setSituacao(pagamento.situacao || 'A')
  }, [show, pagamento])

  useEffect(() => {
    if (planos.length === 0) {
      loadPlanos()
    }

    if (meiosPagamento.length === 0) {
      loadMeiosPagamento()
    }
  }, [])

  async function loadPlanos() {
    try {
      const response = await api.get(`/common/planos`,
        {
          params: {
            situacao: 'A'
          }
        })
      setPlanos(response.data)
    } catch (error) {
      console.log(error)
      notify('danger', 'Não foi possível carregar planos')
    }
  }

  async function loadMeiosPagamento() {
    try {
      const response = await api.get(`/common/empresas_pagamento`,
        {
          params: {
            situacao: 'A'
          }
        })
      setMeiosPagamento(response.data)
    } catch (error) {
      console.log(error)
      notify('danger', 'Não foi possível carregar meios de pagamento')
    }
  }

  async function handleSave() {
    try {
      setErros({})
      setSaving(true)

      if (pagamento.id) {
        await update()
      } else {
        await insert()
      }
      //escondo o modal
      hide()
      //recarrego a listagem por trás
      success()
    } catch (error: any) {
      console.log(error)
      notify('danger', 'Não foi possível adicionar pagamento')
      if (error.response)
        //updateErros(error.response.data)
        console.log(error.response)
    } finally {
      setSaving(false)
    }
  }

  async function insert() {
    //Chamo o endpoint para cadastro de pagamentos na empresa
    await api.post(`/common/empresas/${empresa.id}/pagamentos/criarpagamento`,
      {
        //passo os campos do site que irei salvar
        nome,
        plano_id: plano,
        situacao: 'A',
        empresa_pagamento_id: meioPagamento       
      })

  }

  async function update() {
    await api.put(`/common/empresas/${empresa.id}/pagamentos/${pagamento.id}`,
      {
        nome,
        plano,
        situacao,
        meioPagamento
      })
  }

  //@ts-ignore
  function updateErros(error) {
    if (error) {
      const errors = {}
      for (let e of error) {
        //@ts-ignore
        errors[e.field] = e.message
      }
      setErros(errors)
    } else {
      setErros({})
    }
  }

  return (<>
    <Modal
      className="modal-dialog-centered"
      isOpen={show}
      toggle={hide}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {pagamento.id ? 'Alterar Pagamento' : 'Novo Pagamento'}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={hide}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <FormGroup>
              <label
                className="form-control-label"
              >
                Nome*
              </label>
              <Input
                className="form-control"
                placeholder="Nome..."
                type="text"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
              <small className="text-danger">
                {erros.nome || ''}
              </small>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label
                className="form-control-label">
                Plano*
              </label>
              <Select2
                className="form-control"
                value={plano}
                options={{
                  placeholder: "Selecione um plano..."
                }}
                //@ts-ignore
                onSelect={e => setPlano(e.target.value)}
                //@ts-ignore
                data={planos.map(item => ({ id: item.id, text: item.nome + ' (R$ '+ item.preco+ ')' }))}                
              />
              <small className="text-danger">
                {erros.plano || ''}
              </small>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label
                className="form-control-label">
                Meio de Pagamento*
              </label>
              <Select2
                className="form-control"
                value={meioPagamento}
                options={{
                  placeholder: "Selecione um meio de pagamento..."
                }}
                //@ts-ignore
                onSelect={e => setMeioPagamento(e.target.value)}
                //@ts-ignore
                data={meiosPagamento.map(item => ({ id: item.id, text: item.nome }))}
              />
              <small className="text-danger">
                {erros.meioPagamento || ''}
              </small>
            </FormGroup>
          </Col>
        </Row>
        
        
      </div>
      <div className="modal-footer">
        <Row>
          <Col>
            <div className="float-right ">
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={hide}
              >
                Fechar
              </Button>
              <Button
                disabled={saving}
                color="primary"
                onClick={handleSave}
              >
                Salvar
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  </>
  );
}

export default Form;