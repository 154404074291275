import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  PopoverBody,
  Spinner,
} from "reactstrap";
import Table from "../../../../../components/Table";
import api from "../../../../../services/api";
import EmpresaContext from "../../../../../contexts/Empresa";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { AxiosResponse } from "axios";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
import { SelectableButton } from "./styles";
import { string } from "prop-types";
import Avatar from "../../../../../components/Avatar";
import { UncontrolledPopover, UncontrolledTooltip } from "reactstrap/lib";
import { AbstractEvent } from "@antv/g6-core";
import { KpisContext } from "../../../../../contexts/Kpis";

// import { Container } from './styles';

interface IGraficos {
  mes: number;
  ano: number;
  campanha: any;
  canais: any;
}

const toolTipsData = {
  tempomedioatendimentolead: `Tempo médio que o colaborador leva para atender uma oportunidade 
  (considerando o período e o estágio do funil selecionados).
  O cálculo é feito tomando por base a diferença entre a data e a hora do primeiro
  registro de um comentário e
  a data e hora em que a oportunidade foi criada. `,
  oportunidadesatrasadasperiodo: `Quantidade de oportunidades criadas no período selecionado que estão na posse do colaborador 
  (por estágio selecionado).`,
  ofertamediaprodutos: `Quantidade média de produtos que são oferecidos nas oportunidades, do período selecionado, 
  pelo colaborador (por estágio selecionado).`,
  ticketmedio: `Representa o valor médio gasto ou projetado, no período selecionado, por oportunidade. 
  Quando a oportunidade não estiver convertida, será considerado, para efeito de cálculo, 
  a soma dos valores dos produtos selecionados na oportunidade.`,
  mediacomentariosperiodo: `Média de comentários registrados nas oportunidades pelo colaborador 
  (considerando o período e o estágio do funil selecionados).`,
};

const TabelaPerformanceGeralColaborador: React.FC<IGraficos> = ({
  mes,
  ano,
  campanha,
  canais,
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [kpisEstagio, setKpisEstagio] = useState([]);
  const [campanhas, setCampanhas] = useState([]);
  const [estagioSelecionado, setEstagioSelecionado] = useState(null);
  const [columns, setColumns] = useState([]);
  const [lastSearch, setLastSearch] = useState({});
  const [estagios, setEstagios] = useState([]);
  const [time, setTime] = useState(0);
  const { requestAvaliable, handlerequestAvaliable } = useContext(KpisContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageProperties, setPageProperties] = useState({
    total: "0",
    limit: 10,
    page: 1,
    lastPage: 1,
    loading: isLoading,
  });

  useEffect(() => {
    loadEstagios();
  }, []);

  useEffect(() => {
    setColumns([
      {
        dataField: "name",
        text: "Colaborador",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) => colaboradorFormatter(row),
        headerFormatter: headerFormatter,
      },
      {
        dataField: "tempomedioatendimentolead",
        text: "Tempo médio de atendimento a uma nova oportunidade",
        align: "center",
        headerAlign: "center",
        headerFormatter: headerFormatter,
        formatter: (row: any) => divFormatter(row),
      },
      {
        dataField: "oportunidadesatrasadasperiodo",
        text: "Oportunidades atrasadas",
        align: "center",
        headerAlign: "center",
        headerFormatter: headerFormatter,
        formatter: (row: any) => divFormatter(row),
      },
      {
        dataField: "ofertamediaprodutos",
        text: "Oferta média de produtos",
        align: "center",
        headerAlign: "center",
        headerFormatter: headerFormatter,
        formatter: (row: any) => divFormatter(row),
      },
      {
        dataField: "ticketmedio",
        text: "Ticket médio",
        align: "center",
        headerAlign: "center",
        headerFormatter: headerFormatter,
        formatter: (row: any) => {
          let divFormatter = (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "48px",
              }}
            >
              {row}
            </div>
          );
          return divFormatter;
        },
      },
      {
        dataField: "mediacomentariosperiodo",
        text: "Média de comentários por oportunidade",
        align: "center",
        headerAlign: "center",
        headerFormatter: headerFormatter,
        formatter: (row: any) => divFormatter(row),
      },
    ] as any);

    if (estagioSelecionado && canais) tempo();
  }, [estagioSelecionado, mes, ano, canais]);

  function headerFormatter(column: any) {
    return (
      <>
        <div
          style={{
            maxWidth: "194px",
            display: "flex",
            alignItems: "center",
            whiteSpace: "break-spaces",
            justifyContent: "center",
            margin: "auto",
            height: "7vh",
          }}
          id={column.dataField}
        >
          {column.text}
        </div>

        <UncontrolledTooltip
          delay={0}
          placement="bottom"
          target={column.dataField}
          style={{
            color: "#525f7f",
            backgroundColor: "#fff",
            borderColor: "#c1c1c1",
            borderWidth: 1,
            width: "500px!important",
            border: "1px solid rgba(0, 0, 0, 0.05)",
            boxShadow: "0px 0.5rem 2rem 0px rgb(0 0 0 / 20%)",
          }}
        >
          {/* @ts-ignore */}
          {toolTipsData[column.dataField]}
        </UncontrolledTooltip>
      </>
    );
  }

  function divFormatter(row: any) {
    let divFormatter = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "48px",
        }}
      >
        {row}
      </div>
    );
    return divFormatter;
  }

  function colaboradorFormatter(row: any) {
    let colaboradorAvatar = (
      <div style={{ height: 48 }}>
        {row && (
          <Avatar
            title={row.name}
            user={{ avatar: row.avatar, name: row.name }}
            className="avatar"
            style={{
              cursor: "default",
            }}
          />
        )}
      </div>
    );
    return colaboradorAvatar;
  }

  async function loadEstagios() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/estagios`
      );
      console.log(response.data);

      setEstagios(response.data);
      if (response.data.length && !estagioSelecionado)
        setEstagioSelecionado(response.data[0].id);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar estagios");
    }
  }

  async function handleTableChange(
    type: string,
    sortProperties: {
      page: number;
      limit: number;
      sortField: string;
      sortOrder: string;
    }
  ): Promise<void> {
    try {
      await loadData(
        sortProperties.page == 0 ? 1 : sortProperties.page,
        sortProperties.limit,
        sortProperties.sortField,
        sortProperties.sortOrder
      );
    } catch (error) {
      notify("danger", "Houve um problema ao carregar as kpis.");
    }
  }

  async function tempo() {
    clearTimeout(time);
    setTime(
      setTimeout(async (e) => {
        loadData();
      }, 3000)
    );
  }

  async function loadData(
    page = 1,
    limit = 10,
    sortField = "tema",
    sortOrder = "asc",
    filters = lastSearch
  ) {
    setIsLoading(true);
    handlerequestAvaliable(false);
    try {
      setPageProperties({
        ...pageProperties,
      });

      const dataInicio = moment([ano, mes - 1, 1]).format("YYYY-MM-DD");
      let dataFim = moment([ano, mes - 1, 1])
        .endOf("month")
        .format("YYYY-MM-DD");
      if (new Date(dataFim) > new Date()) {
        // @ts-ignore
        dataFim = new Date();
      }

      const response = await api.get(
        `/dashboards/empresas/${empresaSelecionada?.id}/kpis-estagio-periodo-performance/`,
        {
          params: {
            estagio_id: estagioSelecionado,
            data_inicio: dataInicio,
            data_fim: dataFim,
            canal_id: canais,
          },
        }
      );
      console.log(response.data);

      setKpisEstagio(response.data);
      await updatePageProperties(response.data);
    } catch (err) {
      console.error(err);
      notify("danger", "Houve um problema ao carregar lista de Campanhas");
    } finally {
      setIsLoading(false);
      handlerequestAvaliable(true);
    }
  }

  async function updatePageProperties(response: AxiosResponse) {
    const { total, limit, page, lastPage } = await response.data;
    setPageProperties({
      total,
      limit,
      page,
      lastPage,
      loading: isLoading,
    });
  }

  function notify(type: any, msg: any) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    // if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        {/* <NotificationAlert ref={notificationAlert} /> */}
      </div>
      <Card body>
        <div></div>
        <div>
          <span>
            <Card style={{ minHeight: "365px" }}>
              <div>
                <CardHeader>
                  <h5 className="h3 mb-0">Performance geral do colaborador</h5>
                  <small>
                    Visão geral da performance do colaborador no tocante ao
                    cumprimento de suas rotinas e à eficiência do seu trabalho
                    no período.
                  </small>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <h6 className="surtitle">Kpis</h6> */}
                  </div>
                </CardHeader>
              </div>
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    overflow: "auto",
                    justifyContent: "center",
                  }}
                >
                  {estagios.map(({ nome, id }) => {
                    return (
                      <SelectableButton
                        className={"btn btn-outline-secondary mb-2"}
                        active={id == estagioSelecionado}
                        color={"#0847d6"}
                        onClick={() => setEstagioSelecionado(id)}
                      >
                        {nome}
                      </SelectableButton>
                    );
                  })}
                </div>
                <div className="">
                  {isLoading ? (
                    <Container
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "300px",
                      }}
                    >
                      <Spinner color="primary" size=""></Spinner>
                    </Container>
                  ) : (
                    <Table
                      columns={
                        columns.length
                          ? columns
                          : [
                              {
                                dataField: "name",
                                text: "Nome",
                                align: "center",
                                headerAlign: "center",
                              },
                            ]
                      }
                      data={kpisEstagio}
                      noDataIndication="Nenhum item encontrado"
                      pageProperties={pageProperties}
                      onTableChange={handleTableChange}
                      notify={undefined}
                      rowEvents={undefined}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </span>
        </div>
      </Card>
    </>
  );
};
export default TabelaPerformanceGeralColaborador;
