import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { IContact } from "../../types";
import { Container } from "./styles";
import { useWhatsappContext } from "../../../../../contexts/Whatsapp";
import api from "../../../../../services/api";

interface ICtt extends IContact {
  haveNews?: boolean;
}

function timeAgo(timestamp: Date, locale = "en") {
  let value;
  const diff = (new Date().getTime() - timestamp.getTime()) / 1000;
  const minutes = Math.floor(diff / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });

  if (years > 0) {
    value = rtf.format(0 - years, "year");
  } else if (months > 0) {
    value = rtf.format(0 - months, "month");
  } else if (days > 0) {
    value = rtf.format(0 - days, "day");
  } else if (hours > 0) {
    value = rtf.format(0 - hours, "hour");
  } else if (minutes > 0) {
    value = rtf.format(0 - minutes, "minute");
  } else {
    value = rtf.format(0 - +diff.toFixed(0), "second");
  }
  return value;
}

const ChatCard: React.FC<{ data: ICtt }> = ({ data }) => {
  const [date, setDate] = useState<string>(timeAgo(new Date(data.dt_last_message), "pt-br"));
  const [lastMsg, setLastMsg] = useState<string>(' ')
  const { contacts, selectContact, selectedContact, updateContacts } = useWhatsappContext();

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(timeAgo(new Date(data.dt_last_message), "pt-br"));
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [data.dt_last_message]);
  useEffect(() => {
    handleLastMessage()
    setDate(timeAgo(new Date(data.dt_last_message), "pt-br"));

  }, [contacts]);

  const handleLastMessage = async () => {
    const response = await api.get(`meta/accounts/${data?.account_id}/contacts/${data?.wa_id}/last-message`)
    setLastMsg(response.data?.text ?? "")
  }

  const backgroundStyles =
    data?.wa_id?.concat(data.account_id) === selectedContact?.wa_id?.concat(selectedContact.account_id);

  return (
    <Container
      onClick={() => {
        console.log('clicando em ', data);
        selectContact(data)
      }}
      isSelected={backgroundStyles}
    >
      <div className="contact--info">
        <div>
          <span className="contact-name">{data.name}</span>
          <Badge className="contact-badge" color="primary">
            {data?.account?.verified_name}
          </Badge>
        </div>

        <small style={{ color: data.haveNews ? "red" : "#525f7f" }}>
          {date}
        </small>
      </div>
      <div>
        <p className="contact-message">
          {lastMsg}
        </p>
      </div>
    </Container>
  );
};

export default ChatCard;
