import './integrationSteps.css';
import React, { useEffect, useRef, useState } from "react";
import { FacebookProvider, useFacebook, useLogin } from "react-facebook";

import { FacebookButton } from "./styles";
import api from "../../../../../../services/api";
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { useEmpresaContext } from '../../../../../../contexts/Empresa';

//@ts-ignore
import NotificationAlert from "react-notification-alert";


const FacebookLoginButton = () => {
  const { init, isLoading, error } = useFacebook();

  const myBusinesses = async (FB: any) => {
    const { data } = await FB?.api("me/businesses", undefined, {
      fields: "owned_businesses,name",
    });
    console.log("business", data.length);

    for (const business of data) {
      await whatsappAccBusinessId(FB, business.id);
    }
  };

  const whatsappAccBusinessId = async (FB: any, businessID: string) => {
    const token = await FB?.getToken();
    const response = await FB?.api(businessID, undefined, {
      access_token: token,
      fields: "owned_whatsapp_business_accounts{phone_numbers}",
    });

    const { data } = response.owned_whatsapp_business_accounts;
    for (const whatsapp of data) {
      await subscribeWebhook(FB, whatsapp, token);
    }
  };

  const subscribeWebhook = async (
    FB: any,
    { id, phone_numbers }: { id: string; phone_numbers: any },
    token: string
  ) => {
    // const { success } = await FB.api(`${id}/subscribed_apps`, "post", {
    //   access_token: token,
    // });
    await storeAccount(phone_numbers);
  };

  const storeAccount = async (phone_numbers: any) => {
    await api.post(`meta/accounts`, { accounts: phone_numbers?.data });
  };

  // @ts-check



  async function handleClick() {
    // @ts-ignore
    const api = await init({ version: 'v16.0' });
    // @ts-ignore
    const a = FB?.getLoginStatus();
    // console.log({ a });

    // if (status === "connected") {
    //   await api?.logout();
    // }
    const opt = {
      scope:
        "business_management,whatsapp_business_management,whatsapp_business_messaging",
      rerequest: true,
      extras: {
        feature: "whatsapp_embedded_signup",
      },

    };
    const response = await api?.login(opt);
    console.log(response);

    const FB = await api?.getFB(); // Get original FB object
    await myBusinesses(api);
    // @ts-ignore
  }
  return (
    <FacebookButton disabled={false} onClick={handleClick}>
      Entrar com Facebook
    </FacebookButton>
  );
};



const Whatsapp: React.FC = () => {
  const [token, setToken] = useState('');
  const [currentToken, setCurrentToken] = useState('');
  const { empresaSelecionada } = useEmpresaContext()
  const notificationAlert = useRef(null)
  useEffect(() => {
    getToken()
  }, [])

  const handleTokenChange = (e: any) => {
    setToken(e.target.value);
  };

  const handleTokenSubmit = async (e: any) => {
    e.preventDefault();
    // Aqui você pode fazer algo com o token, como enviar para o servidor
    if (!token) {
      notify("warning", "Nenhum Token foi fornecido!");
      return;
    }
    try {
      await api.patch(
        `common/empresas/${empresaSelecionada?.id}/updatewhatsapptoken`,
        {
          token
        }
      );
      notify("success", "Operação realizada");

    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível concluir a operação.");
    } finally {

      getToken()
      setToken('');
    }
    // Limpar o input depois de enviar
  };

  const getToken = async () => {
    const { data } = await api.get(
      `common/empresas/${empresaSelecionada?.id}/canais/25/token`)

    setCurrentToken(data.token ?? '')
  }
  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    //@ts-ignore
    if (notificationAlert?.current) notificationAlert.current.notificationAlert(options);
  }
  return (
    <div className="integration-steps">
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <ol className="integration-steps__list">
        <li className="integration-steps__list-item">
          Certifique-se de que a empresa concedeu acesso de administrador ao Facebook do Playnee.
        </li>
        <li className="integration-steps__list-item">
          Crie um aplicativo na conta da empresa.
        </li>
        <li className="integration-steps__list-item">
          Adicione os números de WhatsApp desejados ao aplicativo.
        </li>
        <li className="integration-steps__list-item">
          Com a conta do Facebook do Playnee, clique no botão abaixo e selecione a empresa desejada para integração, juntamente com suas respectivas contas.
          <div className="facebook-login">
            <FacebookProvider cookie={false} autoLogAppEvents appId="735161440956284">
              <FacebookLoginButton />
            </FacebookProvider>
          </div>
        </li>
        <li className="integration-steps__list-item">
          Gere e adicione um token permanente para gerenciamento do WhatsApp.
          <InputGroup className='mt-2 mb-2' disabled size="sm">
            <Input value={"Token atual:   " + currentToken} placeholder='token atual' disabled />
          </InputGroup>

          <form onSubmit={handleTokenSubmit}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <span className="input-group-text">
                  <i className="ni ni-fat-add"></i>
                </span>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Token permanente"
                value={token}
                onChange={handleTokenChange}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary" type="submit">
                  Adicionar
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </form>
        </li>
      </ol>
    </div>
  );

};
export default Whatsapp;
