import React, { useState, useRef, useEffect, useContext } from "react";
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
import moment from "moment";
import { bindErrors } from "../../../../utils";

import ContentToSend from "./ContentToSend";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Destinatarios from "./Destinatarios";
import ReactDatetime from "react-datetime";
import NotificationAlert from "react-notification-alert";
import Header from "./Header";
import {
  UncontrolledTooltip,
  CardFooter,
  Button,
  FormGroup,
  Spinner,
  CardBody,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
  PopoverBody,
  UncontrolledPopover,
  Modal,
} from "reactstrap";

export default ({
  history,
  location,
  onBack,
  mala_direta_id,
  isEdit,
  ...props
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);

  const [malaDireta, setMalaDireta] = useState({});

  const [nome, setNome] = useState(null);
  const [dataAgendamento, setDataAgendamento] = useState(null);
  const [description, setDescription] = useState(null);
  const [tabActive, setTabActive] = useState("");
  const [situacao, setSituacao] = useState("ativo");
  const [isNps, setIsNps] = useState(false);

  const [flagPermissaoLimite, setFlagPermissaoLimite] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pedirPermissaoLimite, setPedirPermissaoLimite] = useState(false);

  const [enviarEmail, setEnviarEmail] = useState(false);
  const [enviarSms, setEnviarSms] = useState(false);

  //email-form
  const [sender, setSender] = useState(null);
  const [subject, setSubject] = useState([]);
  const [bodyText, setBodyText] = useState(null);
  const [npsTextEmail, setNpsTextEmail] = useState(null);
  const [midias, setMidias] = useState([]);
  //sms-form
  const [smsConteudo, setSmsConteudo] = useState("");
  const [smsAcentuado, setSmsAcentuado] = useState(false);
  const [npsTextSms, setNpsTextSms] = useState(null);
  const [referenciaId, setReferenciaId] = useState(undefined);
  const [alert, setAlert] = useState(null);
  const [erros, setErros] = useState({});
  const [saving, setSaving] = useState(false);
  const notificationAlert = useRef();

  const [showModalUpdateMalaInativa, setShowModalUpdateMalaInativa] =
    useState(false);

  useEffect(() => {
    if (!isEdit) {
      console.log("NOVA MALA DIRETA");
      loadReferenciaId();
    }
  }, []);

  useEffect(() => {
    if (mala_direta_id) {
      console.log("ID MALA DIRETA");
      console.log(mala_direta_id);
      loadMalaDireta();
    }
  }, [mala_direta_id]);

  useEffect(() => {
    if (malaDireta && malaDireta.id > 0) {
      console.log("MALA DIRETA ENCONTRADA");
      console.log({ malaDireta });
      carregarMalaDireta();
    }
  }, [malaDireta]);

  async function carregarMalaDireta() {
    console.log("CARREGANDO MALA DIRETA to FORM");
    if (malaDireta && malaDireta.id > 0) {
      console.log({ malaDireta });
      setMidias(malaDireta.midias);
      setSituacao(malaDireta.situacao);
      setNome(malaDireta.nome);
      setDataAgendamento(
        malaDireta.data_agendamento
          ? new Date(malaDireta.data_agendamento)
          : null
      );
      setIsNps(malaDireta.is_nps);
      setDescription(malaDireta.descricao);

      setEnviarEmail(malaDireta.enviar_email);
      setSender(malaDireta.email_remetente_id);
      setSubject(malaDireta.email_assunto);
      setBodyText(malaDireta.email_conteudo);

      setEnviarSms(malaDireta.enviar_sms);
      setSmsConteudo(malaDireta.sms_conteudo);
    }
  }

  useEffect(() => {
    //removendo o link da Pesquisa NPS nos casos de edição, que será adicionado novamente ao salvar.
    if (malaDireta && malaDireta.id > 0) {
      if (malaDireta.is_nps && malaDireta.enviar_email) {
        //console.log('REPLACE TEXTO EMAIL')
        //console.log('REMOVER ' + npsTextEmail)
        setBodyText(malaDireta.email_conteudo.replace(npsTextEmail, ""));
      }

      if (malaDireta.is_nps && malaDireta.enviar_sms) {
        //console.log('REPLACE TEXTO SMS')
        //console.log('REMOVER ' + npsTextSms)
        setSmsConteudo(malaDireta.sms_conteudo.replace(npsTextSms, ""));
      }
    }
  }, [npsTextEmail, npsTextSms]);

  async function loadMalaDireta() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/malas-diretas/${mala_direta_id}`
      );
      const data = await response.data;
      setMalaDireta(data);
    } catch (error) {
      notify("error", "Não foi possível carregar Mala Direta");
      console.error(error);
    }
  }

  useEffect(() => {
    setShowModal(false);
    if (flagPermissaoLimite) {
      console.log("ACIONAR INSERT NOVAMENTE");
      handleSave();
    }
    setFlagPermissaoLimite(false);
  }, [flagPermissaoLimite]);

  useEffect(() => {
    console.log("USE_EFFECT ERROS");
    console.log({ erros });
    if (erros && erros.limite_permissao) {
      console.log(erros.limite_permissao);
      console.log("CHAMAR MODAL PERMISSÃO");
      setPedirPermissaoLimite(true);
      setShowModal(true);
    }

    if (erros && erros.limite_bloquear) {
      console.log(erros.limite_bloquear);
      console.log("CHAMAR MODAL BLOQUEIO");
      setPedirPermissaoLimite(false);
      setShowModal(true);
    }
  }, [erros]);

  useEffect(() => {
    if (situacao && situacao == "ativo") {
      if (showModalUpdateMalaInativa) {
        console.log("SET SHOW MODAL FALSE");
        setShowModalUpdateMalaInativa(false);
        console.log("GO TO HANDLE SAVE");
        handleSave();
      }
    }
  }, [situacao]);

  function handleSalvareAtivar() {
    console.log("HANDLE SALVAR E ATIVAR");
    console.log("SET SITUACAO ATIVO");
    setSituacao("ativo");
  }

  async function handleSave() {
    setSaving(true);
    setErros({});
    if (isEdit) {
      console.log("HANDLE UPDATE");
      //await update()
      let mala = bindMalaDireta();
      console.log({ mala });
      if (mala && mala.situacao == "inativo") {
        setShowModalUpdateMalaInativa(true);
      } else {
        await update();
        setSaving(false);
      }
    } else {
      console.log("HANDLE INSERT");
      await insert();
      setSaving(false);
    }
  }

  async function handleSaveMidias(malaDiretaId) {
    const formData = new FormData();

    const arq = midias.filter((file) => !file.id);

    for (const midia of arq) {
      const resp = await fetch(midia.preview);
      const blob = await resp.blob();

      formData.append("files", blob, midia.path);
    }

    const response = await api.post(`marketing/mala-direta/${malaDiretaId}/midias`, formData, {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    });

    afterSave();
  }

  async function insert() {
    try {
      const { data } = await api.post(
        `common/empresas/${empresaSelecionada.id}/malas-diretas`,
        bindMalaDireta()
      );

      await handleSaveMidias(data.id);

      //   afterSave();
    } catch (error) {
      console.error(error);
      if (error.response) {
        const errorMessage = bindErrors(error.response.data);
        if (errorMessage) {
          errorMessage instanceof String
            ? notify("danger", errorMessage)
            : setErros(errorMessage);
        } else {
          notify("danger", "Não foi possível salvar mala direta");
        }
      }
    } finally {
      setSaving(false);
    }
  }

  async function update() {
    try {
      setShowModalUpdateMalaInativa(false);
      const {data} = await api.put(
        `common/empresas/${empresaSelecionada.id}/malas-diretas/${malaDireta.id}`,
        bindMalaDireta()
      );

      await handleSaveMidias(data.id);
    } catch (error) {
      console.error(error);
      if (error.response) {
        const errorMessage = bindErrors(error.response.data);
        if (errorMessage) {
          errorMessage instanceof String
            ? notify("danger", errorMessage)
            : setErros(errorMessage);
        } else {
          notify("danger", "Não foi possível salvar mala direta");
        }
      }
    } finally {
      setSaving(false);
    }
  }

  const afterSave = (msg) => {
    setAlert(
      <ReactBSAlert
        success
        title={msg || "Mala Direta Salva"}
        onConfirm={() => history.goBack()}
        confirmBtnBsStyle="success"
        showConfirm={false}
        btnSize=""
      />
    );
    setTimeout(redirectBack, 2000);
  };

  function bindMalaDireta() {
    let mala = {
      nome,
      data_agendamento: dataAgendamento,
      descricao: description,
      origem: "oportunidades",
      referencia_id: referenciaId,
      situacao,
      is_nps: isNps,
      flag_permissao_limite: flagPermissaoLimite,
    };

    if (enviarEmail) {
      if (isNps) {
        mala = {
          ...mala,
          enviar_email: true,
          email_remetente_id: sender,
          email_conteudo: bodyText + npsTextEmail,
          email_assunto: subject,
        };
      } else {
        mala = {
          ...mala,
          enviar_email: true,
          email_remetente_id: sender,
          email_conteudo: bodyText,
          email_assunto: subject,
        };
      }
    }
    if (enviarSms) {
      if (isNps) {
        mala.sms_conteudo = smsConteudo + npsTextSms;
      } else {
        mala.sms_conteudo = smsConteudo;
      }

      mala.sms_acentuado = smsAcentuado;
      mala.enviar_sms = true;
    }

    return mala;
  }

  function loadReferenciaId() {
    console.log("LOAD REFERENCIA ID");
    console.log(isEdit);
    if (!isEdit) {
      console.log("LOADING REFERENCIA ID");
      const {
        match: { params },
      } = props;
      const { referencia_id } = params;
      setReferenciaId(referencia_id);
    } else {
      console.log("NÃO PRECISA REFERENCIA ID");
    }
  }

  const notify = (type, title, msg = "") => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    if (notificationAlert.current)
      notificationAlert.current.notificationAlert(options);
  };

  const redirectBack = () => {
    onBack ? onBack() : history.goBack();
  };

  async function destroyRecipients() {
    try {
      await api.delete(
        `common/empresas/${empresaSelecionada.id}/destinatarios-malas-diretas-temporarios/${referenciaId}`
      );
    } catch (error) {
      notify("danger", "Ocorreu um erro...");
    } finally {
      redirectBack();
    }
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>

      <Modal
        className="modal-dialog-centered modal-danger"
        size="md"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            {pedirPermissaoLimite
              ? "Permitir Exceder Limite"
              : "Limite Excedido"}
          </h6>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="fas fa-exclamation-circle ni-3x" />
            <h4 className="heading mt-4"></h4>
            <p>
              {pedirPermissaoLimite
                ? erros.limite_permissao
                : erros.limite_bloquear}
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setPedirPermissaoLimite(false);
              setShowModal(false);
            }}
          >
            Fechar
          </Button>
          {pedirPermissaoLimite && (
            <Button
              onClick={() => setFlagPermissaoLimite(true)}
              className="btn-white"
              color="default"
              type="button"
            >
              Permitir
            </Button>
          )}
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered modal-danger"
        size="md"
        isOpen={showModalUpdateMalaInativa}
        toggle={() => setShowModalUpdateMalaInativa(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Salvar Mala Direta Inativa
          </h6>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="fas fa-exclamation-circle ni-3x" />
            <h4 className="heading mt-4"></h4>
            <p>Deseja realmente manter a Mala Direta Inativa?</p>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setShowModalUpdateMalaInativa(false);
              setSaving(false);
            }}
          >
            Fechar
          </Button>

          <Button
            onClick={update}
            className="btn-white"
            color="default"
            type="button"
          >
            Manter Inativa
          </Button>

          <Button
            onClick={handleSalvareAtivar}
            className="btn-white"
            color="default"
            type="button"
          >
            Salvar e Ativar
          </Button>
        </div>
      </Modal>

      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <div className="card">
              <Header
                mala_direta_id={mala_direta_id}
                situacao={situacao}
                setSituacao={setSituacao}
                erros={erros}
              />
              <CardBody>
                <Row>
                  <Col sm="6" md="6" lg="6">
                    <FormGroup>
                      <label className="form-control-label">Nome*</label>
                      <Input
                        placeholder="Informe o nome da mala direta..."
                        className="form-control"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                      />
                      <small class="text-danger">{erros.nome || ""}</small>
                    </FormGroup>
                  </Col>
                  <Col lg="3" sm="3" md="3">
                    <FormGroup>
                      <label className="form-control-label">
                        Data de agendamento*
                        <Button
                          color="secondary"
                          id="tooltip8762793492233"
                          outline
                          size="sm"
                          type="button"
                        >
                          ?
                        </Button>
                        <UncontrolledPopover
                          placement="top"
                          target="tooltip8762793492233"
                        >
                          <PopoverBody>
                            Informe a data e hora dos envios
                          </PopoverBody>
                        </UncontrolledPopover>
                      </label>
                      <InputGroup className="input-group">
                        <ReactDatetime
                          value={dataAgendamento}
                          inputProps={{
                            placeholder: "Data para enviar a Mala Direta...",
                            style: { position: "absolute" },
                          }}
                          isValidDate={(current) => {
                            return current.isSameOrAfter(
                              moment().startOf("day")
                            );
                          }}
                          dateFormat="DD/MM/YYYY"
                          timeFormat="HH:mm"
                          onChange={(e) => setDataAgendamento(e)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            style={{
                              padding: "10px",
                              height: "calc(2.75rem + 2px)",
                            }}
                          >
                            <i class="far fa-calendar-alt" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <small class="text-danger">
                        {erros.data_agendamento || ""}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col lg="3" sm="3" md="3">
                    <FormGroup>
                      <label className="form-control-label">
                        Pesquisa de Satisfação*
                        <Button
                          color="secondary"
                          id="tooltip8762793492233"
                          outline
                          size="sm"
                          type="button"
                        >
                          ?
                        </Button>
                        <UncontrolledPopover
                          placement="top"
                          target="tooltip8762793492233"
                        >
                          <PopoverBody>
                            Informe se a Mala Direta está relacionada a uma
                            Pesquisa de Satisfação
                          </PopoverBody>
                        </UncontrolledPopover>
                      </label>
                      <div>
                        <label className="custom-toggle">
                          <input
                            id="npsCheck"
                            type="checkbox"
                            value={isNps}
                            checked={isNps}
                            onChange={({ target }) => setIsNps(target.checked)}
                          />
                          <span
                            class="custom-toggle-slider rounded-circle"
                            data-label-off="Não"
                            data-label-on="Sim"
                          ></span>
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={12} md={12}>
                    <FormGroup>
                      <label className="form-control-label">Descrição</label>
                      <Input
                        className="form-control"
                        type="textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      <small class="text-danger">
                        {erros.description || ""}
                      </small>
                    </FormGroup>
                  </Col>
                </Row>
                <ContentToSend
                  sender={sender}
                  setSender={setSender}
                  subject={subject}
                  setSubject={setSubject}
                  midias={midias}
                  setMidias={setMidias}
                  bodyText={bodyText}
                  setBodyText={setBodyText}
                  npsTextEmail={npsTextEmail}
                  setNpsTextEmail={setNpsTextEmail}
                  enviarSms={enviarSms}
                  setEnviarSms={setEnviarSms}
                  enviarEmail={enviarEmail}
                  setEnviarEmail={setEnviarEmail}
                  smsConteudo={smsConteudo}
                  setSmsConteudo={setSmsConteudo}
                  smsAcentuado={smsAcentuado}
                  setSmsAcentuado={setSmsAcentuado}
                  npsTextSms={npsTextSms}
                  setNpsTextSms={setNpsTextSms}
                  tabActive={tabActive}
                  setTabActive={setTabActive}
                  isNps={isNps}
                  isEdit={isEdit}
                  erros={erros}
                  notify={notify}
                  destinatarioEmail={
                    <Destinatarios
                      tipo_contato="email"
                      notify={notify}
                      referencia_id={referenciaId}
                      isEdit={isEdit}
                      malaDireta={malaDireta}
                    />
                  }
                  destinatarioSms={
                    <Destinatarios
                      tipo_contato="telefone"
                      notify={notify}
                      referencia_id={referenciaId}
                      isEdit={isEdit}
                      malaDireta={malaDireta}
                    />
                  }
                  {...props}
                />
              </CardBody>
              <CardFooter
                className=""
                style={{
                  position: "sticky",
                  bottom: 0,
                  border: "2 solid",
                  boxShadow: "0px 0px 2px 2px #f5f5f5",
                  zIndex: 50,
                }}
              >
                <Row>
                  <Col>
                    <div
                      className="float-right "
                      style={{
                        flexWrap: "wrap",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        className="ml-auto mt-1"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={destroyRecipients}
                      >
                        Voltar
                      </Button>
                      <Button
                        id={`salvar-mala-direta`}
                        className=" mt-1"
                        disabled={saving}
                        color="primary"
                        onClick={handleSave}
                      >
                        <Spinner
                          hidden={!saving}
                          className="mr-2"
                          color="light"
                          size="sm"
                        />
                        Salvar
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target={`salvar-mala-direta`}
                      >
                        Salvar mala direta
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
