import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import classnames from "classnames";

// import ExpandedFilters from './ExpandedFilters'
import ReactDatetime from "react-datetime";
//@ts-ignore
import Select2 from "react-select2-wrapper";
import { Button, FormGroup, InputGroup } from "reactstrap";

import { Gradient } from "./styles";
import api from "../../../../services/api";
import { usePersistedState } from "../../../../hooks";
import ICanal from "../../../../entities/Marketing/Canal";
import { UncontrolledCollapse } from "reactstrap/lib";
import { KpisContext } from "../../../../contexts/Kpis";

interface IGraficos {
  mes: string;
  ano: string;
  campanha: any;
  setAno: any;
  setMes: any;
  onDateChanged?: any;
  canais: any;
  setCanais: any;
}

const Filters: React.FC<IGraficos> = ({
  onDateChanged,
  ano,
  mes,
  campanha,
  setAno,
  setMes,
  canais,
  setCanais,
}) => {
  const [showCalendar, setShowCalandar] = useState(false);
  const [showDetails, setShowDetatails] = useState(false);
  const [showFilterCanais, setShowFilterCanais] = useState(false);
  const [canaisList, setCanaisList] = useState<Array<ICanal>>([]);
  const { requestAvaliable, handlerequestAvaliable } = useContext(KpisContext);
  const [canaisSelecionados, setCanaisSelecionados] = useState<Array<number>>(
    []
  );

  useEffect(() => {
    setShowCalandar(false);
  }, [showDetails]);

  useEffect(() => {
    (async () => {
      if (canaisList.length === 0) await loadCanais();
    })();
  }, []);

  function handleDateSelected(e: any) {
    try {
      setMes(e.format("MM"));
      setAno(e.format("YYYY"));
    } catch (error) {
      console.error(error);
    }
  }

  async function loadCanais() {
    try {
      const response = await api.get(`/marketing/canais`);
      setCanaisList(response.data);
      if (response.data.length > 0) {
        response.data.unshift({ id: -1, nome: "Todos Canais" });
        setCanaisList(Array.from(response.data));
        // @ts-ignore
        const arr = Array.from(response.data).map((e: ICanal) => e.id);
        setCanaisSelecionados(arr);
        setCanais(arr);
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar canais");
    }
  }

  function notify(type: any, msg: any) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
  }

  function handleShow(e: any) {
    //Se ação do botão for acionada pelo Botão Principal, ou pelo Botão de escolha de Mês
    //é acionado o método de Ocultar/Mostrar Calendário
    console.log(e.target.className);
    if (
      e.target.className.includes(
        "form-control-flush h2 text-blue d-inline-block mb-0"
      ) ||
      e.target.className.includes("rdtMonth") ||
      e.target.className.includes(
        "btn-neutral btn btn-default btn-sm btn btn-secondary btn-sm"
      )
    ) {
      setShowCalandar(!showCalendar);
    }
  }

  const handleCheckAll = () => {
    let meusCanais = [];
    if (canaisSelecionados.length < canaisList.length) {
      meusCanais = canaisList.map((e: ICanal) => e.id);
      setCanaisSelecionados(meusCanais);
    } else {
      meusCanais = [canaisList[1].id];
      setCanaisSelecionados(meusCanais);
    }
    setCanais(meusCanais);
  };

  const handleCheckCanais = (id: number) => {
    let meusCanais = [];
    const has = canaisSelecionados.find((e: number) => e == id);
    if (has) {
      if (canaisSelecionados.length == 1) return;
      meusCanais = canaisSelecionados.filter((e: number) => e != id);
      setCanaisSelecionados(meusCanais);
    } else {
      meusCanais = [...canaisSelecionados, ...[id]];
      setCanaisSelecionados(meusCanais);
    }
    setCanais(meusCanais);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          position: "sticky",
          top: 0,
          zIndex: 100,
          marginTop: "-150px",
          paddingBottom: "20px",
        }}
      >
        <Button
          className="btn-neutral btn btn-default btn-sm"
          style={{ marginTop: 12, marginRight: "-5px" }}
          color="secondary"
          size="sm"
          onClick={handleShow}
          type="button"
        >
          <ReactDatetime
            //@ts-ignore
            style={{
              marginTop: -37,
              visibility: false,
              position: "absolute",
              zIndex: 1000,
            }}
            open={showCalendar}
            inputProps={{
              readOnly: true,
              className: "form-control-flush h2 text-blue d-inline-block mb-0",
              style: {
                background: "#ffff",
                textAlign: "center",
                color: "#11CDEF",
                cursor: "pointer",
              },
            }}
            defaultValue={moment()}
            value={moment(`${mes}-${ano}`, "MM/YYYY")}
            dateFormat={"MMMM/YYYY"}
            viewMode="months"
            timeFormat={false}
            onChange={handleDateSelected}
          />
        </Button>
        <Button
          className="btn-icon btn-2 ml-2"
          style={{
            marginTop: 12,
            height: "45px",
            width: "45px",
            color: "#0847d6",
            background: "rgb(255, 255, 255)",
          }}
          color="secondary"
          size="sm"
          id="buttonToggler"
          onClick={() => setShowFilterCanais(!showFilterCanais)}
          type="button"
        >
          <span className="btn-inner--icon">
            <i className={`fa fa-filter`}></i>
          </span>
        </Button>
      </div>
      <div
        style={{
          textAlign: "center",
          marginBottom: "10px",
          position: "sticky",
          top: "60px",
          zIndex: 100,
          marginTop: "0px",
          padding: "8px 16px",
        }}
      >
        <UncontrolledCollapse
          toggler="#buttonToggler"
          isOpen={showFilterCanais}
        >
          <div
            className="btn-group-toggle"
            data-toggle="buttons"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            {canaisList.map(({ nome, id }) => {
              return (
                <>
                  <Button
                    className={
                      canaisSelecionados.find((e: number) => e == id)
                        ? "active"
                        : ""
                    }
                    color="secondary"
                    tag="label"
                    size="sm"
                    style={{
                      padding: "0px 4px",
                      boxShadow:
                        "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
                    }}
                  >
                    <input
                      autoComplete="off"
                      type="checkbox"
                      value={id}
                      onChange={() => {
                        if (!requestAvaliable) return;
                        if (id != -1) {
                          handleCheckCanais(id);
                        } else {
                          handleCheckAll();
                        }
                      }}
                    />
                    {nome}
                  </Button>
                </>
              );
            })}
          </div>
        </UncontrolledCollapse>
      </div>
    </>
  );
};

export default Filters;
