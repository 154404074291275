import styled from "styled-components";

export const ClienteInputContainer = styled.div`
    
    @media (max-width: 575.98px) { 
        width: 58%
     }


    @media (min-width: 576px) and (max-width: 767.98px) { 
        width: 80%
    }


    @media (min-width: 768px) and (max-width: 991.98px) { 
        width: 80%
    }


    @media (min-width: 992px) and (max-width: 1199.98px) { 
        width: 77%
    }


    @media (min-width: 1200px) { 
        width: 80%
    }
`

export const AnuncioInputContainer = styled.div`    
    width: calc(100% - 58px)
`