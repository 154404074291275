import React, { useState, useEffect, useContext, useRef } from "react";
import SimpleHeader from "../../../../../components/Headers/SimpleHeader";
import { Empresa, SiteEmpresa } from "../../../../../entities/Common";
import EmpresaContext from "../../../../../contexts/Empresa";
// @ts-ignore
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Row,
  Col,
  Table,
  Container,
  FormGroup,
  FormText,
  Input,
  Label,
  Card,
  CardBody,
  Collapse,
  Spinner,
  CardHeader,
} from "reactstrap";
import Select from "react-select";

import api from "../../../../../services/api";

//Definindo as propriedades desse componente

const Sites: React.FC = () => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [file, setFile] = useState<File>();
  const [estagios, setEstagios] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [motivosEncerramento, setMotivosEncerramento] = useState([]);
  const [funis, setFunis] = useState([]);
  const [collapsedMetaData, setCollapsedMetaData] = useState(false);
  const [campanhas, setCampanhas] = useState([]);
  const [anuncios, setAnuncios] = useState([]);
  const [campanha, setCampanha] = useState<{ id: number; label: string }>();
  const [anuncio, setAnuncio] = useState<{ id: number; label: string }>();
  const [produtos, setProdutos] = useState([]);
  const [errorData, setErrorData] = useState({});
  const [saving, setSaving] = useState(false);
  const [separator, setSeparator] = useState(";");
  const notificationAlert = useRef<any>();

  useEffect(() => {
    loadEstagios();
    loadColaboradores();
    loadMotivosEncerramentos();
    loadFunis();
    loadCampanhas();
    loadProdutos();
  }, []);

  useEffect(() => {
    if (campanha) loadAnuncios(campanha.id);
  }, [campanha]);
  const handleSendFile = async () => {
    if (campanha && anuncio && file) {
      try {
        setSaving(true);
        setErrorData({});

        let formData = new FormData();
        formData.append("importCsv", file as File);
        formData.append("delimiter", ";");
        formData.append("anuncio_id", anuncio?.id + "");
        formData.append("campanha_id", campanha?.id + "");
        await api.post(`/common/import/cliente-oportunidade`, formData);
        notify("success", "Importacão realizada com sucesso!");
      } catch (error: any) {
        // @ts-ignore
        setErrorData(
          error?.response?.data?.error?.message ||
            error?.response?.data?.error ||
            error?.response?.data ||
            error?.response ||
            error
        );
        notify("danger", "ops algo deu errado :/");
        // @ts-ignore
        console.log(error.toJSON());
      } finally {
        setSaving(false);
      }
    } else {
      const semCampanha = "Selecione uma campanha";
      const semAnuncio = "Selecione um anuncio";
      const semFile = "Selecione um arquivo";
      console.log(file);

      notify(
        "warning",
        `${!file ? semFile : ""} ${!anuncio?.id ? semAnuncio : ""} ${
          !campanha?.id ? semCampanha : ""
        } `
      );
    }
  };
  async function loadEstagios() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/estagios`
      );
      const data = await response.data;
      setEstagios(data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar estagios");
    }
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  async function loadColaboradores() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/colaboradores?`
      );
      if (response.data) {
        setColaboradores(response.data);
      }
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar os colaboradores");
    }
  }

  async function loadMotivosEncerramentos() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/motivos-encerramentos`
      );
      setMotivosEncerramento(await response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar os motivos");
    }
  }

  async function loadFunis() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/funis`
      );
      if (response.data) {
        setFunis(response.data);
      }
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar os Funis");
    }
  }
  async function loadCampanhas() {
    const response = await api.get(
      `marketing/campanha/${empresaSelecionada?.id}`,
      {
        params: {
          situacao: "ativo",
        },
      }
    );
    setCampanhas(response.data);
  }

  async function loadAnuncios(campanha_id: number) {
    if (campanha_id) {
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/campanhas/${campanha_id}/anuncios`,
        {
          params: {
            situacao: "ativo",
          },
        }
      );
      setAnuncios(response.data);
    }
  }

  async function loadProdutos() {
    const response = await api.get(
      `/common/empresas/${empresaSelecionada?.id}/produtos?limit=10000`,
      {
        params: {
          situacao: "A",
        },
      }
    );
    setProdutos(response.data.data);
  }

  const ButtonHelp = () => (
    <>
      <Button
        color="link"
        id="tooltip-99882211"
        outline
        size="sm"
        onClick={() =>
          window.open(
            "https://ajuda.playnee.com/artigos/importacao-de-oportunidades",
            "_blank"
          )
        }
        type="button"
      >
        ?
      </Button>
    </>
  );

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="Importacões" parentName="Minha empresa" />
      <div className="container-fluid mt--6">
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{ position: "sticky", top: "0px", zIndex: 200 }}
              >
                <h1>Importações</h1>
                <ButtonHelp />
              </CardHeader>
              <CardBody>
                <a
                  className="btn-white btn"
                  href={require("./modelo-cliente-oportunidade-v1.csv")}
                  download="modelo-cliente-oportunidade-v1.csv"
                >
                  <i className="fas fa-download"></i> Baixar CSV modelo
                </a>
                <Button
                  style={{ margin: `1rem` }}
                  onClick={() => setCollapsedMetaData(!collapsedMetaData)}
                  className="btn-white"
                  color="default"
                  type="button"
                >
                  <i className="fas fa-angle-down"></i> Dados sobre a empresa
                </Button>
                <Card>
                  <Collapse isOpen={collapsedMetaData}>
                    <CardBody>
                      <section
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexBasis: 200,
                          flexWrap: "wrap",
                          gap: 16,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexGrow: 1,
                            backgroundColor: "#f8f8f8",
                          }}
                        >
                          <Table bordered size="sm" striped>
                            <caption
                              style={{
                                captionSide: "top",
                                textAlign: "center",
                              }}
                            >
                              Estágios
                            </caption>

                            <thead>
                              <tr>
                                <th className={"text-center"}>ID</th>
                                <th>Nome</th>
                              </tr>
                            </thead>
                            <tbody>
                              {estagios.map(
                                (e: { id: number; nome: string }) => {
                                  return (
                                    <tr>
                                      <td className="text-center">{e.id}</td>
                                      <td>{e.nome}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexGrow: 1,
                            backgroundColor: "#f8f8f8",
                          }}
                        >
                          <Table bordered size="sm" striped>
                            <caption
                              style={{
                                captionSide: "top",
                                textAlign: "center",
                              }}
                            >
                              Colaboradores
                            </caption>

                            <thead>
                              <tr>
                                <th className={"text-center"}>ID</th>
                                <th>Nome</th>
                              </tr>
                            </thead>
                            <tbody>
                              {colaboradores.map(
                                (e: { id: number; name: string }) => {
                                  return (
                                    <tr>
                                      <td className="text-center">{e.id}</td>
                                      <td>{e.name}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexGrow: 1,
                            backgroundColor: "#f8f8f8",
                          }}
                        >
                          <Table bordered size="sm" striped>
                            <caption
                              style={{
                                captionSide: "top",
                                textAlign: "center",
                              }}
                            >
                              Motivos Encerramento
                            </caption>

                            <thead>
                              <tr>
                                <th className={"text-center"}>ID</th>
                                <th>Nome</th>
                              </tr>
                            </thead>
                            <tbody>
                              {motivosEncerramento.map(
                                (e: { id: number; nome: string }) => {
                                  return (
                                    <tr>
                                      <td className="text-center">{e.id}</td>
                                      <td>{e.nome}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexGrow: 1,
                            backgroundColor: "#f8f8f8",
                          }}
                        >
                          <Table bordered size="sm" striped>
                            <caption
                              style={{
                                captionSide: "top",
                                textAlign: "center",
                              }}
                            >
                              Funis
                            </caption>

                            <thead>
                              <tr>
                                <th className={"text-center"}>ID</th>
                                <th>Nome</th>
                              </tr>
                            </thead>
                            <tbody>
                              {funis.map((e: { id: number; nome: string }) => {
                                return (
                                  <tr>
                                    <td className="text-center">{e.id}</td>
                                    <td>{e.nome}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexGrow: 1,
                            backgroundColor: "#f8f8f8",
                          }}
                        >
                         
                            <Table
                              bordered
                              size="sm"
                              striped
                              style={{
                                display: "block",
                                height: "70vh",
                                overflowX: "auto",
                              }}
                            >
                              <caption
                                style={{
                                  captionSide: "top",
                                  textAlign: "center",
                                }}
                              >
                                Produtos
                              </caption>

                              <thead>
                                <tr>
                                  <th className={"text-center"}>ID</th>
                                  <th>Nome</th>
                                </tr>
                              </thead>
                              <tbody>
                                {produtos.map(
                                  (e: { id: number; nome: string }) => {
                                    return (
                                      <tr>
                                        <td className="text-center">{e.id}</td>
                                        <td>{e.nome}</td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </Table>
                          </div>
                      </section>
                    </CardBody>
                  </Collapse>
                </Card>

                <Row>
                  <Col sm="12" md="6" lg="6">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">Campanha*</label>
                      </legend>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder={"Selecione uma Campanha"}
                        options={campanhas.map(
                          (item: { id: number; nome: string }) => ({
                            value: item.id,
                            label: item.nome,
                            id: item.id,
                          })
                        )}
                        value={campanha}
                        onChange={(e) => {
                          // @ts-ignore
                          setCampanha(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="6">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">Separador</label>
                      </legend>
                      <Input
                        type="text"
                        value={separator}
                        onChange={(e) => setSeparator(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="6">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">Anuncio*</label>
                      </legend>
                      <Select
                        isDisabled={!campanha?.id}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder={"Selecione uma Anuncio"}
                        options={anuncios.map(
                          (item: { id: number; nome: string }) => ({
                            value: item.id,
                            label: item.nome,
                            id: item.id,
                          })
                        )}
                        value={anuncio}
                        onChange={(e) => {
                          // @ts-ignore
                          setAnuncio(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="6">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">
                          Escolher CSV*
                        </label>
                      </legend>
                      <Input
                        id="exampleFile"
                        name="file"
                        type="file"
                        accept=".csv"
                        onChange={(e) => {
                          if (e.target.files?.length)
                            setFile(e.target.files[0]);
                        }}
                      />
                      <FormText>
                        Escolha um arquivo .csv que foi preenchido seguindo o
                        arquivo de modelo
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    disabled={saving}
                    onClick={handleSendFile}
                  >
                    Enviar Arquivo
                    <Spinner
                      hidden={!saving}
                      className="mr-2"
                      color="light"
                      size="sm"
                    />
                  </Button>
                </div>
                <br />
                {Object.keys(errorData).length > 0 && (
                  <code
                    style={{
                      color: "red",
                    }}
                  >
                    {JSON.stringify(errorData)}
                  </code>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Sites;
