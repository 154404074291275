import React, { useState, useEffect, useContext, cloneElement } from "react";
import api from "../../../services/api";
import AuthContext from "../../../contexts/Auth";
import EmpresaContext from "../../../contexts/Empresa";

import Assinatura from "./Assinatura";
import Checagem from "./Checagem";
import Colaborador from "./Colaborador";
import Hitorico from "./Historico";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import OportunidadeSimplificada from "./OportunidadeSimplificada";
import GerarLinkQRCode from "./GerarLinkQRCode";
import moment from "moment";
import ListaIndicacoes from "../ProgramaDeIndicacao/ListaIndicacoes";
import Vendas from "../../proto/Vendas";

const actionComponentHandler = {
  vincular_oportunidade_proposta_concessionaria: (props) => {
    return <Vendas {...props}/>;
  },
};

export default (
  {
    oportunidade,
    anuncio,
    handleChangeEstagio,
    handlePoliticaAssinada,
    onChangeMotivoEncerramento,
    onColaboradorChanged,
    notify,
    history,
    currentdataAgendamento,
  },
  ...props
) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const { hasPermission } = useContext(AuthContext);
  const [estagio, setEstagio] = useState({});
  const [alert, setAlert] = useState(null);

  const [alertCrossSelling, setAlertCrossSelling] = useState(null);

  const [showHistorico, setShowHistorico] = useState(false);
  const [showOportunidadeSimplificada, setShowOportunidadeSimplicada] =
    useState(false);
  const [showGerarLinkQRCode, setShowGerarLinkQRCode] = useState(false);
  const [showListarIndicacoes, setShowListarIndicacoes] = useState(false);

  const [estagios, setEstagios] = useState([]);

  // const [acoesInteligentes, setAcoesInteligentes] = useState([]);
  const [currentAction, setCurrentAction] = useState("");

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (estagios.length === 0) {
      loadEstagios();
    }
  }, []);

  useEffect(() => {
    console.log("USE EFFECT ANUNCIO");
    loadEstagios();
  }, [anuncio]);

  useEffect(() => {
    if (oportunidade.id && oportunidade.estagio_id != estagio.id)
      setEstagio(oportunidade.estagio);
  }, [oportunidade]);

  async function loadEstagios() {
    try {
      var urlGetEstagios = "";

      console.log("LOAD ESTÁGIOS");
      if (isNew()) {
        //altera para buscar pelos estágios do funil padrão da empresa
        console.log("NOVA OPORTUNDIADE - isNew(TRUE)");
        console.log({ anuncio });

        if (anuncio && anuncio > 0) {
          console.log("FUNIL PADRAO ANUNCIO");
          urlGetEstagios = `/common/empresas/${empresaSelecionada?.id}/funilpadraoanuncio/${anuncio}`;
        } else {
          console.log("FUNIL PADRAO EMPRESA");
          urlGetEstagios = `/common/empresas/${empresaSelecionada?.id}/funilpadraoempresa`;
        }
      } else {
        console.log("ALTERAR OPORTUNDIADE - isNew(FALSE)");
        //alterar para busca por estágios do funil da oportunidade
        //urlGetEstagios = `/common/empresas/${empresaSelecionada?.id}/estagios`;
        if (oportunidade && oportunidade.id > 0 && oportunidade.funil_id > 0) {
          console.log("FUNIL DA OPORTUNIDADE");
          urlGetEstagios = `/common/empresas/${empresaSelecionada?.id}/funis/${oportunidade?.funil_id}/estagios`;
        } else {
          if (anuncio && anuncio > 0) {
            console.log("FUNIL DO ANUNCIO DA OPORTUNIDADE");
            urlGetEstagios = `/common/empresas/${empresaSelecionada?.id}/funilpadraoanuncio/${anuncio}`;
          }
        }
      }

      //console.log(urlGetEstagios)

      const response = await api.get(urlGetEstagios);
      setEstagios(await response.data);

      if (!isNew() && response.data.length > 0) {
        if (!estagio || !estagio.id) {
          setEstagio(estagio[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const confirmAlertUpdateFase = (
    title,
    msg,
    confirmFunction,
    confirmButtonLabel = "Ok",
    confirmButtonColor = "primary"
  ) => {
    setAlert(
      <ReactBSAlert
        warning
        closeOnClickOutside={false}
        title={title}
        onCancel={() => setAlert(null)}
        onConfirm={confirmFunction}
        showCancel
        confirmBtnColor={confirmButtonColor}
        confirmBtnText={confirmButtonLabel}
        cancelBtnText="Cancelar"
        btnSize=""
      >
        {msg}
      </ReactBSAlert>
    );
  };

  const confirmAlertCrossSelling = (
    title,
    msg,
    confirmFunction,
    confirmButtonLabel = "Ok",
    confirmButtonColor = "primary"
  ) => {
    setAlertCrossSelling(
      <ReactBSAlert
        warning
        closeOnClickOutside={false}
        title={title}
        onCancel={() => setAlertCrossSelling(null)}
        onConfirm={confirmFunction}
        showCancel
        confirmBtnColor={confirmButtonColor}
        confirmBtnText={confirmButtonLabel}
        cancelBtnText="Não"
        btnSize=""
      >
        {msg}
      </ReactBSAlert>
    );
  };

  async function getAcoesInteligentes(estagioId) {
    const { data } = await api.get(
      `marketing/funis/${oportunidade.funil_id}/estagios/${estagioId}`
    );
    const actions = data
      .map((item) => item.acoesInteligentes.map((e) => e.key))
      .flatMap((num) => num);
    return actions;
  }

  async function onChangeEstagio(proximoEstagio) {
    const acoesInteligentes = await getAcoesInteligentes(proximoEstagio.estagio_id);

    if (!isNew()) {
      confirmAlertUpdateFase(
        "Alterar estágio",
        <>
          Deseja realmente alterar o estágio dessa oportunidade para{" "}
          <strong>{proximoEstagio.nome}</strong>? <br />
          <br />
          Verifique a data de agendamento <br />
          <strong>
            {" "}
            {moment(currentdataAgendamento).format("DD/MM/YYYY HH:mm")}
          </strong>
        </>,
        () => validateActions(proximoEstagio, acoesInteligentes),
        "Sim"
      );
    }
  }

  const successAlert = (msg) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
        title={msg}
        confirmBtnBsStyle="success"
        showConfirm={false}
        btnSize=""
      />
    );
    setTimeout(() => setAlert(null), 2000);
  };

  function onChangeEstagioToVenda() {
    if (!isNew()) {
      confirmAlertCrossSelling(
        "Criar Cross-Selling",
        <>
          Deseja criar uma nova oportunidade a partir dos produtos declinados
          desta oportunidade encerrada?
        </>,
        () => criarNovaOportunidadeCrossSelling(),
        "Sim"
      );
    }
  }

  function goToNewCrossSelling() {
    history.push("/admin/oportunidades/new", {
      oportunidadeToCrossSelling: oportunidade.id,
      tipoCrossSelling: "produtos_declinados",
    });
  }

  async function criarNovaOportunidadeCrossSelling() {
    try {
      //volta pra tela de oportunidades, e logo após abre a tela Nova oportunidade carregando os dados
      //de cliente, produtos declinados e espectativa da oportunidade encerrada como venda.
      setTimeout(history.goBack, 200);
      setTimeout(goToNewCrossSelling, 500);
    } catch (error) {
      console.log(error);
      if (notify) notify("danger", "Não foi possível abrir nova oportunidade");
    }
  }

  async function validateActions(nextEstagio, acoesInteligentes) {
    const exists = acoesInteligentes.some((item) =>
      Object.keys(actionComponentHandler).includes(item)
    );

    if (acoesInteligentes.length && exists) {
      for (const action of acoesInteligentes) {
        setCurrentAction(
          actionComponentHandler[action]({
            oportunidade: oportunidade,
            callback: () => changeToEstagio(nextEstagio),
          })
        );
        setModal(true);
      }
    } else {
      changeToEstagio(nextEstagio);
    }
  }

  async function changeToEstagio(nextEstagio) {
    try {
      const response = await api.put(
        `/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidade.id}/avancar-oportunidades`,
        {
          estagio_id: nextEstagio.id,
          data_agendamento: currentdataAgendamento,
        }
      );
      const data = await response.data;
      const data_encerramento = await data.data_encerramento;
      const motivoEncerramento = await data.motivoEncerramento;
      setEstagio(nextEstagio);
      if (onChangeMotivoEncerramento && data_encerramento && motivoEncerramento)
        onChangeMotivoEncerramento(motivoEncerramento);
      if (handleChangeEstagio) {
        handleChangeEstagio(estagio);
      }
      successAlert("Estágio alterado");
      setModal(false);
      if (nextEstagio && nextEstagio.id == 3) {
        var valid = await thisOportunidadeContemProdutosDeclinados();
        if (valid) {
          onChangeEstagioToVenda();
        }
      }

    } catch (error) {
      console.log(error);
      if (notify) notify("danger", "Não foi possível alterar estagio");
    } 
  }

  async function thisOportunidadeContemProdutosDeclinados() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidade.id}/produtos_declinados`
      );
      var prodDeclidados = response.data;
      var hasProdutosDeclinados = prodDeclidados && prodDeclidados.length > 0;
      return hasProdutosDeclinados;
    } catch (error) {
      notify("danger", "Não foi possivel carregar produtos declinados");
      return false;
    }
  }

  function isNew() {
    return !(oportunidade && oportunidade.id);
  }

  function handleHiddeHistorico() {
    setShowHistorico(false);
  }

  function canShowHistorico() {
    return !isNew() && hasPermission("ver-todas-oportunidades");
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        style={{ maxWidth: "1200px" }}
        backdrop="static"
      >
        <ModalBody>{currentAction}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      {alert}
      {alertCrossSelling}
      {canShowHistorico() && (
        <Hitorico
          notify={notify}
          show={showHistorico}
          onHidde={handleHiddeHistorico}
          oportunidade_id={oportunidade.id}
        />
      )}
      <h1 className="d-flex" style={{ justifyContent: "space-between" }}>
        {isNew() ? (
          <span>Nova Oportunidade</span>
        ) : (
          <span>Alterar Oportunidade</span>
        )}
        {hasPermission("ver-todas-oportunidades") && (
          <Button
            style={{
              padding: 6,
            }}
            color="link"
            title="Histórico"
            onClick={() => setShowHistorico(true)}
          >
            <i class="far fa-clock"></i>
          </Button>
        )}
      </h1>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {!oportunidade.data_encerramento && (
          <>
            <Colaborador
              oportunidade={oportunidade}
              notify={notify}
              onColaboradorChanged={onColaboradorChanged}
            />
          </>
        )}
        {!isNew() ? (
          //   <Button
          //     size="sm"
          //     style={{
          //       padding: 6,
          //     }}
          //     color="primary"
          //     title="Programa de indicação"
          //     onClick={() => setShowOportunidadeSimplicada(true)}
          //   >
          //     <span className="btn-inner--icon">
          //       <i className="ni ni-fat-add"></i>
          //     </span>
          //     <span className="btn-inner--text">Programa de Indicação</span>
          //   </Button>

          <UncontrolledDropdown
            style={{
              paddingRight: 4,
            }}
            group
            size="sm"
            direction="down"
          >
            <DropdownToggle
              caret
              //   disabled={isNew() || oportunidade.data_encerramento}
              color={"primary"}
            >
              {" "}
              Programa de Indicação
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => setShowOportunidadeSimplicada(true)}>
                Registrar
              </DropdownItem>
              <DropdownItem onClick={() => setShowGerarLinkQRCode(true)}>
                Gerar Link
              </DropdownItem>
              <DropdownItem onClick={() => setShowListarIndicacoes(true)}>
                Listar indicações
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : null}
        <OportunidadeSimplificada
          isOpen={showOportunidadeSimplificada}
          clienteIndicadorId={oportunidade.cliente_id}
          data={oportunidade}
          hideModal={() => setShowOportunidadeSimplicada(false)}
        />
        <GerarLinkQRCode
          isOpen={showGerarLinkQRCode}
          clienteIndicadorId={oportunidade.cliente_id}
          data={oportunidade}
          hideModal={() => setShowGerarLinkQRCode(false)}
          notify={notify}
        />
        {showListarIndicacoes && (
          <ListaIndicacoes
            isOpen={showListarIndicacoes}
            clienteIndicadorId={oportunidade.cliente_id}
            hideModal={() => setShowListarIndicacoes(false)}
            history={history}
          />
        )}

        <UncontrolledDropdown
          style={{
            paddingRight: 4,
          }}
          group
          size="sm"
          direction="down"
        >
          <DropdownToggle
            caret
            disabled={isNew() || oportunidade.data_encerramento}
            color={
              isNew() || estagio?.cor?.includes("#") ? "primary" : estagio?.cor
            }
            style={
              estagio?.cor?.includes("#")
                ? { backgroundColor: estagio?.cor }
                : {}
            }
          >
            {isNew()
              ? estagios.length > 0
                ? estagios[0].nome
                : ""
              : estagio?.nome}
          </DropdownToggle>
          <DropdownMenu right>
            {!isNew() &&
              estagios.length > 0 &&
              estagios.map((item, key) => {
                if (item.id === estagio?.id) return null;
                return (
                  <DropdownItem
                    key={key}
                    onClick={(e) => {
                      e.preventDefault();
                      onChangeEstagio(item);
                    }}
                  >
                    {item.nome}
                  </DropdownItem>
                );
              })}
          </DropdownMenu>
        </UncontrolledDropdown>
        {!oportunidade.data_encerramento && (
          <>
            <Checagem
              oportunidade={oportunidade}
              notify={notify}
              onChecado={handleChangeEstagio}
            />
            <Assinatura
              oportunidade={oportunidade}
              notify={notify}
              onAssinado={handlePoliticaAssinada}
            />
          </>
        )}
      </div>
    </>
  );
};
