import React, { useState, useContext, useEffect, useRef } from 'react';
import EmpresaContext from "../../../../../contexts/Empresa";
import api from '../../../../../services/api';
import moment from "moment";
import {
    useHistory, useLocation,
} from "react-router-dom";

import {
    Objetivo,
    GrupoEstrategico,
    Estrategia,
    Acao
} from "../../../../../entities/Mentoring";

import ConcluirPDCA from "../Concluir"
import Acoes from "../Acoes";
//@ts-ignore
import Select2 from "react-select2-wrapper";
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
import ReactQuill from "react-quill";
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Row,
    Button,
    Spinner,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import ReactDatetime from "react-datetime";

import { CenterContainer } from './styles';
import { time } from 'console';

type Erros = {
    data_inicio?: string
    descricao?: string
    users?: string
    estrategia_id?: string
}

type Props = {
    objetivoId?: number
    questaoAlvoId?: number
}

const Form: React.FC<Props> = ({ objetivoId, questaoAlvoId }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const history = useHistory()

    const [objetivo, setObjetivo] = useState<Objetivo>({} as Objetivo)
    const [estrategia, setEstrategia] = useState<number | undefined>()
    const [dataInicio, setDataInicio] = useState(new Date())
    const [descricao, setDescricao] = useState("")
    const [acoes, setAcoes] = useState(new Array<Acao>())

    const [estrategias, setEstrategias] = useState(new Array<Estrategia>())
    const [grupoEstrategico, setGrupoEstrategico] = useState<number | undefined | null>()
    const [gruposEstrategicos, setGruposEstrategicos] = useState(new Array<GrupoEstrategico>())
    const [saving, setSaving] = useState(false)
    const [erros, setErros] = useState<Erros>({} as Erros)
    const [alert, setAlert] = useState<React.FC | undefined | JSX.Element>()
    const [loadingAcoes, setLoadingAcoes] = useState(false)

    const [showModalConclusao, setShowModalConclusao] = useState(false)

    const notificationAlert = useRef<NotificationAlert>(null);

    const query = useQuery();


    useEffect(() => {
        loadGruposEstrategicos()

    }, [])

    useEffect(() => {
        if (objetivoId) loadObjetivo()
    }, [objetivoId])

    useEffect(() => {
        if (objetivo && objetivo.id) {
            setEstrategia(objetivo.estrategia_id)
            setGrupoEstrategico(objetivo.estrategia?.grupo_estrategico_id)
            setDescricao(objetivo.descricao)
            setDataInicio(moment(objetivo.data_inicio).toDate())
            loadAcoes()
        }
    }, [objetivo])

    useEffect(() => {
        loadEstrategias()
    }, [grupoEstrategico])

    useEffect(() => {
        if (!objetivoId) {
            const gpId = query.get("gruposEstrategicoId") || 0
            const eId = query.get("estrategiaId") || 0

            setGrupoEstrategico(gpId as number)
            setEstrategia(eId as number)
        }
    }, [])

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }


    async function handleShowModalConclusao() {
        setShowModalConclusao(true)
    }
    async function handleHideModalConclusao() {
        setShowModalConclusao(false)
    }

    async function onObjetivoConcluido() {
        handleHideModalConclusao()
        successAlert('PDCA concluído com sucesso.')
        setTimeout(() => {
            history.goBack()
        }, 2000);
    }

    async function update() {
        try {
            await api.put(`/common/empresas/${empresaSelecionada?.id}/objetivos/${objetivo.id}`,
                {
                    ...objetivo,
                    data_inicio: dataInicio,
                    estrategia_id: estrategia,
                    descricao,
                    questao_alvo_id: (questaoAlvoId && questaoAlvoId > 0 ? questaoAlvoId : null),
                })
            successAlert('Salvo com sucesso!')
            setTimeout(() => { history.goBack() }, 2000)

        } catch (error) {
            console.log(error)
            notify('danger', 'Não conseguimos salvar o PDCA')
            //@ts-ignore
            if (error.response) updateErros(error.response.data)
        }
    }

    async function insert() {
        try {
            const response = await api.post(`/common/empresas/${empresaSelecionada?.id}/objetivos`,
                {
                    data_inicio: dataInicio,
                    estrategia_id: estrategia,
                    descricao,
                    questao_alvo_id: (questaoAlvoId && questaoAlvoId > 0 ? questaoAlvoId : null),
                })

            history.replace(`${response.data.id}/edit`)
        } catch (error) {
            console.log(error)
            notify('danger', 'Não conseguimos salvar o PDCA')
            //@ts-ignore
            if (error.response) updateErros(error.response.data)
        }
    }

    async function handleSave() {
        setErros({})
        setSaving(true)
        if (objetivo && objetivo.id) {
            await update()
        } else {
            await insert()
        }
        setSaving(false)
    }

    async function loadAcoes() {
        setLoadingAcoes(true)
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/objetivos/${objetivoId}/acoes`)
            setAcoes(await response.data)
        } catch (error) {
            console.log(error)
            notify('danger', 'Não conseguimos buscar as ações deste PDCA')
        } finally {
            setLoadingAcoes(false)
        }
    }


    function updateErros(error: any) {
        if (error && error instanceof Array) {
            const errors = {}
            for (let e of error) {
                //@ts-ignore
                errors[e.field] = e.message
            }
            setErros(errors)
        } else {
            setErros({})
        }
    }

    const successAlert = (msg: string) => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
                title={msg}
                confirmBtnBsStyle="success"
                showConfirm={false}
                btnSize=""
            />
        )
        setTimeout(() => {
            setAlert(undefined)
        }, 2000);
    };


    function notify(type: string, msg: string) {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        if (notificationAlert) notificationAlert?.current?.notificationAlert(options);
    };

    async function loadObjetivo() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/objetivos/${objetivoId}`)
            const data = await response.data
            setObjetivo(data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos carregar o PDCA')
        }
    }

    async function loadEstrategias() {
        if (!grupoEstrategico) {
            setEstrategia(undefined)
            setEstrategias(new Array<Estrategia>())
            return
        }
        try {
            const response = await api.get(`mentoring/grupos-estrategicos/${grupoEstrategico}/estrategias`)
            setEstrategias(await response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos carregar as estrategias')
        }
    }

    async function loadGruposEstrategicos() {
        try {
            const response = await api.get(`/mentoring/grupos-estrategicos`)
            const data = await response.data
            setGruposEstrategicos(data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos carregar os grupos estrategicos')
        }
    }


    return (
        <>
            {alert}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <ConcluirPDCA
                show={showModalConclusao}
                hide={handleHideModalConclusao}
                objetivo={objetivo}
                onSuccess={onObjetivoConcluido}
            />
            <Container className={"mt--6"} fluid>
                <Card>
                    <CardHeader
                        style={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 200,
                        }}>
                        <h1>{objetivo.id ? 'Alterar PDCA' : 'Novo PDCA'}</h1>
                    </CardHeader>
                    <CardBody
                        outline
                        body
                        color={'danger'}
                    >
                        <Row>
                            <Col
                                sm='12'
                                md='6'
                                lg='6'
                            >
                                <FormGroup>
                                    <legend className="w-auto" style={{ margin: 0 }}>
                                        <label
                                            className="form-control-label"
                                        >
                                            Grupo Estratégico*
                                        </label>
                                    </legend>
                                    <Select2
                                        className="form-control"
                                        //@ts-ignore
                                        onSelect={(e) => setGrupoEstrategico(e.target.value)}
                                        value={grupoEstrategico}
                                        data={gruposEstrategicos.map(item => ({ id: item.id, text: item.nome, title: item.descricao }))}
                                    />
                                </FormGroup>
                            </Col>
                            <Col
                                sm='12'
                                md='6'
                                lg='6'
                            >
                                <FormGroup>
                                    <legend className="w-auto" style={{ margin: 0 }}>
                                        <label
                                            className="form-control-label"
                                        >
                                            Estratégia*
                                        </label>
                                    </legend>
                                    <Select2
                                        className="form-control"
                                        //@ts-ignore
                                        onSelect={(e) => setEstrategia(e.target.value)}
                                        value={estrategia}
                                        data={estrategias.map(item => ({ id: item.id, text: item.nome, title: item.descricao }))}
                                    />
                                    <small className="text-danger">
                                        {erros?.estrategia_id || ""}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="6" lg="6">
                                <FormGroup>
                                    <legend className="w-auto" style={{ margin: 0 }}>
                                        <label
                                            className="form-control-label"
                                        >
                                            Data de início*
                                        </label>
                                    </legend>
                                    <InputGroup className="input-group" >
                                        <ReactDatetime
                                            closeOnSelect={true}
                                            isValidDate={current => {
                                                return current.isAfter(new Date(new Date().setDate(new Date().getDate() - 1)));
                                            }}
                                            locale={'pt-br'}
                                            value={dataInicio}
                                            inputProps={{
                                                placeholder: "Data de início...",
                                            }}
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat='HH:mm'
                                            //@ts-ignore
                                            onChange={e => setDataInicio(e)}
                                        />
                                        <InputGroupAddon addonType="append"  >
                                            <InputGroupText
                                                style={{
                                                    padding: '10px'
                                                }}>
                                                <i className="far fa-calendar-alt" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    <small className="text-danger">
                                        {erros?.data_inicio || ""}
                                    </small>
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="12" lg="12">
                                <FormGroup>
                                    <legend className="w-auto" style={{ margin: 0 }}>
                                        <label
                                            className="form-control-label"
                                        >
                                            Objetivo*
                                        </label>
                                    </legend>
                                    <ReactQuill
                                        value={descricao}
                                        onChange={setDescricao}
                                        theme="snow"
                                        modules={{
                                            toolbar: [
                                                ["bold", "italic"],
                                                ["link", "blockquote", "code"],
                                                [
                                                    {
                                                        list: "ordered"
                                                    },
                                                    {
                                                        list: "bullet"
                                                    }
                                                ]
                                            ]
                                        }}
                                    />
                                    <small className="text-danger">
                                        {erros?.descricao || ""}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            loadingAcoes ?
                                <CenterContainer>
                                    <Spinner
                                        size='lg'
                                        color='primary'
                                    />
                                </CenterContainer>
                                :
                                <Acoes
                                    notify={notify}
                                    objetivoId={objetivoId}
                                    itens={acoes}
                                    reload={loadAcoes}
                                />
                        }
                    </CardBody>
                    <CardFooter
                        style={{
                            position: "sticky",
                            bottom: 0,
                            border: "2 solid",
                            boxShadow: "0px 0px 2px 2px #f5f5f5",
                            zIndex: 9
                        }}
                    >

                        <Row>
                            <Col>
                                <div className="float-left">
                                    <Button
                                        className="ml-auto"
                                        color="danger"
                                        data-dismiss="modal"
                                        disabled={saving}
                                        type="button"
                                        onClick={(e) => { e.preventDefault(); handleShowModalConclusao() }}
                                    >
                                        Concluir PDCA
                                    </Button>
                                </div>
                            </Col>
                            <Col>
                                <div className="float-right ">
                                    <>
                                        <Button
                                            className="ml-auto"
                                            color="link"
                                            data-dismiss="modal"
                                            disabled={saving}
                                            type="button"
                                            onClick={(e) => { e.preventDefault(); history.goBack() }}
                                        >
                                            Voltar
                                        </Button>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            disabled={saving}
                                            onClick={handleSave}>
                                            <Spinner
                                                hidden={!saving}
                                                className="mr-2"
                                                color="light"
                                                size="sm"
                                            />
                                            Salvar
                                        </Button>
                                    </>
                                </div>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Container>
        </>
    )
}

export default Form;