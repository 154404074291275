import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Oportunidade } from "../../../../../entities/Marketing";
import { createColumns } from '../../Columns'

import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import {
    Button,
    ButtonGroup
} from "reactstrap";

//@ts-ignore
const { ExportCSVButton } = CSVExport;

// import { Container } from './styles';

interface ITable {
    oportunidades: Array<Oportunidade>
    isConversao?: boolean
}

const Table: React.FC<ITable> = ({ oportunidades, isConversao }) => {

    const tableRef = useRef<BootstrapTable>(null)

    return (
        <>
            <ToolkitProvider
                keyField="id"
                data={oportunidades}
                columns={createColumns({
                    isToPrint: true,
                    edit: undefined,
                    printThis: undefined,
                    isConversao,
                    setComentariosOportunidade: undefined,
                }) as Array<ColumnDescription<Oportunidade>>}
                exportCSV={{
                    fileName: 'oportunidades.csv',
                    separator: ';',
                    ignoreHeader: false,
                    noAutoBOM: false,
                    blobType: 'text/csv;charset=UTF-8'
                }}
            >
                {
                    props => (
                        <div>
                            <div
                                className='pt-2'
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 100,
                                    background: '#fff'
                                }}
                            >
                                <ButtonGroup
                                    size='sm'
                                >
                                    <Button
                                        className='btn-icon'
                                        outline
                                        color='primary'
                                        onClick={() => props.csvProps.onExport()}
                                    >
                                        <span className="btn-inner--icon">
                                            <i className="fas fa-file-csv"></i>
                                        </span>
                                        <span className="btn-inner--text">Download CSV</span>
                                    </Button>
                                    <ReactToPrint
                                        trigger={() => (
                                            <Button
                                                outline
                                                className='btn-icon'
                                                color='primary'
                                            >
                                                <span className="btn-inner--icon">
                                                    <i className="fas fa-print"></i>
                                                </span>
                                                <span className="btn-inner--text">Imprimir</span>
                                            </Button>
                                        )}
                                        content={() => tableRef?.current as React.ReactInstance}
                                    />
                                </ButtonGroup>
                                <hr />
                            </div>
                            <div className="table-responsive">
                                <BootstrapTable
                                    ref={tableRef}
                                    {...props.baseProps}
                                />
                            </div>
                        </div>
                    )
                }
            </ToolkitProvider>
        </>
    )
}

export default Table;