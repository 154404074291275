import React, { createContext, useEffect, useRef, useState } from "react";
// @ts-ignore
import NotificationAlert from "react-notification-alert";

//Tipando os dados que quero para usuário
type PushNotification = {
    title?: string
    msg?: string
    click_action?: string
    type?: string
    locale: string
};

//Tipando as Props do contexto
type PropsGlobalPushNotification = {
    noty: PushNotification;
    setNoty: React.Dispatch<React.SetStateAction<PushNotification>>;
};

//Valor default do contexto
const DEFAULT_VALUE = {
    noty: {
        title: "",
        msg: "",
        click_action: "",
        type: "info",
        locale: "br",
    },
    setNoty: () => null, //função de inicialização
};

//criando nosso contexto GlobalPushNotification
const GlobalPushNotification = createContext<PropsGlobalPushNotification>(DEFAULT_VALUE);

/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
const GlobalPushNotificationProvider: React.FC = ({ children }) => {
    const [noty, setNoty] = useState<PushNotification>(DEFAULT_VALUE.noty);
    const notificationAlert = useRef()

    useEffect(() => {
        notify(noty);
    }, [noty])

    const notify = (noty: PushNotification) => {
        let options = {
            place: noty.locale,
            message: (
                noty.click_action ?
                    <a style={{
                        textDecoration: `none`,
                        color: `white`
                    }} href={noty.click_action} className="alert-text">
                        <span className="alert-title" data-notify="title">
                            {noty.title}
                        </span>
                        <span data-notify="message">
                            {noty.msg}
                        </span>
                    </a>
                    : <div className="alert-text">
                        <span className="alert-title" data-notify="title">
                            {noty.title}
                        </span>
                        <span data-notify="message">
                            {noty.msg}
                        </span>
                    </div>
            ),
            type: noty.type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };


        // @ts-ignore
        if (notificationAlert.current && noty.title) notificationAlert.current.notificationAlert(options);
    };

    return (
        <GlobalPushNotification.Provider
            value={{
                noty,
                setNoty,
            }}
        >
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            {children}
        </GlobalPushNotification.Provider>
    );
};
export { GlobalPushNotificationProvider };
export default GlobalPushNotification;

