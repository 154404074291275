

import React, { useState, useEffect, useContext, useRef } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
import moment from 'moment'

import {
    chartExample3,
} from "../../../../../variables/charts";
import { Line } from "react-chartjs-2";
import { SpinnerContainer } from '../styles'
import { EstagioButton } from './styles'

import {
    Spinner,
    Button,
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
} from "reactstrap";

function OpotunidadesMesPorEstagioFunilAnoAtualVsAnterior({ notify, mes, ano, equipe, colaborador, campanha, funil, setCardHeight }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [loading, setLoading] = useState(false)

    const [estagio, setEstagio] = useState({})
    const [estagios, setEstagios] = useState([])
    const [divEstagios, setDivEstagios] = useState([])

    const [canReloadEstagiosButton, setCanReloadEstagiosButton] = useState(true)
    const tableRef = useRef(null)

    // const [stopTimer, setStopTimer] = useState(false);

    // function multiStep() {
    //     // do some work here
    //     console.log('RODOU O TIMER')
    //     console.log(new Date())

    //     var newtime = 10000;
    //     if (!stopTimer) {
    //         console.log('Continua...')
    //         setTimeout(multiStep, newtime);
    //     }else{
    //         console.log('Parou...')
    //     }
    // }
    
    useEffect(() => {
        setCardHeight(tableRef.current.firstChild.clientHeight)
    }, [divEstagios])

    useEffect(() => {
        load()
    }, [mes, ano, equipe, colaborador, campanha, funil])


    useEffect(() => {

        if (itens && itens.length > 0) {
            const funil = itens[0].funil
            let listEstagio = []

            if (funil && funil.length > 0) {
                let index = 0
                console.log({ itens });
                for (const est of funil) {
                    listEstagio.push(
                        {
                            id: est.estagio.id,
                            nome: est.estagio.nome,
                            cor: est.cor,
                            index: index
                        }
                    )
                    index++;
                }
            }
            setEstagios(listEstagio)
        }

    }, [itens])

    useEffect(() => {
        reloadEstagiosButton()
        // multiStep()
    }, [estagios])

    useEffect(() => {
        if (canReloadEstagiosButton) {
            reloadEstagiosButton();
        }
    }, [estagio])


    function reloadEstagiosButton() {
        setCanReloadEstagiosButton(false)

        if (!(estagio && estagio.id > 0)) {
            if (estagios && estagios.length > 0) {
                setEstagio(estagios[0])
            }
        }

        let buttons = [];
        for (const est of estagios) {
            buttons.push(
                <EstagioButton 
                className={'btn btn-outline-secondary'}
                active={(estagio.id == est.id)}
                onClick={() => setEstagio(est)}
                color={est.cor}
                >
                    {est.nome}
                </EstagioButton>
            )

        }
        setDivEstagios(buttons)
        setCanReloadEstagiosButton(true)
    }


    async function load() {
        setLoading(true)
        setEstagios([])
        setDivEstagios([])
        try {
            const response = await api.get(`/dashboards/empresas/${empresaSelecionada.id}/funis-mesanoatual-mesanoanterior`, {
                params: {
                    mes,
                    ano,
                    equipe,
                    colaborador,
                    campanha_id: campanha,
                    funil_id: funil
                }
            })
            setItens(await response.data)
        } catch (error) {
            console.error(error)
            notify('Não foi possível carregar grafico de campanhas')
        }
        setLoading(false)
    }

    function createChartData() {

        const labels = itens.map(item => moment(item.periodo).format('MMM/YYYY'))

        let dataAnoAtual = []
        let dataAnoAnterior = []

        if (estagio && estagio.id > 0) {
            dataAnoAtual = itens.map(item => parseFloat(item.funil[estagio.index].valor_atual))
            dataAnoAnterior = itens.map(item => parseFloat(item.funil[estagio.index].valor_anterior))
        }

        return {
            labels,
            datasets: [
                {
                    label: 'Ano Anterior',
                    backgroundColor: '#898989',
                    borderColor: '#898989',
                    data: dataAnoAnterior,
                    fill: false
                },
                {
                    fill: false,
                    label: 'Ano Atual',
                    borderColor: '#2dce89',
                    backgroundColor: '#2dce89',
                    data: dataAnoAtual
                }
            ]
        }
    }


    return (
        <span ref={tableRef}>
            <Card style={{ minHeight: '522px' }}>
                <CardHeader>
                    <h5 class="h3 mb-0">Oportunidades por Estágio de Funil</h5>
                    <h6 class="surtitle">Oportunidades por estágio de funil do ano atual vs ano anterior, nos últimos 13 meses</h6>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg="12" sm="12" md="12" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '6px' }}>

                            <>
                                {divEstagios}
                            </>

                        </Col>
                    </Row>

                    <div className="">
                        {
                            loading ?
                                <SpinnerContainer>
                                    <Spinner
                                        color='primary'
                                        size='lg'
                                        style={{
                                            alignSelf: 'center'
                                        }}
                                    />
                                </SpinnerContainer>
                                :
                                <div className='chart'>
                                    <Line
                                        data={createChartData()}
                                        options={chartExample3.options}
                                        className="chart-canvas"
                                    />
                                </div>
                        }
                    </div>


                </CardBody>
            </Card>
        </span>
    )
}

export default OpotunidadesMesPorEstagioFunilAnoAtualVsAnterior;
