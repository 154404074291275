import React, { useState, useEffect, useContext } from "react";
import Layout from "./components/Layout";
import MsgBox from "./components/MsgBox";
import Navbar from "./components/Navbar";
import PanelChat from "./components/PanelChat";
// import ws, { AdonisSocket, Subscription, SocketMessage } from "../../../services/socket"
//@ts-ignore

// import { Container } from './styles';
import { Alert, Card, Collapse, Row } from "reactstrap";
import { Button } from "reactstrap";
// import WebSocketPlaynee from '../../../services/websocket'
// @ts-ignore
import Ws from "@adonisjs/websocket-client";
import AuthContext from "../../../contexts/Auth";
import { Col } from "reactstrap/lib";
import FormOportunidade from "../Oportunidades/Form/index";
import ChatProvider, { ChatContext } from "./chatContext";
import FacebookLogin from "../../common/Empresas/MinhaEmpresa/Integracoes/Whatsapp";
import WebSocketClient from "./AdonisWSController";
// const ws = Ws("ws://localhost:3333", {
//   path: `adonis-ws`,
//   reconnection: true,
// });

const socket = new WebSocketClient();

const Chat: React.FC = () => {
  // let ws = new WebSocketPlaynee()
  const [socketMessage, setSocketMessage] = useState();
  const [chanelChat, setChanelChat] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const [audio] = useState(
    new Audio(
      `https://lookaside.fbsbx.com/whatsapp_business/attachments/?mid=1160422821186925&ext=1655494755&hash=ATsHT9AFE2sSoQopZ6Zy66gzAgvoJn_-H6lQ5Rb5XR8pIA`
    )
  );
  const [socket, setSocket] = useState()
  const { auth } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {



    // ws.withApiToken(auth?.token).connect();
    // ws.on('open', (e: any) => {
    //   console.log(`open`, { open: e });

    // })

    // ws.on('close', (e: any) => {
    //   console.log(`close`, { close: e });

    // })

  }, []);

  return (
    <Row
      className="py-2"
      style={{
        height: "88vh",
        marginBottom: "2rem",
        marginInline: 0,
        background: "white",
      }}
    >
      <Col xs={4} style={{ maxHeight: "100%", overflow: "hidden scroll", minWidth: "375px" }}>
        <PanelChat />
      </Col>
      <Col style={{ maxHeight: "100%" }}>
        <MsgBox />
      </Col>
      {/* <Col xs={4}>
        Painel de Oportunidades
        <FormOportunidade oportunidadeId={289671} readOnly={false} oportunidadeIdToCrossSelling={undefined} tipoCrossSelling={undefined} coordenadas={undefined}/>
      </Col> */}
    </Row>
  );
};
const Aaaa = () => {
  return (
    <ChatProvider>
      <Chat />
    </ChatProvider>
  );
};
export default Aaaa;
