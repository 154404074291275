import styled from "styled-components";

interface Props {
  isSent?: boolean;
}

export const ChatHeader = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  background: white;
  border-bottom: 1px solid rgb(236, 239, 243);
`

export const MessageDateContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  .date-label {
    padding: 8px;
    background: rgb(243, 243, 243);
    border-radius: 8px;
    font-size: 0.75rem;
    color: rgb(148, 155, 174);
  }
`;

export const ChatWindow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  align-items: flex-end;
  overflow-x: hidden;
  background: rgb(236, 239, 243);
  padding-top: 4px;
`;

export const MessageBubble = styled.div<Props>`
  max-width: 80%;
  flex-direction: column;
  margin: 8px;
  padding: 6px 7px 8px 9px;
  background: ${({ isSent }) => (isSent ? "rgb(122 219 246 / 42%)" : "#fff")};
  align-self: ${({ isSent }) => (isSent ? "flex-end" : "flex-start")};
  border-radius: 7.5px;
  color: black;
  box-shadow: 0 1px 0.5px #0b141a21;

  .box {
    &--message {
      word-break: break-word;
    }
    &--timestamp {
      display: flex;
      flex: 1%;
      justify-content: flex-end;
    }
  }

  .message {
    &--text {
      font-weight: 400;
      font-size: 14.2px;
      margin-bottom: 0;
    }

    &--timestamp {
      text-align: right;
      opacity: 0.6;
      font-size: 0.7rem;
    }
  }
`;
