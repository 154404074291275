import React, { useState, useEffect, useRef } from 'react';
import api from "../../../../../../../services/api";

import Select2 from "react-select2-wrapper";
import NovoEstagio from "../New";
import NotificationAlert from "react-notification-alert";
import {
    Button,
    FormGroup,
    Modal,
    Row,
    Col,
    InputGroupAddon,
    InputGroup
} from "reactstrap";
import {
    SelectContainer
} from './styles'

export default ({ estagios, empresa, close, onSuccess, show, notify, ...props }) => {

    const [estagiosPossiveis, setEstagiosPossiveis] = useState([]);
    const [estagio, setEstagio] = useState(undefined);
    const [showNovoEstagio, setShowNovoEstagio] = useState(false)
    const notificationAlert = useRef()

    useEffect(() => {
        loadEstagiosPossiveisPossiveis();
    }, [estagios]);

    async function loadEstagiosPossiveisPossiveis() {
        try {
            const response = await api.get(`marketing/estagios`);
            const data = await response.data
            const filters = data.filter(estagio => !estagios.find(item => item.id === estagio.id));
            setEstagiosPossiveis(filters);
        } catch (error) {
            console.log(error)
            notify('danger', 'Não foi possível carregar os estagios');
        }
    }

    function handleAddColaborador() {
        if (!estagio) {
            return;
        }
        addEstagio();
    }

    function handleNovoEstagio(e) {
        e.preventDefault()
        setShowNovoEstagio(true)
    }

    function onAddedNovoEstagio() {
        close()
        onSuccess()
    }

    async function addEstagio() {
        try {
            await api.post(`/common/empresas/${empresa.id}/estagios/${estagio}`);
            setEstagio(undefined);

            onSuccess();
            notify('success', 'Estágio adicionado');
        } catch (error) {
            console.log(error);
            notify('danger', 'Não foi possível adicionar estágio');
        }
    }


    return (
        <>
            <NovoEstagio
                show={showNovoEstagio}
                empresa={empresa}
                notify={notify}
                onHidde={() => setShowNovoEstagio(false)}
                onSuccess={onAddedNovoEstagio}
            />
            <Modal
                className="modal-dialog-centered"
                size='md'
                isOpen={show}
                toggle={close}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Adicionar novo estágio
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={close}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NotificationAlert ref={notificationAlert} />
                    <>
                        <Row>

                            <Col lg={12} md={12} style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                flex: 1,
                                display: 'flex'
                            }}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Estágios
                                    </label>
                                    <InputGroup className="mb-3">
                                        <SelectContainer>
                                            <Select2
                                                maximumInputLength={10}
                                                className="form-control"
                                                style={{ width: '90%' }}
                                                data={estagiosPossiveis.map((item) => ({ id: item.id, text: item.nome }))}
                                                onSelect={(e) => setEstagio(e.target.value)}
                                                options={{
                                                    placeholder: 'Estágios...'
                                                }}
                                                value={estagio}
                                            />
                                        </SelectContainer>
                                        <InputGroupAddon addonType="append">
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={handleNovoEstagio}
                                            >
                                                +
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <div>
                                    <Button
                                        color="primary"
                                        type="button"
                                        outline
                                        onClick={handleAddColaborador}
                                        className="btn-icon btn-3"
                                    >
                                        <span className="btn-inner--icon">
                                            <i className="ni ni-fat-add"></i>
                                        </span>
                                        <span className="btn-inner--text">Adicionar</span>
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </>
                </div>
                <div className="modal-footer">
                    <Row>
                        <Col>
                            <div className="float-right ">
                                <Button
                                    className="ml-auto"
                                    color="link"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={close}
                                >
                                    Fechar
                          </Button>
                            </div>
                        </Col>
                    </Row>
                </div>

            </Modal>
        </>
    );
}
