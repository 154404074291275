import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../../../../services/api";
import { Empresa, SiteEmpresa } from "../../../../../entities/Common";
import List from "./List";
import Form from "./Form";
import SimpleHeader from "../../../../../components/Headers/SimpleHeader";
// @ts-ignore
import NotificationAlert from "react-notification-alert";

import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import EmpresaContext from "../../../../../contexts/Empresa";

const Sites: React.FC = () => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [sites, setSites] = useState(new Array<SiteEmpresa>());
  const [showForm, setShowForm] = useState(false);
  const notificationAlert = useRef<any>();

  useEffect(() => {
    load()
  }, [])

  async function load() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/sites-empresas`
      );
      setSites(await response.data);
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar os sites da empresa");
    }
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  function hideForm() {
    setShowForm(false);
  }

  function handleShowForm() {
    setShowForm(true);
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="Sites" parentName="Minha empresa" />
      <div className="container-fluid mt--6">
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <h1>Sites</h1>
              </CardHeader>
              <CardBody>
               
               { empresaSelecionada &&<Form
                  empresa={empresaSelecionada}
                  hide={hideForm}
                  notify={notify}
                  success={load}
                  show={showForm}
                  site={{} as SiteEmpresa}
                />}
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    color="primary"
                    type="button"
                    className="btn-icon btn-3"
                    onClick={handleShowForm}
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add"></i>
                    </span>
                    <span className="btn-inner--text">Novo Site</span>
                  </Button>
                </div>
                
                 { empresaSelecionada && <List
                  sites={sites}
                  empresa={empresaSelecionada}
                  notify={notify}
                  reLoad={load}
                />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Sites;
