import React, { useState, useEffect } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import Table from "../../../components/Table";
import { createColumns } from './Columns'
import ParcialColaboradores from './chart/ParcialColaboradores'

import {
    Spinner,
    Button,
    UncontrolledTooltip,
    Modal,
    Row,
    Col
} from "reactstrap";

import "./styles.css"

export default ({ itens, pageProperties, notify, lastSearch, ...props }) => {

    const [funcionarioMovimentacao, setFuncionarioMovimentacao] = useState(null)
    const [showModalMovimentacao, setShowModalMovimentacao] = useState(false)

    function handleCloseModalMovimentacao() {
        setShowModalMovimentacao(false)
        setFuncionarioMovimentacao(null)
    }

    return (
        <>
            {/*Modal Movimentacao*/}
            <Modal
                size="lg"
                className="modal-dialog-centered"
                isOpen={showModalMovimentacao}
                toggle={() => handleCloseModalMovimentacao()}
            >
                <div className="modal-header" style={{ paddingBottom: 0 }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                        Movimentação do Funil
                        <br />
                        <small className="text-muted"
                            title={`Id e Nome do Funcionário`}>
                            {funcionarioMovimentacao ? `#${funcionarioMovimentacao.colaborador_id} | ${funcionarioMovimentacao.colaborador_nome}` : ""}
                        </small>
                    </h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={handleCloseModalMovimentacao}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" style={{ paddingTop: 5 }}>
                    <Row className='mt-4'>
                        <Col lg='12' md='12' sm='12' xs='12'>
                            <ParcialColaboradores
                                notify={notify}
                                mes={1}
                                ano={2022}
                                equipe={null}
                                colaborador={null}
                                unidade={null}
                                periodo={null}
                                tipoProduto={null}
                                lastSearch={lastSearch}
                                funcionarioMovimentacao={funcionarioMovimentacao}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>

            {
                itens && pageProperties ?
                    <Table
                        columns={createColumns({
                            lastSearch: lastSearch,
                            setFuncionarioMovimentacao: setFuncionarioMovimentacao,
                            setShowModalMovimentacao: setShowModalMovimentacao
                        })}
                        data={itens}
                        pageProperties={pageProperties}
                        onTableChange={props.onTableChange}                        
                    />
                    :
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center'
                    }}>
                        <Spinner
                            color='primary'
                        />
                    </div>
            }
        </>
    );
}
