import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import Table from "../../../../../components/Table";
import api from "../../../../../services/api";
import EmpresaContext from "../../../../../contexts/Empresa";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { AxiosResponse } from "axios";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
import { SelectableButton } from "./styles";
import { string } from "prop-types";
import Avatar from "../../../../../components/Avatar";
import { KpisContext } from "../../../../../contexts/Kpis";

// import { Container } from './styles';

interface IGraficos {
  mes: number;
  ano: number;
  canais: any;
}
interface ISumario {
  mediaoportunidadepordia: number | null;
  oportunidadesperiodo: number | null;
}

const TabelaPerformanceColaboradorCanaisPeriodo: React.FC<IGraficos> = ({
  mes,
  ano,
  canais,
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [kpis, setKpis] = useState([]);
  const [sumarioPerformanceCampanha, setSumarioPerformanceCampanha] =
    useState<ISumario>();
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastSearch, setLastSearch] = useState({});
  const [time, setTime] = useState(0);
  const { requestAvaliable, handlerequestAvaliable } = useContext(KpisContext);
  const [currentOwnerLead, setCurrentOwnerLead] =
    useState("colaboradorCriador");
  const [pageProperties, setPageProperties] = useState({
    total: "0",
    perPage: 10,
    page: 1,
    lastPage: 1,
    loading: isLoading,
  });

  useEffect(() => {
    setColumns([
      {
        dataField: "name",
        text: "Colaborador",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) => colaboradorFormatter(row),
      },
      {
        dataField: "totaloportunidades",
        text: "Total",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) =>
          divFormatter(row.totaloportunidades),
        /*  */
      },
      {
        dataField: "mediadia",
        text: "Média",
        align: "center",
        headerAlign: "center",
        formatter: (column: any, row: any) =>
          divFormatterNumber(row.mediadia, column),
      },
      {
        dataField: "oportunidadesdia",
        text: "Dia",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) => divFormatter(row.oportunidadesdia),
      },
      {
        dataField: "oportunidadesdiamenos1",
        text: "oportunidadesdiamenos1",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 1));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos1),
      },
      {
        dataField: "oportunidadesdiamenos2",
        text: "oportunidadesdiamenos2",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 2));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos2),
      },
      {
        dataField: "oportunidadesdiamenos3",
        text: "oportunidadesdiamenos3",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 3));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos3),
      },
      {
        dataField: "oportunidadesdiamenos4",
        text: "oportunidadesdiamenos4",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 4));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos4),
      },
      {
        dataField: "oportunidadesdiamenos5",
        text: "oportunidadesdiamenos5",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 5));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos5),
      },
      {
        dataField: "oportunidadesdiamenos6",
        text: "oportunidadesdiamenos6",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 6));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos6),
      },
      {
        dataField: "oportunidadesdiamenos7",
        text: "oportunidadesdiamenos7",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 7));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos7),
      },
      {
        dataField: "oportunidadesdiamenos8",
        text: "oportunidadesdiamenos8",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 8));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos8),
      },
      {
        dataField: "oportunidadesdiamenos9",
        text: "oportunidadesdiamenos9",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 9));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos9),
      },
    ] as any);

    if (canais) {
      tempo();
    }
  }, [mes, ano, currentOwnerLead, canais]);

  function colaboradorFormatter(row: any) {
    let colaboradorAvatar = (
      <div style={{ height: 48 }}>
        {row && (
          <Avatar
            title={row.name}
            user={{ avatar: row.avatar, name: row.name }}
            className="avatar"
            style={{
              cursor: "default",
            }}
          />
        )}
      </div>
    );
    return colaboradorAvatar;
  }

  const formatNumDataWithZero = (num: number) => {
    return num < 10 ? `0${num}` : num;
  };

  const formatWeekDay = (data: Date) => {
    const diaDaSemana = [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ];
    return diaDaSemana[data.getDay()];
  };

  function divFormatter(row: any) {
    let divFormatter = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "48px",
        }}
      >
        {row}
      </div>
    );
    return divFormatter;
  }
  function divFormatterNumber(row: any, column: any) {
    if (typeof column == "number") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "48px",
          }}
        >
          {column.toFixed(2)}
        </div>
      );
    }
    let divFormatter = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "48px",
        }}
      >
        {column}
      </div>
    );
    return divFormatter;
  }

  async function handleTableChange(
    type: string,
    sortProperties: {
      page: number;
      sizePerPage: number;
      sortField: string;
      sortOrder: string;
    }
  ): Promise<void> {
    try {
      await loadData(
        sortProperties.page == 0 ? 1 : sortProperties.page,
        sortProperties.sizePerPage,
        sortProperties.sortField,
        sortProperties.sortOrder
      );
    } catch (error) {
      notify("danger", "Houve um problema ao carregar as kpis.");
    }
  }

  const handleCheck = (value: string) => setCurrentOwnerLead(value);

  async function tempo() {
    clearTimeout(time);
    setTime(
      setTimeout(async (e) => {
        loadData();
        loadDataSumario();
      }, 3000)
    );
  }

  async function loadData(
    page = 1,
    limit = 10,
    sortField = "tema",
    sortOrder = "asc",
    filters = lastSearch
  ) {
    handlerequestAvaliable(false);
    setIsLoading(true);
    try {
      setPageProperties({
        ...pageProperties,
      });
      if (isLoading) {
        console.log("essa porra ainda tá rodando");
        return;
      }

      const dataInicio = moment([ano, mes - 1, 1]).format("YYYY-MM-DD");
      let dataFim = moment([ano, mes - 1, 1])
        .endOf("month")
        .format("YYYY-MM-DD");
      if (new Date(dataFim) > new Date()) {
        // @ts-ignore
        dataFim = new Date();
      }
      let urlRota = "";

      if (currentOwnerLead == "colaboradorCriador") {
        urlRota = "kpis-periodo-performance-campanha-colaborador-criador";
      } else {
        urlRota = "kpis-periodo-performance-campanha-colaborador";
      }

      const response = await api.get(
        `/dashboards/empresas/${empresaSelecionada?.id}/${urlRota}/`,
        {
          params: {
            data_inicio: dataInicio,
            data_fim: dataFim,
            canal_id: canais,
          },
        }
      );
      setKpis(response.data);
      await updatePageProperties(response.data);
    } catch (err) {
      console.error(err);
      notify("danger", "Houve um problema ao carregar lista de Campanhas");
    } finally {
      setIsLoading(false);
      handlerequestAvaliable(true);
    }
  }

  async function loadDataSumario() {
    setIsLoading(true);
    handlerequestAvaliable(false);
    try {
      const dataInicio = moment([ano, mes - 1, 1]).format("YYYY-MM-DD");
      let dataFim = moment([ano, mes - 1, 1])
        .endOf("month")
        .format("YYYY-MM-DD");
      if (new Date(dataFim) > new Date()) {
        // @ts-ignore
        dataFim = new Date();
      }

      const response = await api.get(
        `/dashboards/empresas/${empresaSelecionada?.id}/kpis-sumario-performance-campanha/`,
        {
          params: {
            data_inicio: dataInicio,
            data_fim: dataFim,
            canal_id: canais,
          },
        }
      );
      setSumarioPerformanceCampanha(response.data[0]);
    } catch (err) {
      console.error(err);
      notify("danger", "Houve um problema ao carregar lista de Campanhas");
    } finally {
      setIsLoading(false);
      handlerequestAvaliable(true);
    }
  }

  async function updatePageProperties(response: AxiosResponse) {
    const { total, perPage, page, lastPage } = await response.data;
    setPageProperties({
      total,
      perPage,
      page,
      lastPage,
      loading: isLoading,
    });
  }

  function notify(type: any, msg: any) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    // if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        {/* <NotificationAlert ref={notificationAlert} /> */}
      </div>
      <Card body>
        <div></div>
        <div>
          <span>
            <Card style={{ minHeight: "365px" }}>
              <div>
                <CardHeader>
                  <h5 className="h3 mb-0">
                    Performance dos Colaboradores nos Canais
                  </h5>
                  <small>
                    Visão geral da performance dos colaboradores na execução de
                    canais no período.
                  </small>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <h6 className="surtitle">Kpis</h6> */}
                  </div>
                </CardHeader>
              </div>
              <CardBody>
                <div className="row pt-3">
                  <div className="col-5 m-auto">
                    <Card
                      style={{
                        backgroundColor: "#eaedf3",
                      }}
                    >
                      <CardTitle
                        className="p-3 mb-0"
                        style={{
                          backgroundColor: "#12ccf0",
                          borderRadius: "0.375rem 0.375rem 0px 0px",
                        }}
                      >
                        <h4
                          className="text-uppercase mb-0 font-weight-normal"
                          style={{
                            color: "#000000",
                          }}
                        >
                          Registradas no período
                        </h4>
                      </CardTitle>
                      <div className="p-3 pt-0">
                        <div>
                          <h1>
                            {sumarioPerformanceCampanha?.oportunidadesperiodo}
                          </h1>
                          <h2>
                            Média/Dia ={" "}
                            {
                              sumarioPerformanceCampanha?.mediaoportunidadepordia
                            }
                          </h2>
                        </div>
                        <div
                          className="icon icon-shape bg-white text-white rounded-circle shadow"
                          style={{
                            position: "absolute",
                            right: "48px",
                            top: "50%",
                          }}
                        >
                          <i
                            className="fas fa-bullhorn"
                            style={{
                              fontSize: "1.5rem",
                              color: "#12ccf0",
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div>
                  <FormGroup
                    tag="fieldset"
                    style={{
                      display: "flex",
                      marginBottom: "0px",
                    }}
                  >
                    <FormGroup
                      check
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      <Input
                        onChange={(e: any) => handleCheck(e.target.value)}
                        checked={currentOwnerLead === "colaboradorCriador"}
                        name="radio1"
                        value={"colaboradorCriador"}
                        type="radio"
                      />{" "}
                      <Label
                        check
                        style={{
                          fontSize: "0.9em",
                          fontWeight: 600,
                        }}
                      >
                        Criadas pelo colaborador
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        onChange={(e: any) => handleCheck(e.target.value)}
                        checked={currentOwnerLead === "colaboradorPegador"}
                        name="radio1"
                        value={"colaboradorPegador"}
                        type="radio"
                      />{" "}
                      <Label
                        check
                        style={{
                          fontSize: "0.9em",
                          fontWeight: 600,
                        }}
                      >
                        Estão com o colaborador
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </div>
                <div className="">
                  {isLoading ? (
                    <Container
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "300px",
                      }}
                    >
                      <Spinner color="primary" size=""></Spinner>
                    </Container>
                  ) : (
                    <Table
                      columns={
                        columns.length
                          ? columns
                          : [
                              {
                                dataField: "name",
                                text: "Nome",
                                align: "center",
                                headerAlign: "center",
                              },
                            ]
                      }
                      data={kpis}
                      noDataIndication="Nenhum item encontrado"
                      pageProperties={pageProperties}
                      onTableChange={handleTableChange}
                      notify={undefined}
                      rowEvents={undefined}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </span>
        </div>
      </Card>
    </>
  );
};
export default TabelaPerformanceColaboradorCanaisPeriodo;
