import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../contexts/Empresa'
import moment from 'moment'
import api from "../../../../services/api";
import { usePersistedState } from '../../../../hooks'
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";

import {
    Form,
    FormGroup,
    Modal,
    InputGroup,
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
} from "reactstrap";

function ExpantedFilters({
    notify,
    isGestor,
    colaborador,
    equipe,
    onColaboradorChanged,
    onEquipeChanged,
    show,
    hidde,
    unidade,
    onUnidadeChanged,
    tipoProduto,
    onTipoProdutoChanged,
    mes,
    ano
}) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [colaboradores, setColaboradores] = useState([])
    const [equipes, setEquipes] = useState([])
    const [unidades, setUnidades] = useState([])
    const [tiposProduto, setTiposProduto] = useState([])

    useEffect(() => {
        if (tiposProduto.length === 0) loadTiposProdutos()
        if (unidades.length === 0) loadUnidades()
        if (equipes.length === 0) loadEquipes()
    }, [])

    useEffect(() => {
        colaboradores.unshift({ id: -1, name: '- todos -' })
    }, [colaboradores])

    useEffect(() => {
        onColaboradorChanged(null)
        loadColaboradores(equipe)
    }, [equipe])



    useEffect(() => {
        loadUnidades()
    }, [mes, ano, tipoProduto])

    async function loadColaboradores(equipeId) {
        setColaboradores([])
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/colaboradores`, {
                params: {
                    equipes: equipeId && equipeId > 0 ? [equipeId] : [-1]
                }
            })

            setColaboradores(await response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar colaboradores')
        }
    }

    function limparFiltros() {
        onEquipeChanged(null)
        onColaboradorChanged(null)
        hidde()
    }

    async function loadEquipes() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada.id}/equipes`)
            const data = await response.data
            data.unshift({ nome: '- todos -', id: -1 })
            setEquipes(data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar equipes')
        }
    }

    async function loadUnidades() {
        if (mes && mes > 0 && ano && ano > 0) {
            try {
                const response = await api.get(`/dashboards/empresas/${empresaSelecionada.id}/unidade-metas-periodo`, {
                    params: {
                        mes,
                        ano,
                        tipoProduto
                    }
                })
                console.log(response.data)
                setUnidades(await response.data)

                if (response && response.data && response.data.length > 0) {
                    onUnidadeChanged(response.data[0].id)
                }

            } catch (error) {
                console.error(error)
                notify('danger', 'Não foi possível carregar Unidades')
            }
        } else {
            notify('danger', 'Não foi possível carregar Unidades: Mês e/ou Ano não definido')
        }
    }

    async function loadTiposProdutos() {
        if (mes && mes > 0 && ano && ano > 0) {
            try {
                const response = await api.get(`/dashboards/empresas/${empresaSelecionada.id}/tipo-produto-metas-periodo`, {
                    params: {
                        mes,
                        ano
                    }
                })

                const data = await response.data
                
                if (data.length > 1) {
                    data.unshift({ nome: '- todos -', id: -1 })
                }

                console.log(data)
                
                setTiposProduto(data)

                //if (response && response.data && response.data.length > 0) {
                if (data.length > 0) {
                    onTipoProdutoChanged(data[0].id)
                }

            } catch (error) {
                console.error(error)
                notify('danger', 'Não foi possível carregar Tipos Produto')
            }
        } else {
            notify('danger', 'Não foi possível carregar Tipos Produto: Mês e/ou Ano não definido')
        }
    }

    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={show}
                toggle={hidde}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Filtros
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={hidde}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="py-4">
                        <Col xs="12" lg="12" sm="12" md="12">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-number-input"
                                >
                                    Tipo Produto
                                </label>
                                <InputGroup className="input-group-alternative">
                                    <Select2
                                        size='sm'
                                        onSelect={(e) => onTipoProdutoChanged(e.target.value)}
                                        options={{
                                            placeholder: "Selecione um Tipo de Produto..."
                                        }}
                                        value={tipoProduto}
                                        data={tiposProduto.map((item) => ({ id: item.id, text: item.nome }))}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs="12" lg="12" sm="12" md="12">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-number-input"
                                >
                                    Unidade
                                </label>
                                <InputGroup className="input-group-alternative">
                                    <Select2
                                        size='sm'
                                        defaultValue="Q"
                                        onSelect={(e) => onUnidadeChanged(e.target.value)}
                                        options={{
                                            placeholder: "Selecione uma unidade..."
                                        }}
                                        value={unidade}
                                        data={unidades.map((item) => ({ id: item.id, text: item.nome }))}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        {isGestor &&
                            <>
                                <Col xs="12" lg="12" sm="12" md="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example-number-input"
                                        >
                                            Equipe
                                        </label>
                                        <InputGroup className="input-group-alternative">
                                            <Select2
                                                size='sm'
                                                defaultValue="-1"
                                                onSelect={(e) => onEquipeChanged(e.target.value)}
                                                options={{
                                                    placeholder: "Selecione uma equipe..."
                                                }}
                                                value={equipe}
                                                data={equipes.map((item) => ({ id: item.id, text: item.nome }))}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>

                                <Col xs="12" lg="12" sm="12" md="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example-number-input"
                                        >
                                            Colaborador
                                        </label>
                                        <InputGroup className="input-group-alternative">
                                            <Select2
                                                defaultValue="-1"
                                                onSelect={(e) => onColaboradorChanged(e.target.value)}
                                                options={{
                                                    placeholder: "Selecione um colaborador..."
                                                }}
                                                value={colaborador}
                                                data={
                                                    colaboradores.map((item) => ({ id: item.id, text: item.name }))
                                                }
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </>
                        }

                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        //className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={hidde}
                    >
                        Fechar
                    </Button>

                    <Button
                        //className="ml-auto"
                        color="primary"
                        data-dismiss="modal"
                        type="button"
                        onClick={limparFiltros}
                    >
                        Limpar
                    </Button>
                </div>

            </Modal>
        </>
    )
}

export default ExpantedFilters;