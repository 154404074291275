import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import api from "../../../../services/api";
import Table from "../../../../components/Table";
import EmpresaContext from "../../../../contexts/Empresa";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Filters from "./Filters";
import { MenuComportamento } from "../../../../components/Menus";
import { usePersistedState } from "../../../../hooks";

// import { Container } from './styles';

interface Props {
  isOpen: boolean;
  colaborador?: any;
  hideModal(): void;
  history?: any;
  clienteIndicadorId?: number | undefined;
  cadastroInicial?: Date | undefined;
  cadastroFinal?: Date | undefined;
}

const ListaIndicacoes: React.FC<Props> = ({
  isOpen,
  colaborador,
  hideModal,
  clienteIndicadorId,
}) => {
  const [dataCadastroFinal, setDataCadastroFinal] = useState<any>(moment());
  const [dataCadastroInicial, setDataCadastroInicial] = useState<any>(
    moment().set("date", new Date().getDate() - 10)
  );

  const [columns, setColumns] = useState([]);
  const history = useHistory();
  const [oportunidadesColaborador, setOportunidadesColaborador] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = usePersistedState("page", 1);
  const [currentPerPage, setCurrentPerPage] = usePersistedState("perPage", 10);
  const [pageProperties, setPageProperties] = useState({
    total: "0",
    perPage: currentPerPage,
    page: currentPage,
    lastPage: 1,
    loading: false,
    sort: new Map([]),
  });

  useEffect(() => {
    getOportunidades();
    setColumns([
      {
        dataField: "acoes",
        formatter: (cell: any, row: any) => acoesFormatter(row),
      },
      {
        dataField: "created_at",
        text: "Data de Cadastro",
        align: "left",
        formatter: (row: any, column: any) => dataCadastro(row, column),
        csvFormatter: (cell: any, row: any) => dataCadastroCsv(cell, row),
        csvType: Date,
        headerAlign: "left",
      },
      {
        dataField: "cliente.nome",
        text: "Nome",
        align: "left",
        headerAlign: "left",
      },
      {
        dataField: "cliente.telefones",
        text: "Telefone",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) => {
          return `(${row.cliente.telefones[0].ddd}) ${row.cliente.telefones[0].numero}`;
        },
      },
      {
        dataField: "indicador.nome",
        text: "Cliente Indicador",
        align: "left",
        headerAlign: "center",
      },
      {
        dataField: "descricao",
        text: "Descricao",
        align: "left",
        headerAlign: "center",
      },
    ] as any);
  }, [colaborador]);

  const acoesFormatter = (row: any) => {
    return (
      <MenuComportamento
        actions={[
          {
            label: "Alterar",
            icon: "far fa-edit",
            onClick: () => rowEvents,
          },
        ]}
      />
    );
  };

  async function updatePageProperties(response: any) {
    const { total, perPage, page, lastPage } = await response;
    await setPageProperties((e) => ({ ...e, total, perPage, page, lastPage }));
  }

  const dataCadastro = (cell: any, row: any) => {
    if (!row.created_at) {
      return;
    }
    const dataEncerramento = moment(row.created_at);
    return dataEncerramento.format("D MMM YYYY HH:mm");
  };
  const dataCadastroCsv = (cell: any, row: any) => {
    if (!row.created_at) {
      return "";
    }
    const dataAgendamento = moment(row.created_at);
    return dataAgendamento.format("D/MM/YYYY HH:mm");
  };

  async function getOportunidades(page = 1, limit = 22) {
    setCurrentPage(page);
    setCurrentPerPage(limit);
    try {
      setPageProperties({ ...pageProperties });
      setIsLoading(true);
      const { data } = await api.get(
        `/marketing/oportunidades/clientes/indicador`,
        {
          params: {
            cliente_indicador_id: clienteIndicadorId,
            anuncio_id: colaborador,
            dataCadastroInicial,
            dataCadastroFinal,
            page,
            limit,
          },
        }
      );
      setOportunidadesColaborador(data.data);
      await updatePageProperties(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function reloadOportunidades() {
    console.log("RELOAD OPORTUNIDADES");
    getOportunidades(currentPage, currentPerPage);
  }

  const rowEvents = {
    onClick: (e: any, row: any) => {
      hideModal();
      history.push(`/admin/oportunidades/${row.id}/edit`);
    },
  };

  const handleTableChange = async (
    type: string,
    { page, sizePerPage, sortField, sortOrder }: any
  ) => {
    try {
      if (type == "sort") {
        sortField = sortField.replace("__meta__.", "");
        const value = pageProperties.sort.get(sortField);
        const newOrder = (value || "asc") == "desc" ? "asc" : "desc";
        const map = pageProperties.sort;
        map.set(sortField, newOrder);
        sortOrder = newOrder;
        // setCurrentSortField(sortField);
        // setCurrentSortOrder(sortOrder);
      } else if (type == "pagination") {
        // sortField = currentSortField;
        // sortOrder = currentSortOrder;
      }
      // if (cancelApi) {
      //   try {
      //     cancelApi.cancel("Request canceled");
      //   } catch (error) {}
      // }
      await getOportunidades(page == 0 ? 1 : page, sizePerPage);
    } catch (error) {
      // notify("danger", "Houve um problema ao carregar as Oportunidades.");
    }
  };
  return (
    <>
      <Modal
        centered
        size="lg"
        toggle={function noRefCheck() {}}
        isOpen={isOpen}
        className="p-0"
      >
        <ModalBody className="p-0">
          <Container className="p-0">
            <Card className="m-0">
              <CardHeader>
                <h2>Lista de indicações</h2>
                <Filters
                  dataCadastroFinal={dataCadastroFinal}
                  dataCadastroInicial={dataCadastroInicial}
                  setDataCadastroInicial={setDataCadastroInicial}
                  setDataCadastroFinal={setDataCadastroFinal}
                  onSearch={() => getOportunidades()}
                  isLoading={isLoading}
                />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12" md="12" lg="12">
                    {isLoading ? (
                      <Container
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "300px",
                        }}
                      >
                        <Spinner color="primary" size=""></Spinner>
                      </Container>
                    ) : (
                      <Table
                        columns={
                          columns.length
                            ? columns
                            : [
                                {
                                  dataField: "name",
                                  text: "Nome",
                                  align: "left",
                                  headerAlign: "center",
                                },
                              ]
                        }
                        data={oportunidadesColaborador}
                        pageProperties={pageProperties}
                        // noDataIndication="Nenhum item encontrado"
                        onTableChange={handleTableChange}
                        notify={undefined}
                        rowEvents={rowEvents}
                        // reloadOportunidades={reloadOportunidades}
                      />
                    )}
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <div className="float-right">
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={hideModal}
                  >
                    Voltar
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ListaIndicacoes;
