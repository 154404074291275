import React, { useState, useEffect, useContext } from "react";
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
// reactstrap components
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Form from "./Form";
import {
  Button,
  Modal,
  ListGroupItem,
  ListGroup,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

export default ({
  oportunidadeId,
  oportunidadeIdToCrossSelling,
  readOnly,
  oportunidade = {},
  notify,
  onProtudosChange,
  ...props
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [produtos, setProdutos] = useState([]);

  const [produto, setProduto] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (oportunidadeId) loadProdutos(oportunidadeId);
  }, [oportunidadeId]);

  useEffect(() => {
    if (oportunidadeIdToCrossSelling && oportunidadeIdToCrossSelling > 0) {
      loadProdutosDeclinadosToCrossSelling(oportunidadeIdToCrossSelling);
    }
  }, [oportunidadeIdToCrossSelling]);

  async function loadProdutos(oportunidadeId) {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/produtos`
      );
      setProdutos(response.data);
    } catch (error) {
      notify("danger", "Não foi possivel carregar produtos");
    }
  }

  async function loadProdutosDeclinadosToCrossSelling(oportunidadeId) {
    //console.log('ENTROU NO LOAD PRODUTOS PARA CROSS SELLING')
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/produtos_declinados`
      );
      var prodDeclidados = response.data;
      if (prodDeclidados) {
        for (let prod of prodDeclidados) {
          prod.created_at = null;
          prod.data_declinacao = null;
          prod.id = null;
          prod.oportunidade_id = null;
          prod.updated_at = null;
        }

        setProdutos(prodDeclidados);
        if (onProtudosChange) onProtudosChange(prodDeclidados);
      }
    } catch (error) {
      notify("danger", "Não foi possivel carregar produtos");
    }
  }

  function handleEditProduto(produto) {
    if (!oportunidadeId || readOnly) return;
    setProduto(produto);
    setShowModal(true);
  }

  function removeLocal(id) {
    const array = produtos.filter((item) => item.produto_id != id);
    setProdutos(array);
  }

  async function remove(id) {
    if (window.confirm("Deseja remover o produto?")) {
      try {
        if (!oportunidadeId) {
          //Se não estiver salvo, remove localmente
          removeLocal(id);
          return;
        }
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status == 409) {
          notify("danger", error.response.data.message);
        } else {
          notify("danger", "Não foi possível remover produto");
        }
      }
    }
  }

  function handleSave(produto) {
    notify("success", "Produto salvo");
    //Caso exita oportunidade, carrega a lista d eprodutos
    if (oportunidadeId) loadProdutos(oportunidadeId);

    if (!oportunidadeId && produto) {
      //Caso nao exista oportunidade, seta o produto na lista d eprodutos locais
      const copy = produtos;
      copy.push(produto);
      setProdutos(copy);
      if (onProtudosChange) onProtudosChange(copy);
    }
    setShowModal(false);
    setProduto({});
  }

  async function handleAcaoDeclinar(produto) {
    if (readOnly) return;
    const toDeclinar = !produto.data_declinacao;
    if (
      window.confirm(
        toDeclinar
          ? "Deseja declinar este produto?"
          : "Deseja remover a declinação deste produto?"
      )
    ) {
      try {
        if (toDeclinar) {
          await api.post(
            `/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/produtos/${produto.id}/declinacoes`
          );
        } else {
          await api.delete(
            `/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/produtos/${produto.id}/declinacoes`
          );
        }
        notify(
          "success",
          toDeclinar ? "Produto Declinado" : "Produto Restaurado"
        );
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status == 409) {
          notify("danger", error.response.data.message);
        } else {
          notify("danger", "Não foi possível realizar operação");
        }
      }
      loadProdutos(oportunidadeId);
    }
  }

  function handleNovoProduto() {
    if (readOnly) return;
    setProduto({});
    setShowModal(true);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Button
          disabled={readOnly}
          hidden={oportunidade.data_encerramento}
          color="primary"
          type="button"
          className="btn-icon btn-3"
          size="sm"
          onClick={handleNovoProduto}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-fat-add"></i>
          </span>
          <span className="btn-inner--text">Novo</span>
        </Button>
        <Modal
          className="modal-dialog-centered"
          isOpen={showModal}
          toggle={() => setShowModal(!showModal)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Adicionar Produto
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowModal(!showModal)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              oportunidadeProduto={produto}
              notify={notify}
              oportunidadeId={oportunidadeId}
              onSuccess={handleSave}
              produtosAdicionados={produtos.map((item) => item.produto)}
              close={() => setShowModal(false)}
            />
          </div>
        </Modal>
      </div>
      <ListGroup className="list mt-4" flush>
        {produtos.map((produto, key) => (
          <ListGroupItem
            className="list-group-item-action px-0"
            style={{
              flexDirection: "row",
              display: "flex",
              flex: 1,
              alignItems: "center",
            }}
            key={key}
          >
            <div className="col-auto">
              <span
                id={`declinacao-${key}`}
                className={`${
                  produto.data_declinacao ? "text-danger" : "text-success"
                } mr-2`}
              >
                ●
              </span>
              <UncontrolledTooltip
                delay={0}
                placement="top"
                target={`declinacao-${key}`}
              >
                {`${
                  produto.data_declinacao
                    ? "Produto Declinado"
                    : "Produto Ativo"
                } `}
              </UncontrolledTooltip>
            </div>
            <Row
              style={{ flex: 1 }}
              onClick={(e) => {
                e.preventDefault();
                handleEditProduto(produto);
              }}
              className="align-items-center"
            >
              <div className="col">
                <h4 className="mb-0">
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    {produto.produto.nome}
                  </a>
                </h4>
                <small>Quantidade: {produto.quantidade}</small>
                <br />
                <small>
                  Preço unitário:{" "}
                  {Number(produto.preco).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </small>
                <br />
                <small>
                  Total:{" "}
                  {parseFloat(
                    produto.preco * produto.quantidade
                  ).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </small>
              </div>
            </Row>
            {!oportunidade.data_encerramento && (
              <>
                {oportunidadeId ? (
                  <Button
                    disabled={readOnly}
                    color={"link"}
                    size="sm"
                    onClick={() => handleAcaoDeclinar(produto)}
                  >
                    {produto.data_declinacao ? "Restaurar" : "Declinar"}
                  </Button>
                ) : (
                  <Button
                    disabled={readOnly}
                    className="btn-sm"
                    color="danger"
                    onClick={() => remove(produto.produto_id)}
                  >
                    <i class="fas fa-trash"></i>
                  </Button>
                )}
              </>
            )}
          </ListGroupItem>
        ))}
      </ListGroup>
    </>
  );
};
