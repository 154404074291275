import React, { useState, useEffect } from "react";

import Select2 from "react-select2-wrapper";
import Filters from "../../../components/Headers/Filters";

import { Row, Col, Input, FormGroup, InputGroup } from "reactstrap";
import { usePersistedState } from "../../../hooks";
import InputMask from "react-input-mask";

export default ({ title, notify, load, ...props }) => {
  const [nome, setNome] = useState(null);
  const [cpfCnpj, setCpfCnpj] = useState(null);
  const [tipoPessoa, setTipoPessoa] = useState(["F"]);
  const [sexo, setSexo] = useState(null);
  const [cliente, setCliente] = usePersistedState("cliente", "");
  const [pesquisarTelefoneCliente, setPesquisarTelefoneCliente] =
    usePersistedState("pesquisarTelefoneCliente", false);
  const [monitorClearFilters, setMonitorClearFilters] = useState(undefined);

  const [tiposPessoas] = useState([
    { id: "F", text: "Pessoa Física" },
    { id: "J", text: "Pessoa Jurídica" },
  ]);
  const [sexos] = useState([
    { id: "F", text: "Feminino" },
    { id: "M", text: "Masculino" },
    { id: "O", text: "Outro" },
  ]);

  //Flag para definir tempo de execução
  const [runLoad, setRunLoad] = useState(props.search ? props.search : true);

  useEffect(() => {
    if (runLoad) {
      search();
      setRunLoad(false);
    }
  }, [runLoad]);

  useEffect(() => {
    if (monitorClearFilters) {
      search();
    }
  }, [monitorClearFilters]);

  function throwError(text) {
    if (notify) notify("danger", text);
  }

  const searchBy = () => {
    const by = {};
    if (pesquisarTelefoneCliente) {
      by.telefone = {
        ddd: cliente.slice(0, 2),
        numero: cliente.slice(2, cliente.lenght).replace(/[^0-9]/g, ""),
      };
    } else {
      by.nome = cliente;
    }
    return by;
  };

  function search() {
    load({
      ...searchBy(),
      pesquisarTelefoneCliente,
      tipoPessoa,
      sexo,
      cpfCnpj,
    });
  }

  function handleMultipleSelect(target, state) {
    const array = Array.from(target.selectedOptions);
    state(array.map((item) => item.value));
  }

  async function handleClearFilter() {
    setCliente("");
    setPesquisarTelefoneCliente(false);
    setSexo(null);
  }

  return (
    <>
      <Filters
        onSearch={search}
        title={<h1>Clientes</h1>}
        clearFilters={handleClearFilter}
        onFiltersClead={setMonitorClearFilters}
      >
        <Row className="py-4">
          <Col xs="12" lg="6" sm="6" md="12">
            <FormGroup>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <label className="form-control-label">Cliente</label>
                <div className="custom-control custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="check-telefone"
                    type="checkbox"
                    checked={pesquisarTelefoneCliente}
                    onChange={() =>
                      setPesquisarTelefoneCliente(!pesquisarTelefoneCliente)
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="check-telefone"
                  >
                    Pesquisar por telefone
                  </label>
                </div>
              </div>
              <InputMask
                placeholder={
                  pesquisarTelefoneCliente
                    ? "Telefone do cliente..."
                    : "Nome do cliente..."
                }
                className="form-control"
                maskPlaceholder={null}
                mask={pesquisarTelefoneCliente ? "99 99999-9999" : undefined}
                value={cliente}
                onChange={(e) => setCliente(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example-number-input"
              >
                Tipo da pessoa
              </label>
              <InputGroup className="input-group-alternative">
                <Select2
                  multiple
                  onSelect={(e) =>
                    handleMultipleSelect(e.target, setTipoPessoa)
                  }
                  onUnselect={(e) =>
                    handleMultipleSelect(e.target, setTipoPessoa)
                  }
                  options={{
                    placeholder: "Selecione tipos de pessoas...",
                  }}
                  value={tipoPessoa}
                  data={tiposPessoas}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example-number-input"
              >
                Sexo
              </label>
              <InputGroup className="input-group-alternative">
                <Select2
                  multiple
                  onSelect={(e) => handleMultipleSelect(e.target, setSexo)}
                  onUnselect={(e) => handleMultipleSelect(e.target, setSexo)}
                  options={{
                    placeholder: "Selecione sexo...",
                  }}
                  value={sexo}
                  data={sexos}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </Filters>
    </>
  );
};
