import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
import { usePersistedState } from '../../../../../hooks'

import { SpinnerContainer } from '../styles';
import { Funnel } from '@ant-design/charts';
import Select2 from "react-select2-wrapper";
import {
    Spinner,
    Card,
    CardBody,
    CardHeader,
    Input,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

function FunilVendas({ notify, mes, ano, equipe, colaborador, campanha, funil, setFunil, cardHeight  }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState({})
    
    const [funisList, setFunisList] = useState([])

    useEffect(() => {
        load()
    }, [mes, ano, equipe, colaborador, campanha, funil, empresaSelecionada])

    useEffect(() => {
        createChartData()
    }, [itens])

    useEffect(() => {
        (async () => {
            if (funisList.length === 0) await loadFunis()
        })()
    }, [])

    async function loadFunis() {
        const response = await api.get(`common/empresas/${empresaSelecionada?.id}/funis`)
        if (response.data) {
            response.data.unshift({ id: -1, nome: '- Todos -' })
            setFunisList(response.data)
        }
    }


    async function load() {
        setLoading(true)
        try {
            const response = await api.get(`/dashboards/empresas/${empresaSelecionada.id}/funis`, {
                params: {
                    mes,
                    ano,
                    equipe,
                    colaborador,
                    campanha_id: campanha,
                    funil_id: funil
                }
            })
            setItens(await response.data)
        } catch (error) {
            console.error(error)
            notify('Não foi possível carregar grafico de campanhas')
        }
        setLoading(false)
    }

    function findColor(textColor) {
        if (textColor === 'primary') {
            return '#5E72E4'
        } else if (textColor === 'info') {
            return '#11CDEF'
        } else if (textColor === 'danger') {
            return '#DE2E2E'
        } else if (textColor === 'default') {
            return '#172b4d'
        } else if (textColor === 'secondary') {
            return '#f4f5f7'
        } else if (textColor === 'success') {
            return '#2dce89'
        } else if (textColor === 'warning') {
            return '#fb6340'
        } else {
            return textColor
        }
    }

    function createChartData() {
        var basePercentual = 0;
        try {
            basePercentual = itens[0].valor;
        } catch (error) { }

        const data = itens.map(item => ({ label: item.estagio.nome, valor: parseInt(item.valor), percentual: parseFloat((item.valor / basePercentual * 100).toFixed(2)) }))
        const color = itens.map(item => findColor(item.cor))


        const config = {
            data,
            xField: 'label',
            yField: 'valor',
            legend: true,
            transpose: false,
            dynamicHeight: false,
            // autoFit: false,
            // width: 410,
            padding: [20, 0, 30, 0],
            color,
            label: {
                content: function content(item) {
                    return item.label.concat('\n'.concat(item.percentual), '%');
                },
                style: {
                    fontSize: 12,
                    fontWeight: 'bold',
                    textAlign: 'center'
                },
            },

            tooltip: {
                formatter: (item) => {
                    return {
                        name: item.label,
                        value: item.valor
                    };
                }
            }
        };

        setOptions(config)
    }


    return (
        <Card style={{height: cardHeight}}>
            <CardHeader>
                <h5 class="h3 mb-0">Funil de vendas</h5>
                <h6 class="surtitle">Funil de vendas das oportunidades</h6>
            </CardHeader>
            <CardBody>
                <div>
                    <InputGroup className="input-group-alternative">
                        <Select2
                            className="input-group-alternative"
                            defaultValue="-1"
                            options={{
                                placeholder: "Selecione um funil..."
                            }}
                            onSelect={({ target }) => setFunil(target.value)}
                            value={funil}
                            data={funisList.map((item) => ({ id: item.id, text: item.nome }))}
                        />
                    </InputGroup>
                </div>
                <div className="">
                    {
                        loading ?
                            <SpinnerContainer>
                                <Spinner
                                    color='primary'
                                    size='lg'
                                    style={{
                                        alignSelf: 'center'
                                    }}
                                />
                            </SpinnerContainer>
                            :
                            <div className='chart' style={{height: cardHeight-157}}>
                                <Funnel
                                    {...options}
                                />
                            </div>
                    }
                </div>


            </CardBody>
        </Card>
    )
}

export default FunilVendas;