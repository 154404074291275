import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";

import { Input, Button } from "reactstrap";
import IMessage from "../../../../../entities/Meta/message";
import { ChatContext } from "../../chatContext";
import api from "../../../../../services/api";
import {
  ChatHeader,
  ChatWindow,
  MessageBubble,
  MessageDateContainer,
} from "./styles";
import WebSocketClient from "../../AdonisWSController.js";
import { useWhatsappContext } from "../../../../../contexts/Whatsapp";
import { useEmpresaContext } from "../../../../../contexts/Empresa";

interface MsgBoxProps {
}
interface IChat {
  on: (arg0: string, arg1: (e: any) => void) => void;
  send: (arg0: { event: string; data: string; topic: string }) => void;
  emit: (arg0: string, arg1: string) => void;
}

// let chat: {
//   on: (arg0: string, arg1: (e: any) => void) => void;
//   send: (arg0: { event: string; data: string; topic: string }) => void;
//   emit: (arg0: string, arg1: string) => void;
// };

enum typeEnum {
  SINGLE = "SINGLE",
  HYDRATATION = "HYDRATATION",
  STATUS = "STATUS",
}
interface IPayload {
  SINGLE: IMessage;
  HYDRATATION: IMessage[];
  STATUS: IMessage;
  TYPE: typeEnum;
}

const MsgBox: React.FC<MsgBoxProps> = () => {
  const { empresaSelecionada } = useEmpresaContext()
  const chatBoxRef = useRef(null);
  const boxMsgRef = useRef(null);
  const [msgToSend, setMsgToSend] = useState(``);
  const { messages, selectedContact, sendMessage } = useWhatsappContext()
  const [rows, setRows] = useState(1);
  const topicChat = `whatsapp:empresa_id=${empresaSelecionada?.id}&account_id=${selectedContact?.account_id}&wa_id=${selectedContact?.wa_id}`;
  const contactKey = selectedContact?.wa_id.concat(selectedContact?.account_id);

  useEffect(() => {
    scrollToBottom()

  }, [messages, selectedContact]);

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      // @ts-ignore
      chatBoxRef.current.scrollIntoView({ block: 'end' });
    }
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRows(event.target.value.split("\n").length);
    setMsgToSend(event.currentTarget.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSend = () => {

    sendMessage(
      topicChat
      ,
      JSON.stringify({
        type: "message",
        body: {
          type: "text",
          text: msgToSend,
          timestamps: new Date(),
          is_sent: true,
          wa_id: selectedContact?.wa_id,
          account_id: selectedContact?.account_id,
        },
      })
      // ,"newLocation"
    );

    setRows(1);
    setMsgToSend(``);
  };


  if (!selectedContact?.wa_id) {
    console.log("Sem contato selecionado.");
    return (
      <div
        className="d-flex"
        style={{ textAlign: "center", alignItems: "center", height: "100%" }}
      >
        <p>Selecione uma conversa para visualizar as mensagens.</p>
      </div>
    );
  }

  function formatTimestamp(timestamp: string | Date) {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${hours}:${minutes}`;
    // | ${day}/${month}
  }


  return (
    // container
    <section
      className="d-flex flex-column"
      style={{
        height: "100%",
      }}
    >

      <Row>
        <ChatHeader className="col">
          <div className="d-flex w-100 align-items-center">
            <img
              alt="..."
              className="avatar avatar-xs mr-2"
              src={"https://api.dicebear.com/5.x/pixel-art/svg?"}
            />
            <span style={{ display: "inline-block" }}>
              {selectedContact.name} 
            </span>
          </div>
        </ChatHeader>
      </Row>
      <div
        className="row"
        style={{ overflowY: "scroll", height: "100%" }}
        ref={boxMsgRef}
      >
        <ChatWindow className="col" ref={chatBoxRef}>
          {contactKey && (messages.get(contactKey) ?? []).map((e: any, i: any, arr: any) => {
            const currentDate = new Date(
              arr[i].timestamps
            ).toLocaleDateString();

            let previousDate = new Date(
              arr[i - 1]?.timestamps ?? 0
            ).toLocaleDateString();

            let isAnotherDateGroup = false;
            if (currentDate != previousDate) isAnotherDateGroup = true;
            return (
              <Fragment key={e.wamid}>
                {isAnotherDateGroup && (
                  <MessageDateContainer>
                    <div className="date-label text-center">{currentDate}</div>
                  </MessageDateContainer>
                )}

                <MessageBubble key={e.wamid} isSent={e.is_sent}>
                  <div className="box--message">
                    <p className="message--text">{e.text}</p>
                  </div>
                  <div className="box--timestamp">
                    <small className="message--timestamp">
                      {formatTimestamp(e.timestamps)}
                    </small>
                  </div>
                </MessageBubble>
              </Fragment>
            );
          })}
        </ChatWindow>

      </div>
      <div
        style={{
          position: "relative",
          height: "58px",
          borderTop: "1px solid rgb(236, 239, 243)",
        }}
      >


        <Input
          type="textarea"
          placeholder="Digite a sua mensagem aqui..."
          value={msgToSend}
          onChange={handleTextAreaChange}
          onKeyPress={handleKeyPress}
          rows={rows}
          wrap="hard"
          cols={40}
          style={{
            resize: "none",
            paddingRight: "42px",
            position: "absolute",
            bottom: "0px",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            borderRadius: "8px",
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "8px",
            right: "16px",
          }}
        >
          <Button
            color="primary"
            size="sm"
            style={{ borderRadius: "50%" }}
            onClick={() => handleSend()}
          >
            <i className="ni ni-bold-right" />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default MsgBox;
