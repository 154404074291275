import React, { useState, useContext, useEffect } from 'react';
import { Objetivo } from '../../../../../entities/Mentoring';
import EmpresaContext from "../../../../../contexts/Empresa";
import api from "../../../../../services/api";

// import { Container } from './styles';
import ReactDatetime from "react-datetime";
import {
    Button,
    Spinner,
    FormGroup,
    Input,
    Modal,
    Row,
    Col,
    InputGroupText,
    InputGroupAddon,
    InputGroup
} from "reactstrap";
import { appendToElement } from '@fullcalendar/core';

type Props = {
    show: boolean,
    hide(): void,
    objetivo: Objetivo,
    onSuccess(): void
}

type Erro = {
    data_conclusao?: string,
    analise?: string
}

const Concluir: React.FC<Props> = ({
    show,
    hide,
    objetivo,
    onSuccess
}) => {

    const { empresaSelecionada } = useContext(EmpresaContext)

    const [dataConclusao, setDataConclusao] = useState<Date | undefined>()
    const [analise, setAnalise] = useState('')

    const [erro, setErro] = useState<Erro>({} as Erro)

    const [saving, setSaving] = useState(false)


    useEffect(() => {
        setErro({} as Erro)
        setDataConclusao(objetivo.data_conclusao ? new Date(objetivo.data_conclusao) : undefined)
        setAnalise(objetivo.analise)
    }, [show])

    async function handleSave() {
        setErro({} as Erro)
        try {
            setSaving(true)
            await api.post(`common/empresas/${empresaSelecionada?.id}/objetivos/${objetivo?.id}/concluir`,
                { data_conclusao: dataConclusao, analise: analise })
            onSuccess()
        } catch (error: any) {
            console.error(error)
            //Checando se existe response e se existe data dentro do response
            if (error.response && error.response.data) {
                updateErros(error.response.data)
            }
        } finally {
            setSaving(false)
        }
    }

    function updateErros(error: any) {
        if (error && error instanceof Array) {
            const errors = {}
            for (let e of error) {
                //@ts-ignore
                errors[e.field] = e.message
            }
            setErro(errors)
        } else {
            setErro({})
        }
    }

    return (
        <Modal
            className="modal-dialog-centered"
            size='lg'
            isOpen={show}
            toggle={hide}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    Concluir Objetivo
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={hide}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Row>
                    <Col className="col-6">
                        <FormGroup>
                            <legend className="w-auto" style={{ margin: 0 }}>
                                <label className="form-control-label">
                                    Data Conclusão*
                                </label>
                            </legend>
                            <InputGroup className="input-group" >
                                <ReactDatetime
                                    closeOnSelect={true}
                                    locale={'pt-br'}
                                    value={dataConclusao}
                                    inputProps={{
                                        placeholder: "Data Conclusão...",
                                        disabled: !!objetivo.data_conclusao
                                    }}
                                    dateFormat="DD/MM/YYYY"
                                    timeFormat='HH:mm'
                                    //@ts-ignore
                                    onChange={e => setDataConclusao(e)}
                                />
                                <InputGroupAddon addonType="append"  >
                                    <InputGroupText
                                        style={{
                                            padding: '10px'
                                        }}>
                                        <i className="far fa-calendar-alt" />
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                            <small className="text-danger">
                                {erro?.data_conclusao || ""}
                            </small>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <legend className="w-auto" style={{ margin: 0 }}>
                                <label className="form-control-label">
                                    Análise*
                                </label>
                            </legend>
                            <Input
                                className="form-control"
                                placeholder="Análise..."
                                type="textarea"
                                value={analise}
                                disabled={!!objetivo.data_conclusao}
                                onChange={(e) => setAnalise(e.target.value)}
                            />
                            <small className="text-danger">
                                {erro?.analise || ""}
                            </small>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={hide}
                >
                    Fechar
                </Button>
                <Button
                    color="primary"
                    type="button"
                    disabled={saving || !!objetivo.data_conclusao}
                    onClick={handleSave}>
                    <Spinner
                        hidden={!saving}
                        className="mr-2"
                        color="light"
                        size="sm"
                    />
                    Salvar
                </Button>
            </div>
        </Modal>
    );
}

export default Concluir;