import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../contexts/Empresa'
import api from "../../../../services/api";
import ReactQuill from "react-quill";

import {
    Button,
    Row,
    Col,
    FormGroup,
    Spinner,
} from "reactstrap";

export default ({ notify, close, onSuccess, oportunidadeId, comentario = {}, ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [descricao, setDescricao] = useState('')
    const [erros, setErros] = useState({})
    const [saving, setSaving] = useState(false)


    useEffect(() => {
        if (comentario.descricao) setDescricao(comentario.descricao)
    }, [comentario])


    async function insert() {
        if (saving) return 
        try {
            await api.post(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/comentarios`, {
                descricao,
            })
            updateErros(null)
            if (onSuccess) onSuccess()
        } catch (error) {
            console.error(error)
            if (notify) notify('danger', 'Não foi possível adicionar comentário')
            if (error.response) updateErros(error.response.data)
        }finally{
            setSaving(false)
        }
    }

    async function update() {
        if (saving) return 
        setSaving(true)
        try {
            await api.put(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/comentarios/${comentario.id}`, {
                descricao,
            })
            updateErros(null)
            if (onSuccess) onSuccess()
        } catch (error) {
            console.error(error)
            if (notify) notify('danger', 'Não foi possível adicionar comentário')
            if (error.response) updateErros(error.response.data)
        }finally{
            setSaving(false)
        }
    }

    function updateErros(error) {
        if (error && error instanceof Array) {
            const errors = {}
            for (let e of error) {
                errors[e.field] = e.message
            }
            setErros(errors)
        } else {
            setErros({})
        }
    }

    function handleSave() {
        setSaving(true)
        if (oportunidadeId && comentario.id) {
            update()
        } else if (oportunidadeId) {
            insert()
        } else {
            if (valid()) {
                onSuccess({ descricao })
            }
        }
    }

    function valid() {
        const error = {}
        if (!descricao && descricao.length <= 10) {
            error['descricao'] = 'Comentário inválido'
        }
        setErros(error)
        return !error.descricao
    }


    return (
        <>
            <Row>
                <Col  >
                    <FormGroup>
                        <ReactQuill
                            value={descricao || ""}
                            theme="snow"
                            modules={{
                                toolbar: [
                                    ["bold", "italic"],
                                    ["link", "blockquote", "code"],
                                    [
                                        {
                                            list: "ordered"
                                        },
                                        {
                                            list: "bullet"
                                        }
                                    ]
                                ]
                            }}
                            onChange={v => setDescricao(v)}
                        />
                        <small class="text-danger">
                            {erros.descricao || ''}
                        </small>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="float-right ">
                        <Button
                            className="ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => close()}
                        >
                            Fechar
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleSave}
                            disabled={saving}
                        >
                            {
                                    saving &&
                                    <Spinner
                                        size='sm'
                                        color='secondary'
                                        className='mr-2'
                                        // hidden={!saving}
                                    />
                                }
                            Salvar
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
}
