import React, { useState, useEffect } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import Table from "../../../components/Table";

import {
    Spinner
} from "reactstrap";

import "./styles.css"

export default ({ itens, pageProperties, notify, ...props }) => {

    const headerStyle = { fontSize: 9 }

    const [columns] = useState([
        {
            headerStyle,
            dataField: 'colaborador_nome',
            text: 'Colaborador<br/>  &nbsp;',
            headerFormatter: contacatacaoHeader,
        },
        {
            dataField: "oportunidades_total",
            text: 'Oportunidades<br/>Total',
            headerFormatter: contacatacaoHeader,
            headerAlign: 'center',
            align: 'center',
            headerStyle,
        },
        {
            dataField: 'oportunidades_recebidas',
            text: 'oportunidades<br/>recebidas',
            headerFormatter: contacatacaoHeader,
            headerAlign: 'center',
            align: 'center',
            headerStyle,
        },
        {
            headerStyle,
            dataField: 'oportunidades_cadastradas',
            headerFormatter: contacatacaoHeader,
            text: 'oportunidades<br/>cadastradas',
            headerAlign: 'center',
            align: 'center',
        },
        {
            headerStyle,
            dataField: 'oportunidades_em_negociacao',
            headerFormatter: contacatacaoHeader,
            text: 'oportunidades<br/>em negociação',
            headerAlign: 'center',
            align: 'center',
        },
        {
            headerStyle,
            dataField: 'oportunidades_perdidas',
            headerFormatter: contacatacaoHeader,
            text: 'oportunidades<br/>perdidas',
            align: 'center',
            headerAlign: 'center',
        },
        {
            headerStyle,
            dataField: 'oportunidades_convertidas',
            headerFormatter: contacatacaoHeader,
            text: 'oportunidades<br/>convertidas',
            align: 'center',
            headerAlign: 'center',
        },
        {
            headerStyle,
            dataField: 'oportunidades_atrasadas',
            headerFormatter: contacatacaoHeader,
            text: 'oportunidades<br/>atrasadas',
            align: 'center',
            headerAlign: 'center',
        },
        {
            headerStyle,
            dataField: 'oportunidades_atrasadas_periodo',
            headerFormatter: contacatacaoHeader,
            text: 'oportunidades<br/>atrasadas periodo',
            align: 'center',
            headerAlign: 'center',
        },
        {
            headerStyle,
            dataField: 'oportunidades_agendadas_hoje',
            headerFormatter: contacatacaoHeader,
            text: 'oportunidades<br/>agendadas hoje',
            align: 'center',
            headerAlign: 'center',
        },
        {
            headerStyle,
            dataField: 'oportunidades_agendadas_amanha',
            headerFormatter: contacatacaoHeader,
            text: 'oportunidades<br/>agendadas amanhã',
            align: 'center',
            headerAlign: 'center',
        }

    ])

    function contacatacaoHeader(column) {
        return (
            <>
                <div
                    id={`column-${column.text}`}
                >
                    <div dangerouslySetInnerHTML={{ __html: column.text }} />
                </div>
            </>
        );
    }

    return (
        <>
            {
                itens && pageProperties ?
                    <Table
                        columns={columns}
                        data={itens}
                        pageProperties={pageProperties}
                        onTableChange={props.onTableChange}
                    />
                    :
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center'
                    }}>
                        <Spinner
                            color='primary'
                        />
                    </div>
            }
        </>
    );
}
