import React, { useState, useEffect, useContext } from "react";
import EmpresaContext from "../../../contexts/Empresa";
import api from "../../../services/api";

import ReactQuill from "react-quill";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import {
  Button,
  Input,
  Row,
  Col,
  FormGroup,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";

export default ({
  campanha,
  onCampanhaChange,
  notify,
  successAlert,
  history,
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [nome, setNome] = useState(null);
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  const [descricao, setDescricao] = useState(null);
  const [situacao, setSituacao] = useState(null);
  const [afiliacao, setAfiliacao] = useState(false);

  const [erros, setErros] = useState({});
  const [situacoes, setSituacoes] = useState([
    { id: "ativo", text: "Ativo" },
    { id: "inativo", text: "Inativo" },
    { id: "em_analise", text: "Em Análise" },
    { id: "nao_aprovado", text: "Não Aprovado" },
  ]);

  useEffect(() => {
    setNome(campanha.nome);
    setDataInicial(campanha.dt_inicio ? new Date(campanha.dt_inicio) : null);
    setDataFinal(campanha.dt_fim ? new Date(campanha.dt_fim) : null);
    setDescricao(campanha.descricao);
    setSituacao(campanha.situacao);
    setAfiliacao(campanha.afiliacao + "");
  }, [campanha]);

  function handleSave() {
    if (campanha && campanha.id) {
      update();
    } else {
      insert();
    }
  }

  function handleOnBack() {
    history.push(`/admin/campanhas`);
  }

  async function insert() {
    try {
      const response = await api.post(
        `/common/empresas/${empresaSelecionada.id}/campanhas`,
        {
          nome,
          situacao,
          descricao,
          dt_inicio: dataInicial,
          dt_fim: dataFinal,
          afiliacao: false,
        }
      );
      updateErros(null);
      notify("success", "Campanha adicionada com sucesso");
      successAlert("Campanha Salva");
      if (onCampanhaChange) onCampanhaChange(response.data);
    } catch (error) {
      console.log(error);
      if (notify) notify("danger", "Não foi possível salvar campanha");
      updateErros(error.response.data);
    }
  }

  async function update() {
    try {
      const response = await api.put(
        `/common/empresas/${empresaSelecionada.id}/campanhas/${campanha.id}`,
        {
          nome,
          situacao,
          descricao,
          dt_inicio: dataInicial,
          dt_fim: dataFinal,
          afiliacao: false,
        }
      );
      updateErros(null);
      notify("success", "Campanha alterada com sucesso");
      successAlert("Campanha Salva");
      if (onCampanhaChange) onCampanhaChange(response.data);
    } catch (error) {
      console.log(error);
      if (notify) notify("danger", "Não foi possível salvar Campanha");
      updateErros(error.response.data);
    }
  }

  function updateErros(error) {
    try {
      if (error) {
        const errors = {};
        for (let e of error) {
          errors[e.field] = e.message;
        }
        setErros(errors);
      } else {
        setErros({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <FormGroup>
                <label className="form-control-label">Nome*</label>
                <Input
                  className="form-control"
                  placeholder="Nome da campanha..."
                  type="text"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
                <small class="text-danger">
                  {erros.nome ? erros.nome : ""}
                </small>
              </FormGroup>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <FormGroup>
                <label className="form-control-label">Situação*</label>
                <Select2
                  defaultValue="-1"
                  onSelect={(e) => setSituacao(e.target.value)}
                  options={{
                    placeholder: "Selecione a situação da campanha...",
                  }}
                  value={situacao}
                  data={situacoes}
                />
                <small class="text-danger">{erros.situacao || ""}</small>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <FormGroup>
                <label className="form-control-label">
                  Data início da campanha*
                </label>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Selecione quando a campanha inicia...",
                  }}
                  value={dataInicial}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      dataInicial &&
                      dataFinal &&
                      dataInicial._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      dataInicial &&
                      dataFinal &&
                      new Date(dataInicial._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(dataFinal._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      dataFinal &&
                      dataFinal._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  onChange={(e) => setDataInicial(e)}
                />
                <small class="text-danger">{erros.dt_inicio || ""}</small>
              </FormGroup>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <FormGroup>
                <label className="form-control-label">
                  Data final da campanha*
                </label>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Selecione quando a campanha acaba...",
                  }}
                  value={dataFinal}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      dataInicial &&
                      dataFinal &&
                      dataInicial._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      dataInicial &&
                      dataFinal &&
                      new Date(dataInicial._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(dataFinal._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      dataFinal &&
                      dataFinal._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  onChange={(e) => setDataFinal(e)}
                />
                <small class="text-danger">{erros.dt_fim || ""}</small>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Descrição*</label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic"],
                      ["link", "blockquote"],
                      [
                        {
                          list: "ordered",
                        },
                        {
                          list: "bullet",
                        },
                      ],
                    ],
                  }}
                  value={descricao || ""}
                  onChange={(e) => setDescricao(e)}
                />
                <small class="text-danger">{erros.descricao || ""}</small>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="float-right ">
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={handleOnBack}
            >
              Voltar
            </Button>
            <Button color="primary" onClick={handleSave}>
              Salvar
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};
