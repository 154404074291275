import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { IAccount, IContact } from '../pages/marketing/Chat/types';
import IMessage from '../entities/Meta/message';
import WebSocketClient from '../pages/marketing/Chat/AdonisWSController';
import { useEmpresaContext } from '../contexts/Empresa';
import { useAuthContext } from '../contexts/Auth';
import api from '../services/api';
enum typeEnum {
    SINGLE = 'SINGLE',
    HYDRATATION = 'HYDRATATION',
    STATUS = 'STATUS',
}

interface IPayload {
    SINGLE: IContact;
    HYDRATATION: IContact[];
    STATUS: IContact;
    TYPE: typeEnum;
}
interface IMessagePayload {
    SINGLE: IMessage;
    HYDRATATION: IMessage[];
    STATUS: IMessage;
    TYPE: typeEnum;
}

interface IWhatsappContext {
    contacts: IContact[];
    messages: Map<string, IMessage[]>;
    selectedContact: IContact | null;
    updateContacts: (newContacts: IContact[]) => void;
    selectContact: (contact: IContact) => void;
    sendMessage: (topic: string, message: any, event?: string) => void;
}

const initialContext: IWhatsappContext = {
    contacts: [],
    messages: new Map(),
    selectedContact: null,
    updateContacts: () => {
        console.log('-------initialContext');
    },
    selectContact: () => { },
    sendMessage: () => { },

};

export const WhatsappContext = createContext<IWhatsappContext>(initialContext);

export const WhatsappProvider: React.FC = ({ children }) => {
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [accounts, setAccounts] = useState<IAccount[]>([]);
    const [selectedContact, setSelectedContact] = useState<IContact | null>(null);
    const { empresaSelecionada } = useEmpresaContext();
    const { signed } = useAuthContext();
    const webSocketClientRef = useRef<WebSocketClient | null>(new WebSocketClient());
    const [messages, setMessages] = useState<Map<string, IMessage[]>>(new Map());

    let isMount = false
    let webSocketInitialized = false
    useEffect(() => {
        isMount = true
        if (!empresaSelecionada || !signed || webSocketInitialized || !accounts.length) return;

        const webSocketClient = new WebSocketClient();
        webSocketClientRef.current = webSocketClient;

        const handleWebSocketOpen = () => {
            webSocketInitialized = true
            console.log('WebSocket connection established');
            accounts.forEach(acc => {
                const topicToSubscribeContact = `whatsapp:empresa_id=${empresaSelecionada.id}&account_id=${acc.id}`;

                subscribeToTopicContact(topicToSubscribeContact);
            })
        };

        const handleWebSocketClose = () => {
            webSocketInitialized = false
            console.log('WebSocket connection closed');
        };
        // https://playnee.ngrok.app/api
        webSocketClient.connect(process.env.REACT_APP_API_WSS_URL);
        // webSocketClient.connect(process.env.REACT_APP_API_WSS_URL);
        webSocketClient.ws.on('open', handleWebSocketOpen);
        webSocketClient.ws.on('close', handleWebSocketClose);



        return () => {
            isMount = false
            webSocketClient.disconnect();
        };
    }, [empresaSelecionada, signed, accounts]);

    useEffect(() => {
        handleLoadAccounts()
    }, []);

    const subscribeToTopicContact = (topicToSubscribe: string) => {
        const webSocketClient = webSocketClientRef.current;
        webSocketClient?.subscribe(topicToSubscribe, handleWebSocketNewContact);
    };
    const subscribeToTopicMessage = (topicToSubscribe: string) => {
        const webSocketClient = webSocketClientRef.current;
        webSocketClient?.subscribe(topicToSubscribe, handleWebSocketNewChatMessage)
    };

    function showNotification(msg: IMessage) {
        if ('Notification' in window) {
            // Verifica se as permissões de notificação foram concedidas pelo usuário
            if (Notification.permission === 'granted') {

                const ctt = contacts.find((e) => e.account_id == msg.account_id && e.wa_id == msg.wa_id)
                const title = `${ctt?.name} te enviou uma nova mensagem`;
                const options = {
                    body: msg.text,
                    icon: `../assets/icon.png`,
                };

                const notification = new Notification(title, options);
            }
        }
    }

    async function handleLoadMessages() {
        if (!selectedContact?.wa_id) return
        await api.get(
            `/meta/accounts/${selectedContact.account_id}/contacts/${selectedContact.wa_id}/messages`
        );
    }
    async function handleLoadAccounts() {
        const { data } = await api.get(
            `/meta/accounts/`
        );
        setAccounts(data)
    }

    useEffect(() => {

        if (!selectedContact?.wa_id || !empresaSelecionada?.id) return

        const topicToSubscribe = `whatsapp:empresa_id=${empresaSelecionada.id}&account_id=${selectedContact.account_id}&wa_id=${selectedContact.wa_id}`;
        subscribeToTopicMessage(topicToSubscribe)
        handleLoadMessages()

    }, [selectedContact])



    const handleWebSocketNewChatMessage = (body: IMessagePayload) => {
        try {
            switch (body.TYPE) {
                case typeEnum.SINGLE:
                    updateMessages([body.SINGLE], body.TYPE);
                    break;

                case typeEnum.HYDRATATION:
                    updateMessages(body.HYDRATATION, body.TYPE);
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.error('Error parsing WebSocket message:', error);
        }
    };


    const handleWebSocketNewContact = (body: IPayload) => {
        console.log({ body });
        try {
            switch (body.TYPE) {
                case typeEnum.SINGLE:
                    setContacts(prev => {
                        const ctts = prev
                        let find = false
                        const newCtt = ctts.map(e => {
                            if (e.wa_id.concat(e.account_id) == body.SINGLE.wa_id.concat(body.SINGLE.account_id)) {
                                find = true
                                return body.SINGLE
                            }
                            return e
                        })
                        if (!find) {

                            newCtt.push(body.SINGLE)
                        }

                        return newCtt.sort((a, b) => new Date(b.dt_last_message).getTime() - new Date(a.dt_last_message).getTime());
                    });
                    break;

                case typeEnum.HYDRATATION:
                    updateContacts(body.HYDRATATION.sort((a, b) => new Date(b.dt_last_message).getTime() - new Date(a.dt_last_message).getTime()));
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.error('Error parsing WebSocket message:', error);
        }
    };
    function removeDuplicates(data: any, key: any) {
        // @ts-ignore
        return [...new Map(data.map((x) => [key(x), x])).values()];
    }



    const updateMessages = (msgs: IMessage[], type: typeEnum) => {
        if (!msgs.length) return
        const msg = msgs[0]
        if (type == typeEnum.SINGLE) {
            !msg.is_sent && showNotification(msg)
            addMessage(msg.wa_id, msg.account_id, msg)
        } else
            if (type == typeEnum.HYDRATATION) {
                const key = msg.wa_id.concat(msg.account_id);
                setMessages(prev => {
                    const groupedMessages = new Map<string, IMessage[]>(prev);
                    groupedMessages.set(key, msgs)
                    return groupedMessages
                })

            }
    }


    const addMessage = (wa_id: string, account_id: string, message: IMessage) => {
        const key = wa_id.concat(account_id);
        setMessages(prevMessages => {
            const newMessages = new Map(prevMessages);
            if (newMessages.has(key)) {
                newMessages.get(key)!.push(message);
            } else {
                newMessages.set(key, [message]);
            }
            return newMessages;
        });
    };



    const updateContacts = (newContacts: IContact[]) => {
        if (!isMount) return
        setContacts(newContacts);
    };

    const selectContact = (contact: IContact) => {
        console.log('recebendo nova selecao de contato', isMount, contact);


        setSelectedContact(contact);
    };

    // const updateMessage = (contactId: string, messageId: string, updatedMessage: IMessage) => {
    //     if (!isMount) return

    //     setContacts((prevContacts) => {
    //         const updatedContacts = prevContacts.map((contact) => {
    //             if (contact.wa_id === contactId) {
    //                 const updatedMessages = contact.messages.map((message) => {
    //                     if (message.wamid === messageId) {
    //                         return { ...message, ...updatedMessage };
    //                     }
    //                     return message;
    //                 });
    //                 return { ...contact, messages: updatedMessages };
    //             }
    //             return contact;
    //         });
    //         return updatedContacts;
    //     });
    // };

    const sendMessage = (topic: string, message: any, event = 'message') => {
        webSocketClientRef.current?.sendMessage(topic, message, event);
    };

    return (
        <WhatsappContext.Provider
            value={{
                messages,
                contacts,
                selectedContact,
                selectContact,
                sendMessage,
                updateContacts,
            }}
        >
            {children}
        </WhatsappContext.Provider>
    );
};

export const useWhatsappContext = () => useContext(WhatsappContext);

const useWebsocketWhatsapp = () => {
    const { updateContacts, contacts, sendMessage } = useWhatsappContext();

    // Restante do código...

    return {
        sendMessage,
        contacts,
    };
};

export default useWebsocketWhatsapp;
