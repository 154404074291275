import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  UncontrolledCollapse,
} from "reactstrap";
import { ChatContext } from "../../chatContext";
import { IContact } from "../../types";
import ChatCard from "./ChatCard";
import WebSocketClient from "../../AdonisWSController";
import { WhatsappContext, useWhatsappContext } from "../../../../../contexts/Whatsapp";

let contactsHandler: {
  on: (arg0: string, arg1: (e: any) => void) => void;
  send: (arg0: { event: string; data: string; topic: string }) => void;
  emit: (arg0: string, arg1: string) => void;
};

enum typeEnum {
  SINGLE = "SINGLE",
  HYDRATATION = "HYDRATATION",
  STATUS = "STATUS",
}
interface IPayload {
  SINGLE: IContact;
  HYDRATATION: IContact[];
  STATUS: IContact;
  TYPE: typeEnum;
}

const PanelChat: React.FC = () => {
  // @ts-ignore
  const { currentContact } = useContext(ChatContext);
  const { contacts, selectContact, updateContacts } = useContext(WhatsappContext);
  // const { contacts, selectContact, updateContacts } = useWhatsappContext();

  useEffect(() => {
    // initWS();
    const topicToSubscribe = `whatsapp:account_id=0`;


    // const topicToSubscribe = `whatsapp:account_id=0`;
    // for (let topic of Object.keys(ws.subscriptions)) {
    //   if (topic !== "" + currentContact.wa_id) {
    //     console.log("removendo topico " + topicToSubscribe, topicToSubscribe);
    //     ws.unsubscribe(topicToSubscribe);
    //   }
    // }
    // ws.subscribe(topicToSubscribe, handleContacts)
    // ws.sendMessage("contact", JSON.stringify({ body: "valeu" }));
  }, []);

  useEffect(() => {
    console.log({ contacts });
    console.log("=======Contatos Carregados");

  }, [contacts]);

  function removeDuplicates(data: any, key: any) {
    // @ts-ignore
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  const filterdOrder = (arr: IContact[]) => {
    arr.sort(function (a, b) {
      return (
        new Date(b.dt_last_message).getTime() -
        new Date(a.dt_last_message).getTime()
      );
    });

    let seenIds: any = {};

    // Itera sobre o array e mantém apenas os objetos únicos mais recentes
    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];
      const key = obj.wa_id + obj.account_id
      if (!seenIds.hasOwnProperty(key)) {
        seenIds[key] = i;
      }
    }

    const filteredData = Object.values(seenIds)
      .sort()
      .map((index: any) => arr[index]);
    return filteredData;
  };

  // const initWS = () => {
  //   const topicToSubscribe = `whatsapp:account_id=0`;
  //   for (let topic of Object.keys(ws.subscriptions)) {
  //     if (topic !== "" + currentContact.wa_id) {
  //       console.log("removendo topico", topicToSubscribe);
  //       ws.removeSubscription(topicToSubscribe);
  //     }
  //   }

  //   if (!ws.getSubscription(topicToSubscribe)) {
  //     contactsHandler = ws.subscribe(topicToSubscribe);
  //   }
  //   contactsHandler.on("message", handleContacts);
  //   contactsHandler.on("error", console.error);
  //   contactsHandler.emit("contact", JSON.stringify({ body: "valeu" }));
  //   console.log({ subscriptions: Object.keys(ws.subscriptions) });
  // };


  return (
    <>
      <InputGroup className="input-group-sm mb-2">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="fa fa-search" />
          </InputGroupText>
        </InputGroupAddon>
        <Input placeholder="Pesquisar Mensagem" type="text" />
      </InputGroup>
      <Button
        className="btn-block"
        color="primary"
        size="sm"
        id="buttonToggler"
      >
        Filtros
      </Button>
      <UncontrolledCollapse toggler="#linkToggler,#buttonToggler">
        <Card>
          <CardBody>Filtros, vários deles</CardBody>
        </Card>
      </UncontrolledCollapse>
      <ListGroup flush>
        {contacts?.map((data) => (
          <ChatCard key={data.wa_id.concat(data.account_id)} data={data} />
        ))}
      </ListGroup>
    </>
  );
};

export default PanelChat;
