import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import Table from "../../../components/Table";

import { Spinner } from "reactstrap";

import "./styles.css";

export default ({ itens, pageProperties, notify, ...props }) => {
  const headerStyle = { fontSize: 9 };

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (itens && itens.length > 0) {
      const cols = [
        ...new Set(
          itens
            .map((obj) => {
              return Object.entries(obj).map((e) => e[0]);
            })
            .flat()
        ),
      ];

      const newItens = [...itens];

      var newColumns = cols.map((motivo) => {
        return {
          dataField: motivo,
          text: motivo,
          formatter: (cell, row) => <>{row[motivo] || 0}</>,
          headerFormatter: () => <>{motivo}</>,
          headerAlign: "center",
          align: "center",
          headerStyle,
        };
      });
      setColumns([...newColumns]);
    }
  }, [itens]);

  return (
    <>
      {itens.length && columns.length ? (
        <Table
          columns={columns}
          data={itens}
          pageProperties={{ perPage: 5000 }}
          onTableChange={() => {}}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {props.loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            "nenhum dado encontrado"
          )}
        </div>
      )}
    </>
  );
};
