import styled from "styled-components";

const FacebookButton = styled.button`
  background-color: #3b5998;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #2d4373;
  }

  &:active {
    background-color: #2d4373;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
`;
export { FacebookButton };
