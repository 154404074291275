import React, { useState, useEffect } from "react";

import classnames from "classnames";
import Select2 from "react-select2-wrapper";
import {
  Button,
  ButtonGroup,
  Row,
  Col,
  FormGroup,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function SelectPreset(value, onChange, listaPresets) {
  const [preset, setPreset] = useState(null);

  function changePreset(preset) {
    setPreset(preset);
  }

  useEffect(() => {
    if (preset) {
      onChange(preset.id);
    }
  }, [preset]);

  useEffect(() => {
    if (!value) {
      setPreset(null);
    }
  }, [value]);

  return (
    <>
      <UncontrolledDropdown
        style={{
          paddingRight: 4,
        }}
        group
        size="sm"
        direction="down"
      >
        <DropdownToggle caret disabled={false} color={"primary"} style={{}}>
          <i className="fa fa-star" style={{ marginRight: 5 }} />
          {preset ? preset.nome : "Favoritos"}
        </DropdownToggle>
        <DropdownMenu right>
          {listaPresets.map((item, key) => {
            if (preset && item.id === preset.id) return null;
            console.log({item});
            // if (item["porEstagio"]) {
            //   return (
            //     <DropdownMenu right>
            //       {listaPresets.map((item, key) => {
            //         if (preset && item.id === preset.id) return null;
            //         return (
            //           <DropdownItem
            //             key={key}
            //             onClick={(e) => {
            //               e.preventDefault();
            //               changePreset(item);
            //             }}
            //           >
            //             {item.nome} oie
            //           </DropdownItem>
            //         );
            //       })}
            //     </DropdownMenu>
            //   );
            // } else {
              return (
                <DropdownItem
                  key={key}
                  onClick={(e) => {
                    e.preventDefault();
                    changePreset(item);
                  }}
                >
                  {item.nome}
                </DropdownItem>
              );
            // }
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default SelectPreset;
