import React, { useEffect, useState, useRef, useContext } from "react";
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
import moment from "moment";

import Select2 from "react-select2-wrapper";
import ReactQuill from "react-quill";
import ReactDatetime from "react-datetime";
import ReactBSAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";
import DropZone from "../anexos/Files";
import Links from "../anexos/Links";
import NotificationAlert from "react-notification-alert";
import TagsInput from "react-tagsinput";

import {
  UncontrolledTooltip,
  CardFooter,
  Button,
  FormGroup,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  PopoverBody,
  UncontrolledPopover,
  Badge,
} from "reactstrap";

let runAfterSave = null;

export default ({ artigoId, history, onSuccess, onBack, ...props }) => {
  const { empresaSelecionada, empresas, changeEmpresaSelecionada } =
    useContext(EmpresaContext);
  const [artigo, setArtigo] = useState({});

  const [dataPublicacao, setDataPublicacao] = useState(null);
  const [oldDataPublicacao, setOldDataPublicacao] = useState(null);
  const [situacao, setSituacao] = useState(null);
  const [siteId, setSiteId] = useState(null);
  const [categoriaId, setCategoriaId] = useState(null);

  const [titulo, setTitulo] = useState(null);
  const [subtitulo, setSubtitulo] = useState(null);
  const [conteudo, setConteudo] = useState(null);
  const [descricao, setDescricao] = useState(null);
  const [autor, setAutor] = useState(null);
  const [local, setLocal] = useState(null);
  const [tags, setTags] = useState(null);

  const [links, setLinks] = useState([]);

  const [uploadProgress, setUploadProgress] = useState({
    progress: -1,
    totalProgress: 0,
    showModal: false,
  });

  const [situacoesList, setSituacoesList] = useState([]);
  const [sites, setSites] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [imagens, setImagens] = useState([]);
  const [alert, setAlert] = useState(null);
  const [erros, setErros] = useState({});
  const [saving, setSaving] = useState(false);
  const notificationAlert = useRef();

  useEffect(() => {
    if (empresaSelecionada && empresaSelecionada?.id) {
      if (sites.length === 0) loadSites();
      if (situacoesList.length === 0) loadSituacoes();
    }
  }, [empresaSelecionada]);

  useEffect(() => {
    if (artigo.id) {
      fill(artigo);
      setOldDataPublicacao(artigo.data_publicacao);
      runAfterSave = null;
    }
  }, [artigo]);

  useEffect(() => {
    if (artigoId) {
      loadArtigo(artigoId);
    } else {
      fillTags({});
    }
  }, [artigoId]);

  useEffect(() => {
    if (siteId && siteId > 0) {
      loadCategorias(siteId);
    } else {
      setCategorias([]);
    }
  }, [siteId]);

  useEffect(() => {
    if (artigoId && imagens && imagens.length > 0) {
      insertFiles(artigoId);
    }
  }, [imagens, artigoId]);

  //Preencher os campos do formulario
  function fill(artigo) {
    setDataPublicacao(
      artigo.data_publicacao ? new Date(artigo.data_publicacao) : null
    );
    setSituacao(artigo.situacao);
    setSiteId(artigo.categoria.site_id);
    setCategoriaId(artigo.categoria_id);
    setTitulo(artigo.titulo);
    setSubtitulo(artigo.subtitulo);
    setConteudo(artigo.conteudo);
    setDescricao(artigo.descricao);
    setAutor(artigo.autor);
    setLocal(artigo.local);
    fillTags(artigo);
  }

  function fillTags(artigo) {
    if (artigo && artigo.tags) {
      let tags = artigo.tags
        .replace(/\s/g, "")
        .split(",")
        .filter((item) => item.trim() != "");
      tags.forEach((item) => item.trim());
      setTags(tags);
    } else {
      setTags([]);
    }
  }

  async function loadArtigo(artigoId) {
    try {
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/artigos/${artigoId}`
      );
      const data = await response.data;
      setArtigo(data);
    } catch (err) {
      console.error(err);
      notify("danger", "Não foi possível carregar artigo.");
    }
  }

  async function loadSituacoes() {
    try {
      const data = [
        {
          id: "A",
          value: "Ativar",
          label: "Ativo",
          color: "success",
        },
        {
          id: "I",
          value: "Inativar",
          label: "Inativo",
          color: "danger",
        },
      ];

      setSituacoesList(data);
    } catch (err) {
      console.log(err);
    }
  }

  async function loadSites() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/sites-empresas`
      );
      setSites(response.data);
    } catch (err) {
      notify("danger", "Não foi possível carregar os sites.");
    }
  }

  async function loadCategorias(site_id) {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/sites-empresas/${site_id}/categorias?situacao=A`
      );
      setCategorias(response.data);
    } catch (err) {
      notify("danger", "Não foi possível carregar as categorias.");
    }
  }

  async function afterSave(id) {
    if (runAfterSave) await runAfterSave(id);
    successAlert("Publicação salva");
  }

  async function handleSave() {
    setSaving(true);
    runAfterSave = null;
    await onSave();
  }

  async function onSave() {
    await save();
  }

  async function save() {
    if (artigo.id) {
      await update();
    } else {
      await insert();
    }
    setSaving(false);
  }

  async function insert() {
    try {
      const response = await api.post(
        `common/empresas/${empresaSelecionada?.id}/artigos`,
        bind()
      );
      const { id } = await response.data;
      updateErros(null);
      if (imagens.length > 0) {
        await insertFiles(id, true);
      } else {
        await afterSave(id);
      }
    } catch (error) {
      setSaving(false);
      console.log(error);
      notify("danger", "Não foi possível salvar artigo");
      updateErros(error);
    }
  }

  async function insertFiles(artigoId, closeAfter = false) {
    try {
      if (imagens && imagens.length > 0) {
        let countWithIds = 0; //COntator para checar quantos da lista possuem id
        for (let file of imagens) {
          if (file.id) {
            countWithIds = countWithIds + 1;
            continue;
          }
          setUploadProgress({
            progress: 0,
            showModal: true,
          });
          let formData = new FormData();
          formData.append("image", file);
          await api.post(
            `common/empresas/${empresaSelecionada?.id}/artigos/${artigoId}/imagens`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                const index = imagens.indexOf(file) + 1;
                let progress = Math.floor((index * 100) / imagens.length);
                setUploadProgress({
                  showModal: true,
                  progress,
                  index: index - 1,
                });
                // do whatever you like with the percentage complete
                // maybe dispatch an action that will update a progress bar or something
              },
            }
          );
        }

        setTimeout(async () => {
          const progress = { showModal: false };
          if (closeAfter) {
            await afterSave(artigoId);
          } else if (countWithIds != imagens.length) {
            //Se a contagem for diferente, então foram adicionados novas imagens
            progress["reloadList"] = true;
          }
          setUploadProgress(progress);
        }, 1000);
      } else if (!artigoId) {
        await afterSave(artigoId);
      }
    } catch (error) {
      console.error(error);
      setUploadProgress({
        showModal: false,
      });
      notify("danger", "Não foi possível salvar arquivos");
    }
  }

  async function update() {
    try {
      await api.put(
        `common/empresas/${empresaSelecionada?.id}/artigos/${artigo.id}`,
        bind()
      );
      updateErros(null);
      await afterSave();
    } catch (error) {
      setSaving(false);
      console.log(error);
      notify("danger", "Não foi possível atualizar artigo");
      updateErros(error);
    }
  }

  function updateErros(error) {
    try {
      setErros({});
      if (error) {
        const errors = {};
        for (let e of error.response.data) {
          errors[e.field] = e.message;
        }
        setErros(errors);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function bind() {
    return {
      ...artigo,
      data_publicacao:
        moment(dataPublicacao).format("YYYY-MM-DD HH:mm:ss") ===
        moment(oldDataPublicacao).format("YYYY-MM-DD HH:mm:ss")
          ? undefined
          : dataPublicacao,
      situacao,
      links,
      titulo,
      subtitulo,
      conteudo,
      descricao,
      autor,
      local,
      tags: tags.join(),
      categoria_id: categoriaId,
    };
  }

  const notify = (type, title, msg = "") => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    if (notificationAlert.current)
      notificationAlert.current.notificationAlert(options);
  };

  const redirectBack = () => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };

  const successAlert = (msg) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
        title={msg}
        onConfirm={() => (onSuccess ? onSuccess() : redirectBack())}
        confirmBtnBsStyle="success"
        showConfirm={false}
        btnSize=""
      />
    );
    setTimeout(onSuccess ? onSuccess : redirectBack, 2000);
  };

  const onTagsChange = (tagsinput) => {
    if (!tagsinput) return;
    const newTags = tagsinput.map((tag) => tag.trim().toLowerCase());
    let unique = [...new Set(newTags)];
    setTags(unique);
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className={"mt--6"} fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                }}
              >
                <h1>{artigo.id ? "Alterar Artigo" : "Novo Artigo"}</h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="4" sm="12" md="6">
                    <FormGroup>
                      <label className="form-control-label">
                        Data da publicação*
                      </label>
                      <InputGroup className="input-group">
                        <ReactDatetime
                          value={dataPublicacao}
                          inputProps={{
                            placeholder: "Data para publicação...",
                            style: { position: "absolute" },
                          }}
                          isValidDate={(current) => {
                            return current.isAfter(
                              new Date(
                                new Date().setDate(new Date().getDate() - 1)
                              )
                            );
                          }}
                          dateFormat="DD/MM/YYYY"
                          timeFormat="HH:mm"
                          onChange={(e) => setDataPublicacao(e)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            style={{
                              padding: "10px",
                              height: "calc(2.75rem + 2px)",
                            }}
                          >
                            <i class="far fa-calendar-alt" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <small class="text-danger">
                        {erros.data_publicacao || ""}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col lg="2" sm="12" md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Situação*
                      </label>
                      <InputGroup className="input-group-alternative">
                        <Select2
                          className="form-control"
                          value={situacao}
                          options={{
                            placeholder: "Situação...",
                          }}
                          onSelect={(e) => setSituacao(e.target.value)}
                          data={situacoesList.map((item) => ({
                            id: item.id,
                            text: item.label,
                          }))}
                        />
                        <small class="text-danger">
                          {erros.situacao || ""}
                        </small>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" sm="12" md="6">
                    <FormGroup>
                      <label className="form-control-label">Site*</label>
                      <Select2
                        className="form-control"
                        value={siteId}
                        options={{
                          placeholder: "Selecione um site...",
                        }}
                        onSelect={(e) => setSiteId(e.target.value)}
                        data={sites.map((item) => ({
                          id: item.id,
                          text: item.path,
                        }))}
                      />
                      <small class="text-danger">{erros.site_id || ""}</small>
                    </FormGroup>
                  </Col>

                  <Col lg="6" sm="12" md="6">
                    <FormGroup>
                      <label className="form-control-label">Categoria*</label>
                      <Select2
                        className="form-control"
                        value={categoriaId}
                        options={{
                          placeholder: "Selecione uma categoria...",
                        }}
                        onSelect={(e) => setCategoriaId(e.target.value)}
                        data={categorias.map((item) => ({
                          id: item.id,
                          text: item.nome,
                        }))}
                      />
                      <small class="text-danger">
                        {erros.categoria_id || ""}
                      </small>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" sm="12" md="12">
                    <FormGroup>
                      <label className="form-control-label d-flex justify-content-between">
                        <div>
                          Título
                          <Button
                            color="secondary"
                            id="tooltip876279349"
                            outline
                            size="sm"
                            type="button"
                          >
                            ?
                          </Button>
                          <UncontrolledPopover
                            placement="top"
                            target="tooltip876279349"
                          >
                            <PopoverBody>
                              Informe o texto que será apresentado no Título do
                              Artigo...
                            </PopoverBody>
                          </UncontrolledPopover>
                        </div>
                        <Badge
                          size="sm"
                          color="secondary"
                          title={`${
                            titulo?.replace(/<[^>]*>/g, "").length || 0
                          } caracteres no corpo`}
                          className="badge-md badge-circle badge-floating border-white"
                        >
                          {titulo?.replace(/<[^>]*>/g, "")?.length || 0}
                        </Badge>
                      </label>
                      <Input
                        onChange={(v) => setTitulo(v.target.value)}
                        value={titulo || ""}
                      />

                      <small class="text-danger">{erros.titulo || ""}</small>
                    </FormGroup>
                  </Col>
                  <Col lg="12" sm="12" md="12">
                    <FormGroup>
                      <label className="form-control-label d-flex justify-content-between">
                        <div>
                          Subtítulo
                          <Button
                            color="secondary"
                            id="tooltip876279349"
                            outline
                            size="sm"
                            type="button"
                          >
                            ?
                          </Button>
                          <UncontrolledPopover
                            placement="top"
                            target="tooltip876279349"
                          >
                            <PopoverBody>
                              Informe o texto que será apresentado como
                              Subtítulo do Artigo...
                            </PopoverBody>
                          </UncontrolledPopover>
                        </div>
                        <Badge
                          size="sm"
                          color="secondary"
                          title={`${
                            subtitulo?.replace(/<[^>]*>/g, "").length || 0
                          } caracteres no corpo`}
                          className="badge-md badge-circle badge-floating border-white"
                        >
                          {subtitulo?.replace(/<[^>]*>/g, "")?.length || 0}
                        </Badge>
                      </label>
                      <Input
                        onChange={(v) => setSubtitulo(v.target.value)}
                        value={subtitulo || ""}
                      />
                      <small class="text-danger">{erros.subtitulo || ""}</small>
                    </FormGroup>
                  </Col>
                  <Col lg="12" sm="12" md="12">
                    <FormGroup>
                      <label className="form-control-label d-flex justify-content-between">
                        <div>
                          Conteúdo
                          <Button
                            color="secondary"
                            id="tooltip87627934922"
                            outline
                            size="sm"
                            type="button"
                          >
                            ?
                          </Button>
                          <UncontrolledPopover
                            placement="top"
                            target="tooltip87627934922"
                          >
                            <PopoverBody>
                              Informe o texto que será apresentado como conteúdo
                              do artigo...
                            </PopoverBody>
                          </UncontrolledPopover>
                        </div>
                        <Badge
                          size="sm"
                          color="secondary"
                          title={`${
                            conteudo?.replace(/<[^>]*>/g, "")?.length || 0
                          } caracteres `}
                          className="badge-md badge-circle badge-floating border-white"
                        >
                          {conteudo?.replace(/<[^>]*>/g, "")?.length || 0}
                        </Badge>
                      </label>
                      <ReactQuill
                        value={conteudo || ""}
                        theme="snow"
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, false] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link", "image"],
                            ["clean"],
                          ],
                        }}
                        formats={[
                          "header",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "list",
                          "bullet",
                          "indent",
                          "link",
                          "image",
                        ]}
                        onChange={(v) => setConteudo(v)}
                      />
                      <small class="text-danger">{erros.conteudo || ""}</small>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="12" lg="12">
                    <FormGroup>
                      <label className="form-control-label">
                        Descrição
                        <Button
                          color="secondary"
                          id="tooltip8762793492244"
                          outline
                          size="sm"
                          type="button"
                        >
                          ?
                        </Button>
                        <UncontrolledPopover
                          placement="top"
                          target="tooltip8762793492244"
                        >
                          <PopoverBody>
                            Utilize este campo para descrever o artigo.
                          </PopoverBody>
                        </UncontrolledPopover>
                      </label>
                      <ReactQuill
                        value={descricao || ""}
                        theme="snow"
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, false] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link", "image"],
                            ["clean"],
                          ],
                        }}
                        formats={[
                          "header",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "list",
                          "bullet",
                          "indent",
                          "link",
                          "image",
                        ]}
                        onChange={(v) => setDescricao(v)}
                      />
                      <small class="text-danger">{erros.descricao || ""}</small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <FormGroup>
                      <label className="form-control-label">Autor</label>
                      <Input
                        placeholder="Autor..."
                        className="form-control"
                        value={autor}
                        onChange={(e) => setAutor(e.target.value)}
                      />
                      <small className="text-danger">{erros.autor || ""}</small>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <FormGroup>
                      <label className="form-control-label">Local</label>
                      <Input
                        placeholder="Local..."
                        className="form-control"
                        value={local}
                        onChange={(e) => setLocal(e.target.value)}
                      />
                      <small className="text-danger">{erros.local || ""}</small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" sm="12" md="12">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">Tags</label>
                      </legend>
                      <div
                        style={{
                          minHeight: "calc(2.75rem + 2px)",
                          border: "1px solid #DEE2E6",
                          padding: 4,
                          borderRadius: 4,
                        }}
                      >
                        <TagsInput
                          onlyUnique={true}
                          className="bootstrap-tagsinput"
                          onChange={onTagsChange}
                          tagProps={{ className: "tag badge mr-1 secondary" }}
                          value={tags || []}
                          inputProps={{
                            className: "secondary",
                            placeholder: "Tags...",
                            textTransform: "uppercase",
                            style: {},
                          }}
                        />
                      </div>
                      <small class="text-danger">{erros.tags || ""}</small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6" lg="6">
                    <FormGroup>
                      <label />
                      <DropZone
                        alert={setAlert}
                        clearAutoReloadList={() =>
                          setUploadProgress({
                            ...uploadProgress,
                            reloadList: false,
                          })
                        }
                        artigo={artigo}
                        uploadProgress={uploadProgress}
                        notify={notify}
                        onFileListChange={(files) => {
                          setImagens(files);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="6">
                    <FormGroup>
                      <label />
                      <Links
                        artigoId={artigo.id ? artigo.id : null}
                        alert={setAlert}
                        notify={notify}
                        onChangeLinkList={setLinks}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter
                className=""
                style={{
                  position: "sticky",
                  bottom: 0,
                  border: "2 solid",
                  boxShadow: "0px 0px 2px 2px #f5f5f5",
                  zIndex: 50,
                }}
              >
                <Row>
                  <Col>
                    <div
                      className="float-right "
                      style={{
                        flexWrap: "wrap",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        className="ml-auto mt-1"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => redirectBack()}
                      >
                        Voltar
                      </Button>
                      <Button
                        // id={`salvar-publicacao-${artigo.id}`}
                        className=" mt-1"
                        disabled={saving}
                        color="primary"
                        onClick={handleSave}
                      >
                        <Spinner
                          hidden={!saving}
                          className="mr-2"
                          color="light"
                          size="sm"
                        />
                        Salvar
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
