import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../../../../services/api";
//@ts-ignore
import classnames from "classnames";
import { Empresa } from "../../../../../entities/Common";
import EmpresaContext from "../../../../../contexts/Empresa";
import { ApiAccess } from "../../../../../entities/Security";
// @ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// @ts-ignore
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "../../../../../components/Headers/SimpleHeader";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";

import Emails from "./Emails/index";
import Sms from "./CrendenciaisMensagens/index";
import Whatsapp from "./Whatsapp";
import FacebookAds from "./FacebookAds";
import PaginasWeb from "./PaginasWeb";

import { Container, ButtonContainer, InfoToken, LabelToken } from "./styles";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
  CardBody,
  PopoverBody,
  UncontrolledPopover,
  UncontrolledTooltip,
  Card,
  CardHeader,
  Button,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { HeaderContainer } from "../../styles";
import AuthContext from "../../../../../contexts/Auth";

const Integracoes: React.FC = ({ ...props }: any) => {
  const [showEmailForm, setShowEmalForm] = useState(false);
  const [apiAccess, setApiAccess] = useState<ApiAccess>({} as ApiAccess);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [showSmsForm, setShowSmsForm] = useState(false);
  const [textCopiar, setTextCopiar] = useState("Copiar token");
  const notificationAlert = useRef<any>();
  const [fbStatus, setFbStatus] = useState<any>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [connectedPages, setConnectedPages] = useState([]);
  const [tabActive, setTabActive] = useState("facebookAds");
  const [empresa, setEmpresa] = useState({});
  const { hasPermission, hasRole } = useContext(AuthContext)

  const toggle = () => setModal(!modal);

  var externaTabActive = props.location.state?.externaTabActive ? props.location.state.externaTabActive : null;

  useEffect(() => {
    if (externaTabActive && externaTabActive.length > 0) {
      setTabActive(externaTabActive);
    }
  }, [externaTabActive]);

  useEffect(() => {
    if (empresaSelecionada && empresaSelecionada.id) loadApiAccess();
  }, []);

  useEffect(() => {
    console.log({
      TOKEN_FB: new URLSearchParams(window.location.search).get("code"),
    });
  }, []);

  async function checkFacebookStatus() {
    const response = await api.get("common/canais/facebook");
    const { is_valid, pages } = response.data;
    setFbStatus(is_valid);
    setConnectedPages(pages);
  }

  useEffect(() => {
    checkFacebookStatus();
  }, []);

  function onEmpresaChange(empresa: any) {
    setEmpresa(empresa ? empresa : {});
  }

  async function onFacebookResponse({ accessToken }: any) {
    console.log({ accessToken });
    if (!accessToken) {
      notify("danger", "Não conseguimos concluir a operação");
      return;
    }
    try {
      await api.patch(
        `common/empresas/${empresaSelecionada?.id}/updatefacebooktoken`,
        {
          token: accessToken,
        }
      );
      await getMyPages(accessToken);
      notify("success", "Operação realizada");
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível concluir a operação.");
    }
  }

  async function getMyPages(accessToken: any) {
    try {
      const data = await fetch(
        `https://graph.facebook.com/me/accounts?access_token=${accessToken}&fields=access_token,id,%20name&limit=10000`
      ).then((e) => e.json().then((r) => r.data));
      for (const page of data) {
        fetch(
          `https://graph.facebook.com/${page.id}/subscribed_apps?subscribed_fields=leadgen&access_token=${page.access_token}`,
          {
            method: "POST",
          }
        )
          .then((e) => console.log(`listen ${page.name}`))
          .catch(console.error);
      }
    } catch (error) {
      console.log(error);
    }
  }
  function hanleNewEmail(e: React.MouseEvent) {
    e.preventDefault();
    setShowEmalForm(true);
  }

  function handleNewSms(e: React.MouseEvent) {
    e.preventDefault();
    setShowSmsForm(true);
  }

  async function loadApiAccess() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/api-access`
      );
      setApiAccess(response?.data);
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar empresa");
    }
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  const CustomNavItem = ({ children, disabled, name }: any) => {
    return (
      <>
        <NavItem>
          <NavLink
            href="#"
            role="tab"
            className={classnames("mb-sm-3 mb-md-0 mt-2", {
              active: tabActive === name,
            })}
            onClick={() => setTabActive(name)}
            disabled={disabled}
          >
            {children}
          </NavLink>
        </NavItem>

      </>
    );
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="Integrações" parentName="Minha empresa" />

      <div className="container-fluid mt--6">
        <Card>
          <CardHeader>
            <HeaderContainer>
              <h1>Integrações</h1>
            </HeaderContainer>
          </CardHeader>
          <CardBody outline body>
            <Row>
              <Col xs={12} lg={12} md={12} sm={12}>
                <div className="nav-wrapper">
                  <Nav
                    className="nav-fill flex-column flex-md-row"
                    pills
                    role="tablist"
                  >
                    <CustomNavItem name="facebookAds" disabled={!empresaSelecionada?.id}>
                      <>
                        <i className="fab fa-facebook mr-2"></i>
                        Facebook ADS
                      </>
                    </CustomNavItem>
                    <CustomNavItem name="sms" disabled={!empresaSelecionada?.id}>
                      <>
                        <i className="fas fa-comments mr-2"></i>
                        Sms
                      </>
                    </CustomNavItem>
                    <CustomNavItem name="emails"
                      disabled={!empresaSelecionada?.id}
                    >
                      <>
                        <i className="fas fa-envelope mr-2"></i>
                        Emails
                      </>
                    </CustomNavItem>
                    <CustomNavItem name="paginasWeb" disabled={!empresaSelecionada?.id}>
                      <>
                        <i className="fas fa-globe mr-2"></i>
                        Páginas Web
                      </>
                    </CustomNavItem>
                    <span
                      title="consulte o suporte para configurar"
                    >
                      <CustomNavItem name="whatsapp"
                      >
                        <i className="fab fa-whatsapp mr-2"></i>
                        Whatsapp <i className="text-muted">beta</i>
                      </CustomNavItem>
                    </span>
                  </Nav>
                </div>
                <hr className="mt-0" />
                <div>
                  <TabContent activeTab={tabActive}>
                    <TabPane tabId="emails">
                      {empresaSelecionada?.id && (
                        <Emails
                          {...props}
                          onEmpresaChange={onEmpresaChange}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane>
                    <TabPane tabId="sms">
                      {empresaSelecionada?.id && (
                        <Sms
                          {...props}
                          onEmpresaChange={onEmpresaChange}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane>

                    <TabPane tabId="whatsapp"
                      disabled={!hasRole(`administrador`)}
                    >
                      {empresaSelecionada?.id && (
                        <Whatsapp
                          {...props}

                          onEmpresaChange={onEmpresaChange}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane>

                    <TabPane tabId="facebookAds">
                      {empresaSelecionada?.id && (
                        <FacebookAds
                          {...props}
                          onEmpresaChange={onEmpresaChange}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane>

                    <TabPane tabId="paginasWeb">
                      {empresaSelecionada?.id && (
                        <PaginasWeb
                          {...props}
                          onEmpresaChange={onEmpresaChange}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Integracoes;
