import React, { useEffect, useState, useRef, useContext } from 'react';
import Select2 from "react-select2-wrapper";
import AuthContext from '../../../../contexts/Auth'
import EmpresaContext from '../../../../contexts/Empresa'
import api from '../../../../services/api'

import {
    Button,
    FormGroup,
    Modal,
} from "reactstrap";


export default ({ show, onHidden, oportunidade, onConfirm, notify }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const { auth } = useContext(AuthContext)
    const [selecionado, setSelecionado] = useState(undefined)
    const [error, setError] = useState('')

    const colaboradorRef = useRef()

    useEffect(() => {
        setError('')
    }, [selecionado])


    function colaboradorOptions() {
        const option = {
            placeholder: "Selecione um colaborador",
        }
        option['ajax'] = {
            delay: 250,
            url: `${process.env.REACT_APP_API_URL}/common/empresas/${empresaSelecionada.id}/colaboradores?roles=comercial&roles=relacionamento`,
            dataType: 'json',
            headers: {
                Authorization: `Bearer ${auth.token}`
            },
            processResults: function (data) {
                return {
                    results: data.map((item) => ({ id: item.id, text: `${item.name}, ${item.email}` }))
                        .filter(item => (item.id != oportunidade.user_id))
                };
            }
        }
        return option
    }


    async function handleEncaminhar() {
        if (!selecionado) return
        try {
            await api.post(`/common/empresas/${empresaSelecionada.id}/oportunidades/${oportunidade.id}/encaminhamentos`, {
                encaminar_para: selecionado
            })
            onConfirm(selecionado)
        } catch (err) {
            if (err.response) {
                const data = await err.response.data
                if (data.message) {
                    setError(data.message)
                } else {
                    switch (err.response.status) {
                        case 403:
                            notify('danger', 'Você não pode realizar essa operação.')
                            break;
                        default:
                            notify('danger', 'Não foi possível realizar alteração.')
                            break;
                    }
                }
            } else {
                notify('danger', 'Não foi possível realizar alteração.')
            }

        }
    }

    return (
        <>
            <Modal
                className="modal-dialog-centered modal-primary"
                size="md"
                isOpen={show}
                toggle={onHidden}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        Encaminhar Oportunidade
                </h6>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        <i className="fas fa-question-circle ni-3x" />
                        <h4 className="heading mt-4"></h4>
                        <p>
                            Para quem deseja encaminhar está oportunidade?
                        </p>
                        <FormGroup>
                            <Select2
                                ref={colaboradorRef}
                                className="form-control"
                                onSelect={(e) => setSelecionado(e.target.value)}
                                options={{
                                    placeholder: 'Selecione um colaborador...'
                                }}
                                options={colaboradorOptions()}
                                value={selecionado}
                            />
                            <small style={{
                                fontStyle: 'italic'
                            }}>
                                {error}
                            </small>
                        </FormGroup>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHidden}
                    >
                        Fechar
                </Button>
                    <Button
                        onClick={handleEncaminhar}
                        className="btn-white"
                        color="default"
                        type="button">
                        Encaminhar
                </Button>
                </div>
            </Modal>
        </>
    );
}
