import React, { useState, useEffect, useContext } from 'react';
import api from "../../../../services/api";
import EmpresaContext from '../../../../contexts/Empresa'
import { Oportunidade } from "../../../../entities/Marketing";

import { SpinnerContainer } from './styles';
import Table from './Table'
import {
    UncontrolledAlert,
    Button,
    Modal,
    Spinner,
} from "reactstrap";
import AuthContext from '../../../../contexts/Auth';

interface IExport {
    search: object,
    show: boolean,
    onClose(): void,
    isConversao?: boolean,
}

const Export: React.FC<IExport> = ({ search, show, onClose, isConversao }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const { hasRole } = useContext(AuthContext)
    const [alert, setAlert] = useState<string | undefined>()
    const [oportunidades, setOportunidades] = useState(new Array<Oportunidade>())
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setOportunidades(new Array<Oportunidade>())
        if (show) {
            loadOportunidades()
        }
    }, [show])


    async function loadOportunidades() {

        setLoading(true)
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/oportunidades`, {
                params: {
                    ...search,
                    limit: hasRole('administrador') || hasRole('administrador_empresa') ? 'all' : 200,
                    extras: ['telefones']
                }
            })
            const data = await response.data
            setOportunidades(data.data)
        } catch (error) {
            console.error(error)
            showAlert('Não conseguimos carregar as oportunidades')
        }
        setLoading(false)
    }

    function showAlert(texto: string) {
        setAlert(texto)
        setTimeout(() => setAlert(undefined), 5000)
    }

    return (
        <>
            <Modal
                size='lg'
                className="modal-dialog-centered"
                isOpen={show}
                toggle={onClose}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Exportar Oportunidades
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={onClose}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        alert &&
                        <UncontrolledAlert className="alert-danger" fade={true}>
                            <span className="alert-inner--icon">
                                !
                            </span>{" "}
                            <span className="alert-inner--text">
                                {alert}
                            </span>
                        </UncontrolledAlert>
                    }
                    {
                        loading ?
                            <>
                                <div className="modal-body">

                                    <div
                                        style={{
                                            textAlign: 'center'
                                        }}
                                        className='mb-4'>
                                        <br></br>
                                        <h3>
                                            O tempo de execução dessa exportação dependerá da quantidade de oportunidades exportadas.
                                        </h3>
                                    </div>

                                    <div
                                        style={{
                                            textAlign: 'center'
                                        }}
                                        className='mb-4'>
                                        <br></br>
                                        <h4>Aguarde...</h4>

                                        <SpinnerContainer>
                                            <Spinner
                                                size='lg'
                                                color='primary'
                                                style={{
                                                    marginTop: '-150px'
                                                }}
                                            />
                                        </SpinnerContainer>
                                    </div>
                                </div>
                            </>
                            :
                            <Table
                                oportunidades={oportunidades}
                                isConversao={isConversao}
                            />
                    }
                </div>
                <div className="modal-footer">
                    <Button
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={onClose}
                    >
                        fechar
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default Export;