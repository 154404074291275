import React, { useState, useRef, useContext, useEffect } from "react";
import EmpresaContext from "../../../../../../contexts/Empresa";
import api from "../../../../../../services/api";
import NotificationAlert from "react-notification-alert";

import Filters from "./Filters";
import Table from "./Table";
import {
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";
// core components
import SimpleHeader from "../../../../../../components/Headers/SimpleHeader";
import Form from "./Form";

export default ({ empresa, ...props }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [produtoSelecionadoId, setProdutoSelecionadoId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [pageProperties, setPageProperties] = useState({
    total: "0",
    perPage: 10,
    page: 1,
    lastPage: 1,
    loading: false,
    sort: new Map([]),
  });
  const [lastSearch, setLastSearch] = useState({});
  const notificationAlert = useRef();

  useEffect(() => {
    if (!showModal) {
      setProdutos(null);
      loadProdutos();
    }
  }, [showModal]);
  useEffect(() => {
    if (produtoSelecionadoId) {
      setShowModal(true);
    }
  }, [produtoSelecionadoId]);

  function notify(type, msg) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  async function updatePageProperties(response) {
    const { total, perPage, page, lastPage } = await response.data;
    await setPageProperties({
      ...pageProperties,
      total,
      perPage,
      page,
      lastPage,
      loading: false,
    });
  }

  async function loadProdutos(
    page = 1,
    limit = 10,
    sortField = "nome",
    sortOrder = "desc",
    filters = lastSearch
  ) {
    try {
      setPageProperties({
        ...pageProperties,
        loading: true,
      });
      const response = await api.get(
        `/common/empresas/${empresa?.id}/produtos`,
        {
          params: {
            page,
            limit,
            sortField,
            sortOrder,
            ...filters,
          },
        }
      );
      setProdutos(response.data);
      await updatePageProperties(response);
    } catch (err) {
      console.log(err.response);
      notify("danger", "Houve um problema ao carregar os produtos.");
    }
  }

  const handleTableChange = async (
    type,
    { page, sizePerPage, sortField, sortOrder, ...props }
  ) => {
    try {
      if (type == "sort") {
        const value = pageProperties.sort.get(sortField);
        const newOrder = (value || "asc") == "desc" ? "asc" : "desc";
        const map = pageProperties.sort;
        map.set(sortField, newOrder);
        sortOrder = newOrder;
      }
      await loadProdutos(
        page == 0 ? 1 : page,
        sizePerPage,
        sortField,
        sortOrder
      );
    } catch (error) {
      console.error(error);
      notify("danger", "Houve um problema ao carregar as publicações.");
    }
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      {/* <SimpleHeader name="Lita de produtos" parentName="Produtos" {...props} /> */}

      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {/* {tipoProdutoSelecionado ? 'Alterar Produto' : 'Novo Produto'} */}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(!showModal)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            empresaId={empresa?.id}
            produtoId={produtoSelecionadoId}
            onClose={() => {
              setProdutoSelecionadoId(null);
              setShowModal(false);
            }}
          />
        </div>
      </Modal>
      <div className="mt-5">
        <Row>
          <Col>
            <CardHeader
              style={{
                position: "sticky",
                top: 0,
                zIndex: 100,
              }}
            >
              <Filters
                load={(filters) => {
                  setLastSearch(filters);
                  loadProdutos(1, 10, "nome", "desc", filters);
                }}
              />
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Button
                    disabled={!empresa?.id}
                    color="primary"
                    type="button"
                    onClick={() => setShowModal(!showModal)}
                    size="sm"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add" />
                    </span>
                    Novo produto
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table
                    produtos={produtos ? produtos.data : []}
                    notify={notify}
                    onTableChange={handleTableChange}
                    history={props.history}
                    pageProperties={pageProperties}
                    loading={loading}
                    onSelect={setProdutoSelecionadoId}
                  />
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>
      </div>
    </>
  );
};
