import React, { useState, useEffect, useContext, useRef } from "react";

import api from "../../../../../services/api";

import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  CardHeader,
  Card,
  CardBody,
} from "reactstrap";
import EmpresaContext from "../../../../../contexts/Empresa";
import SimpleHeader from "../../../../../components/Headers/SimpleHeader";
// @ts-ignore
import NotificationAlert from "react-notification-alert";

const PesquisaNPS: React.FC = () => {
  const [urlNps, setUrlNps] = useState<any>();
  const [urlGoogleAvaliavao, setUrlGoogleAvaliavao] = useState<any>();
  const [erros, setErros] = useState<any>({});
  const { empresaSelecionada } = useContext(EmpresaContext);
  const notificationAlert = useRef<any>();

  useEffect(() => {
    if (!urlNps) loadUrlNps();
  }, []);

  function handleSaveUrl() {
    console.log("Método handleSaveUrl");
    update();
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  async function update() {
    console.log("Método Update");
    try {
      const response = await api.put(
        `/common/empresas/${empresaSelecionada?.id}/nps/updateurlnps`,
        {
          url_nps: urlNps,
          url_google_avaliacao: urlGoogleAvaliavao,
        }
      );
      updateErros(null);
      notify("success", "Pesquisa nps alterada com sucesso");
      loadUrlNps();
    } catch (error: any) {
      console.log(error);
      if (notify) notify("danger", "Não foi possível salvar pesquisa nps.");
      updateErros(error.response.data);
    }
  }

  function updateErros(error: any) {
    if (error) {
      const errors = {};
      for (let e of error) {
        //@ts-ignore
        errors[e.field] = e.message;
      }
      setErros(errors);
    } else {
      setErros({});
    }
  }

  async function loadUrlNps() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}`
      );
      if (response.data) {
        setUrlNps(response.data.url_nps);
        setUrlGoogleAvaliavao(response.data.url_google_avaliacao);
      }
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar a Url NPS");
    }
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="Pesquisa NPS" parentName="Minha empresa" />
      <div className="container-fluid mt--6">
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <h1>Pesquisa NPS</h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12} md={12}>
                    <FormGroup>
                      {/* <>
              Aqui você pode configurar a url que será usada como link nas malas diretas do tipo NPS (Pesquisa de Satisfação).
              <br></br>
              Você deve inserir a url que corresponde à página responsável por realizar a pesquisa com seus clientes.
              <br></br>
              Use TAGs para parametizar dados que serão preenchidos automaticamente durante o processo de envio de uma Mala Direta do tipo NPS.
              <br></br>
              Ex. de Tags: {'{TAG_CLIENTE_ID}, {TAG_EMPRESA_ID}, {TAG_MALA_DIRETA_ID}'}
              <br></br>
              Ex. de Url: {'https://playnee.com/empresa/nps?cliente_id={TAG_CLIENTE_ID}&empresa_id={TAG_EMPRESA_ID}&mala_direta_id={TAG_MALA_DIRETA_ID}'}
            </> */}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Url NPS
                      </label>
                      <Input
                        className="form-control"
                        placeholder=""
                        type="text"
                        value={urlNps}
                        // @ts-ignore
                        onChange={({ target: any }) => setUrlNps(target.value)}
                        disabled={true}
                      />
                      <small className="text-danger">
                        {erros.urlNps || ""}
                      </small>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Url Google Avaliação
                      </label>
                      <Input
                        className="form-control"
                        placeholder=""
                        type="text"
                        value={urlGoogleAvaliavao}
                        onChange={({ target }) =>
                          setUrlGoogleAvaliavao(target.value)
                        }
                      />
                      <small className="text-danger">
                        {erros.urlGoogleAvaliavao || ""}
                      </small>
                    </FormGroup>
                  </Col>
                </Row>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    color="primary"
                    type="button"
                    className="btn-icon btn-3"
                    onClick={() => handleSaveUrl()}
                  >
                    <span className="btn-inner--text">Salvar</span>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PesquisaNPS;
