import styled from 'styled-components'


export const DivCard = styled.div`
    white-space: initial;
    background-color: white;
    padding: 8px;
    margin: auto auto 10px;
    border-radius: 8px;
    width: 370px;
    max-width: 370px;
    min-height: 200px;
    cursor: pointer;
    transition: 100ms;
    :hover{
        background-color: #e7e7e7;
    }   
`