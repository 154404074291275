import React, { useState } from 'react';

import Chat from '../../../../components/layouts/Chat/Modal'
import InputMask from "react-input-mask";
import {
    Button,
    ListGroup,
    ListGroupItem,
    Modal,
    Badge,
} from "reactstrap";

export default ({ show, onHidde, telefones }) => {

    const [contato, setContato] = useState()
    const [showChat, setShowChat] = useState()
    const [clienteId, setClienteId] = useState()


    function handleCloseChat() {
        setShowChat(false)
        setContato(null)
        setClienteId(null)
    }

    function handleShowChat({ ddd, numero, tipo_telefone, cliente_id }) {
        // if (tipo_telefone?.toLowerCase() === 'celular') {
        //     setClienteId(clienteId)
        //     setContato(`55${ddd}${numero}`)
        //     setClienteId(cliente_id)
        //     setShowChat(true)
        // }

    }

    function handleOpenWhatsapp({ ddd, numero, tipo_telefone }) {
        if (tipo_telefone.toLowerCase() != 'celular') return
        const telefone = `${ddd}${numero}`
        const win = window.open(`https://api.whatsapp.com/send?l=pt_BR&phone=55${telefone}`, '_blank');
        win.focus();
    }

    function isCelular({ tipo_telefone }) {
        return tipo_telefone.toLowerCase() == 'celular'
    }

    return (
        <>
            {
                showChat &&
                <Chat
                    show={showChat}
                    contato={contato}
                    cliente_id={clienteId}
                    canal={'whatsapp'}
                    close={handleCloseChat}
                />
            }
            <Modal
                size='sm'
                className="modal-dialog-centered"
                isOpen={show}
                toggle={onHidde}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Telefones
            </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHidde}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <ListGroup>
                        {
                            telefones.map((item, key) =>
                                <ListGroupItem
                                    key={key}
                                    onClick={() => handleShowChat(item)}
                                    className="list-group-item-action d-flex justify-content-between align-items-center" >
                                    <div>

                                        <InputMask
                                            disabled
                                            className="form-control-flush text-muted"
                                            maskPlaceholder={null}
                                            mask={"(99) 99999-9999"}
                                            value={`${item.ddd}${item.numero}`}
                                        />
                                    </div>
                                    <Badge
                                        color="success"
                                        size='sm'
                                        onClick={() => handleOpenWhatsapp(item)}
                                        style={{
                                            cursor: isCelular(item) ? 'pointer' : 'default'
                                        }}
                                        title={isCelular(item) ? 'Abrir whatsapp' : ''}
                                        pill>
                                        {
                                            isCelular(item) &&
                                            <i class="fab fa-whatsapp mr-2"></i>
                                        }
                                        {item.tipo_telefone}
                                    </Badge>
                                </ListGroupItem>
                            )
                        }
                    </ListGroup>
                </div>
                <div className="modal-footer">
                    <Button
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHidde}
                    >
                        Voltar
            </Button>
                </div>
            </Modal>
        </>
    );
}
