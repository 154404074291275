import React, { useEffect, useState, useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
import moment from 'moment';

import BootstrapTable from "react-bootstrap-table-next";
//@ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
    Button,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormGroup,
} from "reactstrap";
import ReactDatetime from "react-datetime";

import TablePeriodo from './TablePeriodo';

interface Props {
    notify(type: string, msg: string): void,
    meta: any,
    firstDay: Date,
    lastDay: Date,
    colaborador: any,
    unidade: any,
    onSuccess: any,
    onBack: any
}

const Periodos: React.FC<Props> = ({ notify, meta, firstDay, lastDay, colaborador, unidade, onSuccess, onBack }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [periodo, setPeriodo] = useState(null) //periodo selecionado nos combos
    const [periodosAdicionados, setPeriodosAdicionados] = useState([]) //periodos já adicionados ao anuncio
    const [periodoRemoved, setPeriodoRemoved] = useState(null) //periodo selecionado para ser removido

    const [dataInicioPeriodo, setDataInicioPeriodo] = useState(null)
    const [dataFimPeriodo, setDataFimPeriodo] = useState(null)

    const [disablePeriodo, setDisablePeriodo] = useState(false)

    const [somaValorPeriodos, setSomaValorPeriodos] = useState(0);

    function removePeriodoFormatter(cell: any, row: any) {
        return (
            <>
                < div className="col-auto" >
                    <Button
                        className="btn-sm"
                        color="danger"
                        onClick={(e) => {
                            e.preventDefault()
                            setPeriodoRemoved(row)
                        }}
                        title='Excluir'
                    // disabled={!isUltimoPeriodo(row)}
                    >
                        {/* @ts-ignore */}
                        <i class="fas fa-trash"></i>
                    </Button>
                </div >
            </>
        )
    }

    function dataInicioFomatter(cell: any, row: any) {
        if (!row.data_inicio) {
            return
        }
        const data_inicio = moment(row.data_inicio)

        return <>
            <span id={`dataInicio-${data_inicio.format('DDMMYYYY')}`}>
                {data_inicio.format('DD/MM/YYYY')}
            </span>
        </>
    }

    function dataFimFomatter(cell: any, row: any) {
        if (!row.data_fim) {
            return
        }
        const data_fim = moment(row.data_fim)

        return <>
            <span id={`dataFim-${data_fim.format('DDMMYYYY')}`}>
                {data_fim.format('DD/MM/YYYY')}
            </span>
        </>
    }

    const [columns, setColumns] = useState([
        {
            dataField: 'remove',
            formatter: (cell: any, row: any) => removePeriodoFormatter(cell, row),
            editable: false
        },
        {
            dataField: 'data_inicio',
            text: 'Data Início',
            formatter: (cell: any, row: any) => dataInicioFomatter(cell, row),
            editable: false
        },
        {
            dataField: 'data_fim',
            text: 'Data Fim',
            formatter: (cell: any, row: any) => dataFimFomatter(cell, row),
            editable: false
        },
        {
            dataField: 'valor',
            text: 'Valor',
            type: 'number',
            validator: (newValue: number, row: any, column: any) => {
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: 'O campo só aceita números e ponto para separação de centavos em caso de valor monetário.'
                    };
                }
                return true;
            }
        }
    ])

    useEffect(() => {
        if (colaborador && colaborador.id) {
            //alert(colaborador.fail)
            loadPeriodosAdicionados()
        }
    }, [colaborador])

    useEffect(() => {
        if (periodoRemoved) {
            console.log('#### REMOVER PERIODO ####')
            console.log('#### PAREI AQUI ####')

            var ok = isUltimoPeriodo(periodoRemoved)

            if (ok) {
                console.log('#### PODE EXCLUIR ####')
                handleDeletePeriodo(periodoRemoved)
            } else {
                console.log('#### PROIBIDO EXCLUIR ####')
                //notify("warning", "Períodos devem ser excluídos na ordem decrescente")
                window.confirm("Períodos devem ser excluídos na ordem decrescente!")
            }

            setPeriodoRemoved(null)
        }
    }, [periodoRemoved])

    function isUltimoPeriodo(periodo: any) {
        if (periodosAdicionados && periodosAdicionados.length > 0) {
            const ultimoPeriodo = periodosAdicionados[periodosAdicionados.length - 1]
            //@ts-ignore
            if (ultimoPeriodo.data_inicio == periodo.data_inicio) {
                console.log('#### É ULTIMO PERIODO ####')
                return true;
            } else {
                console.log('#### NÂO É ULTIMO PERIODO ####')
                return false;
            }
        }
        return undefined;
    }

    function handleDeletePeriodo(periodo: any) {
        if (periodo) {
            var oldValue = periodo.valor;
            //@ts-ignore
            setPeriodosAdicionados(periodosAdicionados.filter(e => periodo.data_inicio != e.data_inicio))
            recalcularParcialPeriodos(oldValue, 0);
            //@ts-ignore
            setDataFimPeriodo(moment(lastDay))
            setDisablePeriodo(false);
        }
    }

    function recalcularParcialPeriodos(oldValue: number, newValue: number) {
        setSomaValorPeriodos((somaValorPeriodos - oldValue + newValue))
    }


    useEffect(() => {
        if (periodosAdicionados && periodosAdicionados.length > 0) {
            console.log('#### periodosAdicionados.length > 0 ####')
            console.log({ periodosAdicionados })
            var dtInicial = moment(firstDay)

            var value = 0;
            for (const periodo of periodosAdicionados) {
                //@ts-ignore
                value += parseFloat(periodo.valor)
                //@ts-ignore
                if (!moment(periodo.data_fim).isBefore(dtInicial)) {
                    //@ts-ignore
                    dtInicial = moment(periodo.data_fim).add(1, 'day')
                }
            }

            setSomaValorPeriodos(value)

            console.log('COMPARACAO DE DATA INICIAL COM LAST DAY')
            console.log({ dtInicial })
            console.log({ lastDay })

            if (!dtInicial.isAfter(lastDay)) {
                //@ts-ignore
                setDataInicioPeriodo(dtInicial)
            } else {
                console.log('DESABILITANDO PERIODO')
                setDataInicioPeriodo(null)
                setDataFimPeriodo(null)
                setDisablePeriodo(true)
            }

        } else {
            console.log('#### periodosAdicionados VAZIO ou NULL ####')
            //@ts-ignore
            setDataInicioPeriodo(moment(firstDay))
            //@ts-ignore
            setDataFimPeriodo(moment(lastDay))

            setSomaValorPeriodos(0)
        }

        colaborador.periodos = periodosAdicionados

    }, [periodosAdicionados])


    function handleAddPeriodo() {
        console.log('#### handleAddPeriodo ####')

        if (colaborador) {
            console.log('#### COLABORADOR ####')
            console.log({ colaborador })

            if (dataInicioPeriodo && dataFimPeriodo) {
                var periodo = {
                    meta_id: meta ? meta.id : 0,
                    user_id: colaborador ? colaborador.id : 0,
                    name: colaborador ? colaborador.name : '',
                    data_inicio: dataInicioPeriodo,
                    data_fim: dataFimPeriodo,
                    valor: 0
                }

                console.log('#### PERIODO ####')
                console.log({ periodo })


                if (periodosAdicionados && periodosAdicionados.length > 0) {
                    console.log('#### ADICIONANDO AOS PERIODOS_ADICIONADOS ####')
                    //@ts-ignore
                    periodosAdicionados.push(periodo)
                } else {
                    console.log('#### SETANDO PERIODOS_ADICIONADOS ####')
                    //@ts-ignore
                    var periodos = [];
                    periodos.push(periodo)
                    //@ts-ignore
                    //setColaboradores(colabs);
                    //colaborador.pivot.periodos_metas = periodos_metas;
                    setPeriodosAdicionados(periodos)
                }
                //@ts-ignore
                //loadColaboradores();                
                if (dataFimPeriodo && dataFimPeriodo.isBefore(lastDay)) {
                    console.log('ATUALIZANDO ATUMATICAMENTE AS DATAS')
                    //@ts-ignore
                    setDataInicioPeriodo(moment(dataFimPeriodo).add(1, 'day'))
                    //@ts-ignore                    
                    setDataFimPeriodo(moment(lastDay));
                    //moment(periodoInicial).add(1, 'month')
                } else {
                    console.log('PERIODO TOTALMENTE PREENCHIDO')
                    setDataInicioPeriodo(null);
                    setDataFimPeriodo(null)
                    setDisablePeriodo(true)
                }

            } else {
                console.log('DATAS NÃO INFORMADAS')
            }

        } else {
            console.log('COLABORADOR NÂO INFORMADO')
        }

    }

    async function loadPeriodosAdicionados() {
        try {
            if (colaborador && colaborador.periodos) {
                setPeriodosAdicionados(colaborador.periodos)
            }
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar períodos do colaborador')
        }
    }


    function recalcularParcialPeriodo(oldValue: number, newValue: number) {
        setSomaValorPeriodos((somaValorPeriodos - oldValue + newValue))
    }

    //@ts-ignore
    function beforeSaveCellPeriodo(oldValue, newValue, row, column, done) {
        setTimeout(() => {
            if (unidade) {
                if (unidade == 'M') {
                    //UNIDADE MONETÁRIA
                    var value = parseFloat(newValue);

                    if (value == newValue) {
                        if (window.confirm('Está de acordo com essa alteração?')) {
                            recalcularParcialPeriodo(oldValue, newValue);
                            done(true);
                        } else {
                            done(false);
                        }
                    } else {
                        if (window.confirm('Valor não condiz com a Unidade MONETÁRIA!')) {
                            done(false);
                        } else {
                            done(false);
                        }
                    }

                } else {
                    //UNIDADE QUANTIDADE
                    var value = parseInt(newValue);
                    if (value == newValue) {
                        if (window.confirm('Está de acordo com essa alteração?')) {
                            recalcularParcialPeriodo(oldValue, newValue);
                            done(true);
                        } else {
                            done(false);
                        }
                    } else {
                        if (window.confirm('Valor não condiz com a Unidade QUANTIDADE! Informe um valor inteiro.')) {
                            done(false);
                        } else {
                            done(false);
                        }
                    }
                }

            } else {
                //UNIDADE DESCONHECIDA
                done(false);
            }

        }, 0);
        return { async: true };
    }

    function handleDistruibuirPorPeriodos() {
        if (colaborador.valor && colaborador.valor > 0) {
            if (unidade) {
                //if (window.confirm('Deseja realmente Redistribuir Metas por Períodos?')) {
                if (window.confirm('Deseja realmente distribuir por igual a Meta para o(s) período(s) adicionado(s)?')) {
                    setSomaValorPeriodos(0);
                    let soma = 0;
                    let divisaoMeta = (colaborador.valor / periodosAdicionados.length)

                    let valorTratadoByUnidade = (unidade == 'Q') ? Math.trunc(divisaoMeta) : parseFloat(divisaoMeta.toFixed(2));

                    for (const periodo of periodosAdicionados) {
                        // @ts-ignore
                        periodo.valor = valorTratadoByUnidade
                        // @ts-ignore
                        soma += periodo.valor;
                    }

                    setSomaValorPeriodos(soma)

                    //@ts-ignore
                    setPeriodosAdicionados([...periodosAdicionados]);

                    if (valorTratadoByUnidade != divisaoMeta) {
                        (valorTratadoByUnidade < divisaoMeta) ?
                            notify('warning', `A distribuição não foi exata, ocasionando saldo positivo. Confira a distruibuição e adicione este saldo a algum(ns) período(s) manualmente.`)
                            :
                            notify('warning', `A distribuição não foi exata, ocasionando saldo negativo de alguns centavos. Confira a distruibuição e reduza a meta de algum(ns) período(s) manualmente.`)
                    }

                }

            } else {
                notify('danger', 'Unidade ainda não Definida')
            }
        } else {
            notify('danger', 'Meta ainda não Definida')
        }
    }

    return (
        <>
            {/* <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            > */}
            <Row>
                <Col sm="12" md="3" lg="3">
                    {/* style={{ marginRight: '-20px' }}> */}
                    <FormGroup>
                        {/* @ts-ignore */}
                        <legend class="w-auto" style={{ margin: 0 }}>
                            <label
                                className="form-control-label"
                            >
                                Início*
                            </label>
                        </legend>
                        <InputGroup className="input-group" >
                            {/* @ts-ignore */}
                            <ReactDatetime
                                closeOnSelect={true}
                                isValidDate={current => {
                                    return current.isAfter(moment(firstDay).add(-1, 'day'))
                                        && !current.isAfter(moment(lastDay))
                                }}
                                locale={'pt-br'}
                                value={
                                    dataInicioPeriodo
                                        ? moment(dataInicioPeriodo)
                                        : null
                                }
                                inputProps={{
                                    placeholder: "Data Início...",
                                    name: "data_inicio",
                                    id: "data_inicio",
                                    autocomplete: "off",
                                    disabled: true
                                }}
                                dateFormat="DD/MM/YYYY"
                                timeFormat={false}
                                //@ts-ignore
                                onChange={e => setDataInicioPeriodo(e)}
                            />
                            {
                                // !disablePeriodo &&
                                // <InputGroupAddon addonType="append"  >
                                //     <InputGroupText
                                //         style={{
                                //             padding: '10px'
                                //         }}>
                                //         {/* @ts-ignore */}
                                //         <i class="far fa-calendar-alt" />
                                //     </InputGroupText>
                                // </InputGroupAddon>
                            }
                        </InputGroup>
                        {/* <small class="text-danger">
                                {erros.data_agendamento || ""}
                            </small> */}
                    </FormGroup>
                </Col>
                <Col sm="12" md="3" lg="3">
                    {/* style={{ marginRight: '-20px' }}> */}
                    <FormGroup>
                        {/* @ts-ignore */}
                        <legend class="w-auto" style={{ margin: 0 }}>
                            <label
                                className="form-control-label"
                            >
                                Fim*
                            </label>
                        </legend>
                        <InputGroup className="input-group" >
                            {/* @ts-ignore */}
                            <ReactDatetime
                                closeOnSelect={true}
                                isValidDate={current => {
                                    return current.isAfter(moment(firstDay).add(-1, 'day'))
                                        && current.isBefore(moment(lastDay))
                                        && (dataInicioPeriodo && current.isAfter(moment(dataInicioPeriodo).add(-1, 'day')))
                                }}
                                locale={'pt-br'}
                                value={
                                    dataFimPeriodo
                                        ? moment(dataFimPeriodo)
                                        : null
                                }
                                inputProps={{
                                    placeholder: "Data fim...",
                                    name: "data_fim",
                                    id: "data_fim",
                                    autocomplete: "off",
                                    disabled: disablePeriodo
                                }}
                                dateFormat="DD/MM/YYYY"
                                timeFormat={false}
                                //@ts-ignore
                                onChange={e => setDataFimPeriodo(e)}
                            />
                            {
                                // !disablePeriodo &&
                                // <InputGroupAddon addonType="append"  >
                                //     <InputGroupText
                                //         style={{
                                //             padding: '10px'
                                //         }}>
                                //         {/* @ts-ignore */}
                                //         <i class="far fa-calendar-alt" />
                                //     </InputGroupText>
                                // </InputGroupAddon>
                            }
                        </InputGroup>
                        {/* <small class="text-danger">
                                {erros.data_agendamento || ""}
                            </small> */}
                    </FormGroup>
                </Col>
                <Col sm="12" md="3" lg="3">
                    {/* style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}> */}
                    <Button
                        style={{ marginTop: 42 }}
                        color="primary"
                        type="button"
                        outline
                        onClick={handleAddPeriodo}
                        className="btn-icon btn-3"
                        disabled={disablePeriodo}
                    >
                        <span className="btn-inner--icon">
                            <i className="ni ni-fat-add"></i>
                        </span>
                        <span className="btn-inner--text">Adicionar</span>
                    </Button>
                </Col>

                <Col sm="12" md="3" lg="3">
                    {/* style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}> */}
                    <Button
                        style={{ marginTop: 42 }}
                        color="primary"
                        type="button"
                        outline
                        onClick={handleDistruibuirPorPeriodos}
                        className="btn-icon btn-3"
                    >
                        <span className="btn-inner--icon">
                            <i className="fas fa-divide"></i>
                        </span>
                        <span className="btn-inner--text">Distribuir</span>
                    </Button>
                </Col>

            </Row>

            {/* </div> */}
            <TablePeriodo
                periodos={periodosAdicionados}
                columnsPeriodos={columns}
                unidade={unidade}
                valor={colaborador.valor}
                somaValorPeriodos={somaValorPeriodos}
                beforeSaveCellPeriodo={beforeSaveCellPeriodo}
                handleDistruibuirPorPeriodos={handleDistruibuirPorPeriodos}

            >
            </TablePeriodo>
        </>
    );
}

export default Periodos
