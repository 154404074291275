import React, { useEffect, useState, useRef, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
import moment from 'moment';

import Select2 from "react-select2-wrapper";
import ReactQuill from "react-quill";
import ReactDatetime from "react-datetime";
import AttachMarcacao from "../../marcacoes/Attach";
import ReactBSAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";
import DropZone from "../anexos/Files";
import Links from "../anexos/Links";
import PonderarButton from '../PonderarButton';
import NotificationAlert from "react-notification-alert";
import Header from './Header'
import SelectDesigner from '../SelectDesigner'
import {
    UncontrolledTooltip,
    CardFooter,
    Button,
    FormGroup,
    Spinner,
    CardBody,
    Container,
    Row,
    Col,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    PopoverBody,
    UncontrolledPopover,
    Badge
} from "reactstrap";

let runAfterSave = null

export default ({ publicacaoId, anuncio, history, clone, onSuccess, onBack, onBackAnuncio, anuncioToBack, ...props }) => {

    const { empresaSelecionada, empresas, changeEmpresaSelecionada } = useContext(EmpresaContext)
    const [publicacao, setPublicacao] = useState({})
    const [destinoId, setDestinoId] = useState([])
    const [titulo, setTitulo] = useState(null)
    const [corpoTexto, setCorpoTexto] = useState(null)
    const [showCabecalhoPadrao, setShowCabecalhoPadrao] = useState(true)
    const [showRodapePadrao, setShowRodapePadrao] = useState(true)
    const [textoImagem, setTextoImagem] = useState(null)
    const [dataPostagem, setDataPostagem] = useState(null)
    const [oldDataPostagem, setOldDataPostagem] = useState(null)
    const [formatoId, setFormatoId] = useState(null)
    const [trafegoId, setTrafegoId] = useState(null)
    const [sugestao, setSugestao] = useState(null)
    const [marcacoes, setMarcacoes] = useState(null)
    const [pilarId, setPilarId] = useState(null)
    const [links, setLinks] = useState([])
    const [designerResponsavelId, setDesignderResponsavelId] = useState(undefined)

    const [uploadProgress, setUploadProgress] = useState({
        progress: -1,
        totalProgress: 0,
        showModal: false
    })
    const [imagens, setImagens] = useState([])
    const [pilares, setPilares] = useState([])
    const [trafegos, setTrafegos] = useState([])
    const [formatos, setFormatos] = useState([])
    const [destinos, setDestinos] = useState([])
    const [designers, setDesigners] = useState([])
    const [alert, setAlert] = useState(null)
    const [erros, setErros] = useState({})
    const [saving, setSaving] = useState(false)
    const [fluxo, setFluxo] = useState({})
    const [showSelectDesigner, setShowSelectDesigner] = useState(false)
    const notificationAlert = useRef()

    useEffect(() => {
        if (empresaSelecionada && empresaSelecionada?.id) {
            if (formatos.length === 0) loadFormatos()
            if (pilares.length === 0) loadPilares()
            if (trafegos.length === 0) loadTrafegos()
            if (destinos.length === 0) loadDestinos()
            if (!fluxo.id) loadFluxo()
        }
    }, [empresaSelecionada])

    useEffect(() => {
        if (!publicacao.id && saving) {
            //Se for uma tela de insert e o modal de designer estiver visivel...
            setShowSelectDesigner(false);
            save()
        }
    }, [designerResponsavelId]) //Caso o designer responsavel mude...

    useEffect(() => {
        if (publicacao.id) {
            if (empresaSelecionada?.id !== publicacao.empresa_id) {
                fixEmpresaSelecionada()
            }
            fill(publicacao)
            setOldDataPostagem(publicacao.data_postagem)
            runAfterSave = null
        }
    }, [publicacao])

    useEffect(() => {
        if (anuncio?.id) {
            fillByAnuncio()
        }
    }, [anuncio])

    useEffect(() => {
        if (publicacaoId) {
            loadPublicacao(publicacaoId)
        } else {
            fillMarcacoes({})
        }
    }, [publicacaoId])

    useEffect(() => {
        if (clone?.id) {
            setPublicacao({
                titulo: `Clone - ${clone.titulo}`
            })
            clone.designer_responsavel_id = undefined
            fill(clone)
        }
    }, [clone])

    useEffect(() => {
        if (publicacaoId && (imagens && imagens.length > 0)) {
            insertFiles(publicacaoId)
        }
    }, [imagens, publicacaoId])

    useEffect(() => {
        //Se for nova publicação e ouver erros
        if (!publicacao.id && Object.keys(erros).length !== 0) setDesignderResponsavelId(null)
    }, [erros])

    function fixEmpresaSelecionada() {
        const empresa = empresas.find(item => item.id === publicacao.empresa_id)
        if (empresa) {
            changeEmpresaSelecionada(empresa)
            window.location.reload()
        } else {
            history.push('/publicacoes')
        }
    }

    async function loadFluxo() {
        try {
            const response = await api.get("/common/fluxos/vigente");
            setFluxo(await response.data)
        } catch (err) {
            notify('danger', "Não foi possível carregar fluxo de fases.")
        }
    }

    //Carregue os campos com base no anuncio
    function fillByAnuncio() {
        fill({
            titulo: anuncio.nome,
            data_postagem: anuncio.dt_inicio,
        })
        setPublicacao({ titulo: anuncio.nome })
        loadTrafegoAnuncio()
    }

    //Preencher os campos do formulario
    function fill(publicacao) {
        setTitulo(publicacao.titulo)
        setDestinoId(publicacao?.destinos_id ? publicacao?.destinos_id : [])
        setCorpoTexto(publicacao.corpo_texto)
        setTextoImagem(publicacao.texto_imagem)
        setDataPostagem(publicacao.data_postagem ? new Date(publicacao.data_postagem) : null)
        setFormatoId(publicacao.formato_id)
        setTrafegoId(publicacao.trafego_id)
        setSugestao(publicacao.sugestao)
        setPilarId(publicacao.pilar_id)
        fillMarcacoes(publicacao)
        setDesignderResponsavelId(publicacao.designer_responsavel_id)

        setShowCabecalhoPadrao(publicacao.show_cabecalho_padrao ? true : false)
        setShowRodapePadrao(publicacao.show_rodape_padrao ? true : false)
    }

    function fillMarcacoes(publicacao) {
        if (publicacao.marcacoes) {
            let marks = publicacao.marcacoes.replace(/\s/g, "").split('#').filter(item => item.trim() != '')
            marks.forEach(item => item.trim())
            setMarcacoes(marks)
        } else if (!publicacaoId) {
            loadMarcacoes()
        } else {
            setMarcacoes([])
        }
    }

    async function loadMarcacoes() {
        try {
            const response = await api.get('/marketing/marcacoes/' + empresaSelecionada?.id)
            let marcacoes = response.data
            marcacoes = marcacoes.filter(item => item.padrao == 'S').map(item => item.marcacao)
            setMarcacoes(marcacoes)
        } catch (err) {
            notify('danger', 'Não foi possível carregar marcações.')
        }
    }


    async function selectDesignerDisponivel() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/designers-disponiveis`)
            const desigersDisponiveis = await response.data
            if (desigersDisponiveis.length == 1) {
                setDesignderResponsavelId(desigersDisponiveis[0].id)
            } else if (desigersDisponiveis.length > 1) {
                setDesigners(desigersDisponiveis)
                setShowSelectDesigner(true)
            } else {
                await save()
            }
        } catch (err) {
            notify('danger', 'Não foi possível carregar designers da empresa.')
        }
    }

    async function loadPublicacao(publicacaoId) {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacaoId}`);
            const data = await response.data
            setPublicacao(data)
        } catch (err) {
            console.error(err);
            notify("danger", "Não foi possível carregar publicacao.");
        }
    }

    async function loadTrafegoAnuncio() {
        const valor = await findTrafego('trafego_anuncio')
        if (valor) setTrafegoId(parseInt(valor))
    }


    async function findTrafego(key) {
        try {
            const response = await api.get(`common/parametros/${key}`);
            const valor = await response.data.valor
            return valor
        } catch (err) {
            console.error(err);
        }
    }

    async function loadDestinos() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/destinos/`, {
                params: {
                    situacao: 'A'
                }
            })
            setDestinos(response.data)
        } catch (err) {
            notify("danger", "Não foi possível carregar as destinos.");
        }
    }

    async function loadTrafegos() {
        try {
            const response = await api.get("/marketing/trafegos", {
                params: { anuncio: anuncio != null }
            });
            const array = await response.data
            setTrafegos(array)
        } catch (err) {
            console.error(err);
            notify("danger", "Não foi possível carregar trafegos.");
        }
    }


    async function loadPilares() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/pilares?situacao=A`);
            setPilares(response.data)
        } catch (err) {
            notify("danger", "Não foi possível carregar os pilares.");
        }
    }

    async function loadFormatos() {
        try {
            const response = await api.get("/marketing/formatos");
            setFormatos(response.data)
        } catch (err) {
            console.error(err);
            notify("danger", "Não foi possível carregar formatos.");
        }
    }

    async function avancarFase(publicacaoId = publicacao.id) {
        try {
            await api.put(`common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacaoId}/fases/avancar-fase`);
        } catch (err) {
            console.error(err);
            notify('danger', 'Não foi possível alterar fase da publicação.')
        }
        setAlert(null)
    }

    function getNextFase() {
        if (!fluxo.id) return {}

        let faseAtual = fluxo.faseInicial.id
        if (publicacao.id) {
            faseAtual = publicacao.fase_atual_id
        }
        return fluxo.fases.find(item => item.fase_antecessora_id === faseAtual)
    }

    async function handleSaveAvancar() {

        const confirmFunction = async () => {
            try {
                setAlert(null)
                runAfterSave = avancarFase
                setSaving(true)
                await onSave()
            } catch (error) {
                console.error(error)
            }
        }

        setAlert(<ReactBSAlert
            custom
            customIcon={
                <div
                    className="swal2-icon swal2-question swal2-animate-question-icon"
                    style={{ display: "flex" }}
                >
                    <span className="swal2-icon-text">?</span>
                </div>
            }
            title=''
            onConfirm={confirmFunction}
            onCancel={() => setAlert(null)}
            showCancel
            confirmBtnBsStyle="primary"
            confirmBtnText="Sim"
            cancelBtnText="cancelar"
            btnSize=""
        >
            {`Salvar publicação e avançar para fase ${getNextFase().nome}?`}
        </ReactBSAlert>)
    }


    async function afterSave(id) {
        if (runAfterSave) await runAfterSave(id)
        successAlert('Publicação salva')
    }


    async function handleSave() {
        setSaving(true)
        runAfterSave = null
        await onSave()
    }

    async function onSave() {
        //Caso seja uma nova publicação, sempre seleciona o designer
        if (!publicacao.id) {
            await selectDesignerDisponivel()
        } else {
            await save()
        }
    }

    async function save() {
        if (publicacao.id) {
            await update()
        } else {
            await insert()
        }
        setSaving(false)
    }

    async function insert() {
        try {
            const response = await api.post(`common/empresas/${empresaSelecionada?.id}/publicacoes`, bind())
            const { id } = await response.data
            updateErros(null)
            if (imagens.length > 0) {
                await insertFiles(id, true)
            } else {
                await afterSave(id)
            }
        } catch (error) {
            setSaving(false)
            console.log(error)
            notify('danger', 'Não foi possível salvar publicação')
            updateErros(error)
        }
    }

    async function insertFiles(publicacaoId, closeAfter = false) {
        try {
            if (imagens && imagens.length > 0) {
                let countWithIds = 0 //COntator para checar quantos da lista possuem id
                for (let file of imagens) {
                    if (file.id) {
                        countWithIds = countWithIds + 1
                        continue;
                    }
                    setUploadProgress({
                        progress: 0,
                        showModal: true
                    })
                    let formData = new FormData()
                    formData.append("image", file);
                    await api.post(`common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacaoId}/imagens`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: progressEvent => {
                                const index = imagens.indexOf(file) + 1
                                let progress = Math.floor((index * 100) / imagens.length);
                                setUploadProgress({ showModal: true, progress, index: index - 1, })
                                // do whatever you like with the percentage complete
                                // maybe dispatch an action that will update a progress bar or something
                            }
                        })
                }

                setTimeout(async () => {
                    const progress = { showModal: false }
                    if (closeAfter) {
                        await afterSave(publicacaoId)
                    } else if (countWithIds != imagens.length) {
                        //Se a contagem for diferente, então foram adicionados novas imagens
                        progress['reloadList'] = true
                    }
                    setUploadProgress(progress)
                }, 1000)
            } else if (!publicacaoId) {
                await afterSave(publicacaoId)
            }
        } catch (error) {
            console.error(error)
            setUploadProgress({
                showModal: false
            })
            notify('danger', 'Não foi possível salvar arquivos')
        }
    }

    async function update() {
        try {
            await api.put(`common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacao.id}`, bind())
            updateErros(null)
            await afterSave()
        } catch (error) {
            setSaving(false)
            console.log(error)
            notify('danger', 'Não foi possível salvar publicação')
            updateErros(error)
        }
    }

    function updateErros(error) {
        try {
            setErros({})
            if (error) {
                const errors = {}
                for (let e of error.response.data) {
                    errors[e.field] = e.message
                }
                setErros(errors)
            }
        } catch (error) {
            console.error(error)
        }
    }

    function bind() {
        return {
            ...publicacao,
            links,
            titulo,
            data_postagem: moment(dataPostagem).format('YYYY-MM-DD HH:mm:ss') === moment(oldDataPostagem).format('YYYY-MM-DD HH:mm:ss') ? undefined : dataPostagem,
            formato_id: formatoId,
            destinos: destinoId,
            pilar_id: pilarId,
            marcacoes,
            trafego_id: trafegoId,
            corpo_texto: corpoTexto,
            texto_imagem: textoImagem,
            sugestao,
            anuncio_id: anuncio ? anuncio.id : (clone ? clone.anuncio_id : (publicacao ? publicacao.anuncio_id : null)),
            designer_responsavel_id: designerResponsavelId,
            show_cabecalho_padrao: empresaSelecionada && empresaSelecionada.id && empresaSelecionada.cabecalho_publicacao && showCabecalhoPadrao ? true : false,
            show_rodape_padrao: empresaSelecionada && empresaSelecionada.id && empresaSelecionada.rodape_publicacao && showRodapePadrao ? true : false
        }
    }

    const notify = (type, title, msg = '') => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {title}
                    </span>
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3
        };
        if (notificationAlert.current) notificationAlert.current.notificationAlert(options);
    };

    const redirectBack = () => {
        if (onBack) {
            onBack();
        } else {
            if (onBackAnuncio) {
                history.push(`/admin/campanhas/${anuncioToBack.campanha_id}/edit`, { toAnuncio: anuncioToBack })
            } else {
                history.goBack();
            }
        }
    };

    const hasFeedSelected = () => {
        const ids = destinos.filter(item => item.tipo == 'feed').map(item => item.id)
        let has = 0
        ids.forEach(item => {
            if (destinoId.includes(item)) has = has + 1 //Incrementa o has caso ache um item
        })
        return has > 0
    }

    const successAlert = msg => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
                title={msg}
                onConfirm={() => onSuccess ? onSuccess() : redirectBack()}
                confirmBtnBsStyle="success"
                showConfirm={false}
                btnSize=""
            />
        )
        setTimeout(onSuccess ? onSuccess : redirectBack, 2000);
    };

    return (
        <>
            {alert}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Container className={anuncio ? '' : "mt--6"} fluid>
                <Row>
                    <Col>
                        <div className={anuncio ? '' : "card"}>
                            <Header
                                style={anuncio ? { paddingTop: 0 } : null}
                                publicacao={publicacao}
                                onTituloChanged={setTitulo}
                                erros={erros}
                                alert={setAlert}
                                notify={notify}
                                onPublicacaoChanged={setPublicacao}
                            />
                            <SelectDesigner
                                confirmButtonText='Salvar Publicação'
                                designers={designers}
                                show={showSelectDesigner}
                                onHidden={() => {
                                    setShowSelectDesigner(false)
                                    setSaving(false)
                                }}
                                onConfirm={data => {
                                    setDesignderResponsavelId(data.id)
                                }}
                            />
                            <CardBody style={{ backgroundColor: '#ffff' }}>
                                <Row>
                                    <Col lg="4" sm="12" md="6">
                                        <FormGroup>
                                            <label className="form-control-label">
                                                Data da publicação*
                                            </label>
                                            <InputGroup className="input-group" >
                                                <ReactDatetime
                                                    value={dataPostagem}
                                                    inputProps={{
                                                        placeholder: "Data para publicação...",
                                                        style: { position: 'absolute' }
                                                    }}
                                                    isValidDate={current => {
                                                        return current.isAfter(new Date(new Date().setDate(new Date().getDate() - 1)));
                                                    }}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat='HH:mm'
                                                    onChange={e => setDataPostagem(e)}
                                                />
                                                <InputGroupAddon addonType="append" >
                                                    <InputGroupText
                                                        style={{
                                                            padding: '10px',
                                                            height: 'calc(2.75rem + 2px)',
                                                        }}>
                                                        <i class="far fa-calendar-alt" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <small class="text-danger">
                                                {erros.data_postagem || ''}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4" sm="12" md="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label">
                                                Formato*
                                            </label>
                                            <Select2
                                                className="form-control"
                                                value={formatoId}
                                                options={{
                                                    placeholder: "Selecione um formato..."
                                                }}
                                                onSelect={e => setFormatoId(e.target.value)}
                                                data={formatos.map(item => ({ id: item.id, text: item.nome }))}
                                            />
                                            <small class="text-danger">
                                                {erros.formato_id || ''}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4" sm="12" md="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label">
                                                Tráfego*
                                            </label>
                                            <Select2
                                                className="form-control"
                                                value={trafegoId}
                                                options={{
                                                    placeholder: "Selecione um formato..."
                                                }}
                                                onSelect={e => setTrafegoId(e.target.value)}
                                                data={trafegos.map(item => ({ id: item.id, text: item.nome }))}
                                            />
                                            <small class="text-danger">
                                                {erros.trafego_id || ''}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" sm="12" md="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label">
                                                Destinos*
                                            </label>
                                            <Row>
                                                {
                                                    destinos.map((item, key) => (
                                                        <Col
                                                            key={key}
                                                            className="btn-group-toggle"
                                                            style={{
                                                                paddingBottom: 12
                                                            }}
                                                        >
                                                            <Button
                                                                className={classnames({ active: destinoId?.includes(item.id), 'btn-icon': true })}
                                                                color="secondary"
                                                                tag="label"
                                                            >
                                                                <i class={item.icone}></i>
                                                                <input
                                                                    autoComplete="off"
                                                                    type="checkbox"
                                                                    value={destinoId?.includes(item.id)}
                                                                    onClick={e => {
                                                                        if (destinoId?.includes(item.id)) {
                                                                            let v = destinoId?.filter(it => it != item.id)
                                                                            setDestinoId(v)
                                                                        } else {
                                                                            let v = destinoId
                                                                            v.push(item.id)
                                                                            setDestinoId(v)
                                                                        }
                                                                        setDestinos(Array.from(destinos))
                                                                    }}
                                                                />
                                                                {item.nome}
                                                            </Button>

                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                            <small class="text-danger">
                                                {erros.destinos || ''}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6" sm="12" md="12">
                                        <FormGroup>
                                            <label className="form-control-label">
                                                Pilar*
                                            </label>
                                            <Select2
                                                className="form-control"
                                                value={pilarId}
                                                options={{
                                                    placeholder: "Selecione um pilar..."
                                                }}
                                                onSelect={e => setPilarId(e.target.value)}
                                                data={pilares.map(item => ({ id: item.id, text: item.nome }))}
                                            />
                                            <small class="text-danger">
                                                {erros.pilar_id || ''}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6" sm="12" md="12">
                                        <FormGroup>
                                            <label className="form-control-label">
                                                <span>Marcações</span>
                                            </label>
                                            <AttachMarcacao
                                                showAddButton={true}
                                                onChange={(obj) => setMarcacoes(obj.target.value)}
                                                publicacaoId={publicacao.id}
                                                marcacoes={marcacoes}
                                            />
                                            <small class="text-danger">
                                                {erros.marcacoes || ''}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" sm="12" md="12">
                                        <FormGroup>
                                            <div className="d-flex justify-content-between">
                                                <label className="form-control-label d-flex justify-content-between">
                                                    <div>
                                                        Corpo (legenda){hasFeedSelected() ? '*' : ''}
                                                        <Button
                                                            color="secondary"
                                                            id="tooltipLegenda"
                                                            outline
                                                            size="sm"
                                                            type="button">
                                                            ?
                                                        </Button>
                                                        <UncontrolledPopover placement="top" target="tooltipLegenda">
                                                            <PopoverBody>
                                                                Informe o texto que será apresentado no corpo da
                                                                publicação...
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    </div>
                                                </label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {empresaSelecionada && empresaSelecionada.id && empresaSelecionada.cabecalho_publicacao &&
                                                        <div className="custom-control custom-checkbox" style={{ marginBottom: -5 }}>
                                                            <input
                                                                className="custom-control-input"
                                                                id="check-cabecalho"
                                                                type="checkbox"
                                                                checked={showCabecalhoPadrao}
                                                                onChange={() => setShowCabecalhoPadrao(!showCabecalhoPadrao)}
                                                            />
                                                            <label
                                                                className="custom-control-label" htmlFor="check-cabecalho">
                                                                Inserir Cabeçalho Padrão
                                                            </label>
                                                        </div>
                                                    }
                                                    <Badge
                                                        style={{ marginLeft: 10 }}
                                                        size='sm'
                                                        color="secondary"
                                                        title={`${corpoTexto?.replace(/<[^>]*>/g, '').length || 0} caracteres no corpo`}
                                                        className="badge-md badge-circle badge-floating border-white">
                                                        {corpoTexto?.replace(/<[^>]*>/g, '')?.length || 0}
                                                    </Badge>
                                                </div>
                                            </div>
                                            <ReactQuill
                                                value={corpoTexto || ""}
                                                theme="snow"
                                                modules={{
                                                    toolbar: [
                                                        ["bold", "italic"],
                                                        ["link", "blockquote", "code"],
                                                        [
                                                            {
                                                                list: "ordered"
                                                            },
                                                            {
                                                                list: "bullet"
                                                            }
                                                        ]
                                                    ]
                                                }}
                                                onChange={v => setCorpoTexto(v)}
                                            />
                                            <div className="d-flex justify-content-between">
                                                <small class="text-danger">
                                                    {erros.corpo_texto || ''}
                                                </small>
                                                {empresaSelecionada && empresaSelecionada.id && empresaSelecionada.rodape_publicacao &&
                                                    <div className="custom-control custom-checkbox" style={{ marginTop: 8, marginRight: 52 }}>
                                                        <input
                                                            className="custom-control-input"
                                                            id="check-rodape"
                                                            type="checkbox"
                                                            checked={showRodapePadrao}
                                                            onChange={() => setShowRodapePadrao(!showRodapePadrao)}
                                                        />
                                                        <label
                                                            className="custom-control-label" htmlFor="check-rodape">
                                                            Inserir Rodapé Padrão
                                                        </label>
                                                    </div>
                                                }
                                            </div>

                                        </FormGroup>
                                    </Col>
                                    <Col lg="12" sm="12" md="12">
                                        <FormGroup>
                                            <label className="form-control-label d-flex justify-content-between">
                                                <div>
                                                    {`Texto ${formatos.find(it => it.id == formatoId) ? formatos.find(it => it.id == formatoId).nome : 'corpo'}`}
                                                    <Button
                                                        color="secondary"
                                                        id="tooltip87627934922"
                                                        outline
                                                        size="sm"
                                                        type="button">
                                                        ?
                                                    </Button>
                                                    <UncontrolledPopover placement="top" target="tooltip87627934922">
                                                        <PopoverBody>
                                                            Informe o texto que será adicionado na peça que
                                                            será produzida...
                                                        </PopoverBody>
                                                    </UncontrolledPopover>
                                                </div>
                                                <Badge
                                                    size='sm'
                                                    color="secondary"
                                                    title={`${textoImagem?.replace(/<[^>]*>/g, '')?.length || 0} caracteres `}
                                                    className="badge-md badge-circle badge-floating border-white">
                                                    {textoImagem?.replace(/<[^>]*>/g, '')?.length || 0}
                                                </Badge>
                                            </label>
                                            <ReactQuill
                                                value={textoImagem || ""}
                                                theme="snow"
                                                modules={{
                                                    toolbar: [
                                                        ["bold", "italic"],
                                                        ["link", "blockquote", "code"],
                                                        [
                                                            {
                                                                list: "ordered"
                                                            },
                                                            {
                                                                list: "bullet"
                                                            }
                                                        ]
                                                    ]
                                                }}
                                                onChange={v => setTextoImagem(v)}
                                            />
                                            <small class="text-danger">
                                                {erros.texto_imagem || ''}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='12' md='12' lg='12' >
                                        <FormGroup>
                                            <label className="form-control-label">
                                                Sugestões
                                                <Button
                                                    color="secondary"
                                                    id="tooltip8762793492244"
                                                    outline
                                                    size="sm"
                                                    type="button">
                                                    ?
                                                </Button>
                                                <UncontrolledPopover placement="top" target="tooltip8762793492244">
                                                    <PopoverBody>
                                                        Adicione sugestões de como a peça pode ser produzida
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                            </label>
                                            <ReactQuill
                                                value={sugestao || ""}
                                                theme="snow"
                                                modules={{
                                                    toolbar: [
                                                        ["bold", "italic"],
                                                        ["link", "blockquote", "code"],
                                                        [
                                                            {
                                                                list: "ordered"
                                                            },
                                                            {
                                                                list: "bullet"
                                                            }
                                                        ]
                                                    ]
                                                }}
                                                onChange={v => setSugestao(v)}
                                            />
                                            <small class="text-danger">
                                                {erros.sugestao || ''}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='12' md='6' lg='6'>
                                        <FormGroup>
                                            <label />
                                            <DropZone
                                                alert={setAlert}
                                                clearAutoReloadList={() => setUploadProgress({
                                                    ...uploadProgress,
                                                    reloadList: false
                                                })}
                                                publicacao={publicacao}
                                                uploadProgress={uploadProgress}
                                                notify={notify}
                                                onFileListChange={(files) => {
                                                    setImagens(files)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='12' md='6' lg='6'>
                                        <FormGroup>
                                            <label />
                                            <Links
                                                publicacaoId={publicacao.id ? publicacao.id : null}
                                                alert={setAlert}
                                                notify={notify}
                                                onChangeLinkList={setLinks}
                                            />

                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter
                                className=""
                                style={{
                                    position: "sticky",
                                    bottom: 0,
                                    border: "2 solid",
                                    boxShadow: "0px 0px 2px 2px #f5f5f5",
                                    zIndex: 50,
                                }}
                            >
                                <Row>
                                    <PonderarButton
                                        publicacao={publicacao}
                                        alert={setAlert}
                                        onError={(msg) => notify('danger', msg)}
                                        onSuccess={successAlert}
                                    />
                                    <Col>
                                        <div
                                            className="float-right "
                                            style={{
                                                flexWrap: 'wrap',
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <Button
                                                className="ml-auto mt-1"
                                                color="link"
                                                data-dismiss="modal"
                                                type="button"
                                                onClick={() => redirectBack()}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                id={`salvar-publicacao-${publicacao.id}`}
                                                className=" mt-1"
                                                disabled={saving}
                                                color="primary"
                                                onClick={handleSave}
                                            >
                                                <Spinner
                                                    hidden={!saving}
                                                    className="mr-2"
                                                    color="light"
                                                    size="sm"
                                                />
                                                Salvar
                                            </Button>
                                            {
                                                getNextFase() &&
                                                <Button
                                                    className=" mt-1"
                                                    disabled={saving}
                                                    id={`salvar-avancar-publicacao-${publicacao.id}`}
                                                    color="primary"
                                                    onClick={handleSaveAvancar}
                                                >
                                                    <Spinner
                                                        hidden={!saving}
                                                        className="mr-2"
                                                        color="light"
                                                        size="sm"
                                                    />
                                                    Salvar e Avançar
                                                </Button>
                                            }
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={`salvar-publicacao-${publicacao.id}`}
                                            >
                                                Salvar publicação
                                            </UncontrolledTooltip>
                                            {
                                                getNextFase() &&
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    placement="top"
                                                    target={`salvar-avancar-publicacao-${publicacao.id}`}
                                                >
                                                    {`Salvar publicação e avançar para fase ${getNextFase().nome}`}
                                                </UncontrolledTooltip>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
