import React from 'react';
import { hasPermission, getContrastYIQ } from '../../../utils';
import moment from 'moment'

import { MenuComportamento } from '../../../components/Menus'
import Avatar from '../../../components/Avatar'
import {
    Badge,
    UncontrolledTooltip
} from "reactstrap";

export const createColumns = ({ edit, printThis, isToPrint, isConversao, setComentariosOportunidade}) => {

    let copy = getColumns(isConversao, setComentariosOportunidade)

    if (edit) {
        if (copy[0].dataField !== 'acoes') {
            copy.unshift({
                dataField: 'acoes',
                formatter: (cell, row) => acoesFormatter(row, edit, printThis),
                csvExport: false
            })
        }
    }
    if (isToPrint) {
        const telefones = copy.find(item => item.dataField === 'telefones')
        const email = copy.find(item => item.dataField === 'cliente.email')
        if (!email) {
            copy = insert(copy, 2, {
                dataField: 'cliente.email',
                text: 'Email',
                align: 'center',
                headerAlign: 'center',
                csvExport: true,
            })
        }
        if (!telefones) {
            copy = insert(copy, 2, {
                dataField: 'telefones',
                text: 'Telefones',
                align: 'center',
                headerAlign: 'center',
                csvExport: true,
                csvFormatter: (cell, row) => telefonesFormaterCsv(cell, row),
                formatter: (row, column) => telefonesFormaterCsv(row, column)
            })
        }
    }
    return copy
}

const getColumns = (isConversao, setComentariosOportunidade) => {
    const columns = [
        isConversao ? getColumnDataEncerramento() : getColumnDataAgendamento(),
        {
            dataField: "cliente.nome",
            text: 'Cliente',
            sort: true
        },
        {
            dataField: 'anuncio.nome',
            text: 'Anúncio',
            formatter: (row, column) => anuncioFormater(row, column),
            csvFormatter: (cell, row) => anuncioFormatterCsv(cell, row),
            sort: true
        },
        {
            dataField: 'estagio.nome',
            text: 'Estágio',
            align: 'center',
            headerAlign: 'center',
            formatter: (row, column) => estagioFormatter(row, column),
            csvFormatter: (cell, row) => estagioFormatterCsv(cell, row),
            sort: true
        },
        {
            dataField: 'expectativa.nome',
            text: 'Expectativa',
            formatter: (row, column) => expectativaFormatter(row, column),
            csvFormatter: (cell, row) => expectativaFormatterCsv(cell, row),
            align: 'center',
            headerAlign: 'center',
            sort: true
        },
        {
            dataField: 'motivo_encerramento_id',
            text: 'Situação',
            formatter: (row, column) => situacaoFormater(row, column),
            csvFormatter: (cell, row) => situacaoFormatterCsv(cell, row),
            align: 'center',
            headerAlign: 'center',
            sort: true
        },
        {
            dataField: '__meta__.total_comentarios',
            text: 'Contactações',
            title: 'Quantidade',
            align: 'center',
            headerAlign: 'center',
            // sort: true,
            csvType: Number,
            headerFormatter: contacatacaoHeader,
            formatter: (row, column) => contactacaoFormatter(row, column, setComentariosOportunidade),
        },
        {
            dataField: '__meta__.valor',
            text: 'Valor',
            title: 'Valor',
            align: 'center',
            headerAlign: 'center',
            // sort: true,
            formatter: (row, column) => valorFormatter(row, column),
            csvFormatter: (row, column) => valorFormatter(row, column),
            headerFormatter: valorHeader
        },
        hasPermission('ver-todas-oportunidades') ? getColumnColaborador() : {}
    ]

    return columns
}

const acoesFormatter = (row, edit, printThis) => {

    return (
        <MenuComportamento
            actions={[{
                label: 'Alterar',
                icon: 'far fa-edit',
                onClick: () => edit(row.id)
            },
            {
                label: 'Imprimir',
                icon: 'fas fa-print',
                onClick: () => printThis(row.id)
            }]}
        />
    )
}

const valorFormatter = (cell, row) => {
    return (
        <>
            {Number(row.__meta__.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </>
    )
}

const contactacaoFormatter = (cell, row, setComentariosOportunidade) => {
    return (

        setComentariosOportunidade ?
            <>
                <a href='#' onClick={() => comentarioAction(row, setComentariosOportunidade)} >{row.__meta__.total_comentarios}</a>
            </>
            :
            <>
                {row.__meta__.total_comentarios}
            </>
    )
}

function comentarioAction(oportunidade, setComentariosOportunidade) {
    console.log('ENTROU COMENTARIOS ACTIONS')
    console.log({ oportunidade })
    setComentariosOportunidade(oportunidade)
}

function contacatacaoHeader(column) {
    return (
        <>
            <div
                id={`column-${column.text}`}
            >
                {column.text}
            </div>
            <UncontrolledTooltip
                target={`column-${column.text}`}
                placement='top'
            >
                Quantidade de contactações
            </UncontrolledTooltip>
        </>
    );
}

function valorHeader(column) {
    return (
        <>
            <div
                id={`column-${column.text}`}
            >
                {column.text}
            </div>
            <UncontrolledTooltip
                target={`column-${column.text}`}
                placement='top'
            >
                Valor do(s) produto(s) desta oportunidade
            </UncontrolledTooltip>
        </>
    );
}

function getColumnColaborador() {
    return ({
        dataField: 'user.name',
        text: 'Colaborador',
        formatter: (row, column) => colaboradorFormatter(row, column),
        csvFormatter: (cell, row) => colaboradorFormatterCsv(cell, row),
        align: 'center',
        headerAlign: 'center',
        sort: true
    })
}

function getColumnDataAgendamento() {
    return (
        {
            dataField: 'data_agendamento',
            text: 'Data de Agendamento',
            formatter: (row, column) => dataAgendamento(row, column),
            csvFormatter: (cell, row) => dataAgendamentoCsv(cell, row),
            csvType: Date,
            sort: true
        }
    )
}

function getColumnDataEncerramento() {
    return (
        {
            dataField: 'data_encerramento',
            text: 'Data de Conversão',
            formatter: (row, column) => dataEncerramento(row, column),
            csvFormatter: (cell, row) => dataEncerramento(cell, row),
            csvType: Date,
            sort: true
        }
    )
}

const colaboradorFormatter = (cell, row) => (
    <>
        {
            row.user &&
            <Avatar
                title={row.user.name}
                user={row.user}
                className='avatar-xs'
                style={{
                    cursor: 'default'
                }}
            />
        }
    </>
)

const dataAgendamento = (cell, row) => {
    if (!row.data_agendamento) {
        return
    }
    const dataAgendamento = moment(row.data_agendamento)
    const diff = row.data_encerramento ? 100 : dataAgendamento.diff(moment(), 'minutes')
    const timeLimit = 30

    return <>
        <span id={`agendamento-${row.id}`}>
            {
                (diff <= timeLimit) &&
                <span className="text-warning mr-1"><i className="fas fa-exclamation-circle"></i></span>
            }
            {dataAgendamento.format('D MMM YYYY HH:mm')}
        </span>
        {
            (diff <= timeLimit) &&
            <UncontrolledTooltip
                target={`agendamento-${row.id}`}
                placement='top'
            >
                {
                    diff < 0 ?
                        `Atrasada ${dataAgendamento.fromNow(false)}`
                        :
                        `Restam menos de ${diff} minutos para o atendimento`
                }
            </UncontrolledTooltip>
        }

    </>
}

const dataEncerramento = (cell, row) => {
    if (!row.data_encerramento) {
        return
    }
    const dataEncerramento = moment(row.data_encerramento)
    return dataEncerramento.format('D MMM YYYY HH:mm')
}


const situacaoFormater = (cell, row) => (
    <>
        <Badge
            id={`denc-${row.id}`}
            color={row.data_encerramento ? 'danger' : 'success'}
            className="badge-lg"
            pill
        >
            {row.data_encerramento ? 'Encerrado' : 'Aberto'}
        </Badge>
        {
            row?.motivoEncerramento &&
            <UncontrolledTooltip
                target={`denc-${row.id}`}
                placement='top'
            >
                {row.motivoEncerramento.nome}
            </UncontrolledTooltip>
        }
    </>
)


const expectativaFormatter = (cell, row) => (
    <Badge
        color='primary'
        className="badge-lg"
        pill
    >
        {(row.expectativa.nome.toLowerCase() === 'alta') &&
            <i className="fas fa-fire mr-2" ></i>
        }
        {row.expectativa.nome}
    </Badge>
)

const estagioFormatter = (cell, row) => (
    <>
        <Badge
            pill
            id={`est-${row.id}`}
            //color={row.estagio.cor}
            color={row.estagio.cor.includes('#') ? 'secondary' : row.estagio.cor}
            style={row.estagio.cor.includes('#') ? { backgroundColor: row.estagio.cor, color: getContrastYIQ(row.estagio.cor) } : {}}
            className="badge-lg" >
            {
                row?.motivoEncerramento && !row?.data_encerramento &&
                <i className='far fa-bookmark mr-2'></i>
            }
            {row.estagio.nome}
        </Badge>
        {
            row?.motivoEncerramento &&
            <UncontrolledTooltip
                target={`est-${row.id}`}
                placement='top'
            >
                {row.motivoEncerramento.nome}
            </UncontrolledTooltip>
        }
    </>
)

const anuncioFormater = (cell, row) => (
    <div
        title={row.anuncio.campanha.nome}
    >
        {row.anuncio.nome}
    </div>
)

const anuncioFormatterCsv = (cell, row) => (
    row.anuncio.nome
)

const estagioFormatterCsv = (cell, row) => (
    row.estagio.nome
)

const expectativaFormatterCsv = (cell, row) => (
    row.expectativa.nome
)

const situacaoFormatterCsv = (cell, row) => (
    row.motivo_encerramento_id ? 'Encerrado' : 'Aberto'
)

const colaboradorFormatterCsv = (cell, row) => (
    row.user_id ? row.user.name : ''
)


const dataAgendamentoCsv = (cell, row) => {
    if (!row.data_agendamento) {
        return ''
    }
    const dataAgendamento = moment(row.data_agendamento)
    return dataAgendamento.format('D/MM/YYYY HH:mm')
}


const telefonesFormaterCsv = (cell, row) => (
    row?.cliente?.telefones.map(item => ` (${item.ddd}) ${item.numero}`).join()
)


const telefonesFormater = (cell, row, showTelefones) => {
    return (
        <a
            href='#'
            onClick={(e) => {
                e.preventDefault(); showTelefones(row.cliente_id)
            }}>
            {row.__meta__?.total_telefones}
        </a >
    )
}

const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
]