import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import Preview from "./Preview";
import Avatar from "../../../components/Avatar";
import { MenuComportamento } from "../../../components/Menus";
import EditarIdCanal from "./EditarIdCanal";
import VincularAnuncio from "./VincularAnuncio";
import SweetAlert from "react-bootstrap-sweetalert";

// reactstrap components
import { Badge, Button, ButtonGroup, Spinner } from "reactstrap";

// import { Container } from './styles';

export default ({
  publicacoes,
  pageProperties,
  notify,
  search,
  onBackAnuncio,
  anuncio,
  ...props
}) => {
  const [publicacaoPreviewId, setPublicacaoPreviewId] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showEditarIdCanal, setShowEditarIdCanal] = useState(false);
  const [showVincular, setShowVincular] = useState(false);
  const [publicacao, setPublicacao] = useState(null);
  const [showSincronizarLeads, setShowSincronizarLeads] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redeSocialId, setRedeSocialId] = useState(null);
  const [syncFacebookResponseAlert, setSyncFacebookResponseAlert] =
    useState(null);

  const [columns, setColumns] = useState([
    {
      dataField: "acoes",
      formatter: (cell, row) => AcoesFormatter({ cell, row, context: this }),
    },
    {
      dataField: "titulo",
      text: "Título",
      sort: true,
    },
    {
      dataField: "trafego.nome",
      text: "Tráfego",
    },
    {
      dataField: "designerResponsavel.name",
      text: "Designer",
      align: "center",
      headerAlign: "center",
      formatter: (row, column) => designerFormatter(row, column),
    },
    {
      dataField: "data_postagem",
      text: "Data Postagem",
      formatter: (row, column) => dataCriacaoFormatter(row, column),
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "situacao",
      text: "Situação",
      formatter: (row, column) => situacaoFormatter(row, column),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "faseAtual.nome",
      text: "Fase Atual",
      formatter: (row, column) => faseFormatter(row, column),
      align: "center",
      headerAlign: "center",
    },
  ]);

  useEffect(() => {
    // console.log('USE EFFECT ANUNCIO TABLE')
    // console.log(anuncio)

    setColumns([
      {
        dataField: "acoes",
        formatter: (cell, row) => AcoesFormatter({ cell, row, context: this }),
      },
      {
        dataField: "titulo",
        text: "Título",
        sort: true,
      },
      {
        dataField: "trafego.nome",
        text: "Tráfego",
      },
      {
        dataField: "designerResponsavel.name",
        text: "Designer",
        align: "center",
        headerAlign: "center",
        formatter: (row, column) => designerFormatter(row, column),
      },
      {
        dataField: "data_postagem",
        text: "Data Postagem",
        formatter: (row, column) => dataCriacaoFormatter(row, column),
        align: "center",
        headerAlign: "center",
        sort: true,
      },
      {
        dataField: "situacao",
        text: "Situação",
        formatter: (row, column) => situacaoFormatter(row, column),
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "faseAtual.nome",
        text: "Fase Atual",
        formatter: (row, column) => faseFormatter(row, column),
        align: "center",
        headerAlign: "center",
      },
    ]);
  }, [anuncio]);

  const dataCriacaoFormatter = (cell, row) => {
    return <>{moment(row.data_postagem).format("D MMM YYYY HH:mm")}</>;
  };

  const designerFormatter = (cell, row) => (
    <>
      {row.designer_responsavel_id && (
        <Avatar
          title={row.designerResponsavel.name}
          user={row.designerResponsavel}
          className="avatar-xs"
          style={{
            cursor: "default",
          }}
        />
      )}
    </>
  );

  const AcoesFormatter = ({ cell, row, context }) => {
    function createActions() {
      const actions = [];

      if (anuncio) {
        if (row.anuncio_id && row.anuncio_id > 0) {
          actions.push({
            label: "Alterar",
            icon: "far fa-edit",
            onClick: () => goToEdit(row.id),
          });

          actions.push({
            label: "Editar ID Canal",
            icon: "far fa-edit",
            onClick: () => goToEditIdCanal(row),
          });
          if (row.rede_social_id) {
            actions.push({
              label: "Sincronizar leads",
              icon: "fas fa-sync",
              onClick: () => {
                setRedeSocialId(row.rede_social_id);
                setShowSincronizarLeads(true);
              },
            });
          }

          actions.push({
            label: "Clonar",
            icon: "far fa-clone",
            onClick: () => clone(row.id),
          });
        } else {
          actions.push({
            label: "Alterar",
            icon: "far fa-edit",
            onClick: () => goToEdit(row.id),
          });

          actions.push({
            label: "Vincular",
            icon: "far fa-clone",
            onClick: () => goToVincularPublicacao(row),
          });
        }
        return actions;
      } else {
        const actions = [
          {
            label: "Alterar",
            icon: "far fa-edit",
            onClick: () => goToEdit(row.id),
          },
          {
            label: "Clonar",
            icon: "far fa-clone",
            onClick: () => clone(row.id),
          },
        ];
        return actions;
      }
    }

    return (
      <ButtonGroup size="sm">
        <MenuComportamento actions={createActions()} />
        {row.faseAtual.is_aprovado && (
          <Button
            title="Preview"
            color="link"
            onClick={() => {
              setPublicacaoPreviewId(row.id);
              setShowPreview(!showPreview);
            }}
          >
            <i className="far fa-eye"></i>
          </Button>
        )}
      </ButtonGroup>
    );
  };

  const syncFacebook = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`syncFacebookLeads?ad_id=${redeSocialId}`);
      setShowSincronizarLeads(false);
      setSyncFacebookResponseAlert(
        <SweetAlert
          success
          title="Sincronização realizada"
          onConfirm={() => setSyncFacebookResponseAlert(null)}
          //   onCancel={this.onCancel}
        >
          <Badge className="badge-lg" pill>
            {data.novos} novos cadastros
          </Badge>
          <Badge className="badge-lg" pill>
            {data.jaCadastrados} já estavam cadastrados
          </Badge>
          <Badge className="badge-lg" pill>
            {data.jaCadastrados + data.novos} Total sincronizados
          </Badge>
        </SweetAlert>
      );
    } catch (error) {
      setShowSincronizarLeads(false);
      setSyncFacebookResponseAlert(
        <SweetAlert
          danger
          title="Falha na Sincronização"
          onConfirm={() => setSyncFacebookResponseAlert(null)}
        >
          {error.response.data.message}
        </SweetAlert>
      );
    } finally {
      setLoading(false);
    }
  };

  const clone = (id) => {
    props.history.push(`/admin/publicacoes/new`, { toClone: id });
  };

  const faseFormatter = (cell, row) => {
    return (
      <>
        <Badge color="secondary" className="badge-lg" pill>
          {row.faseAtual.nome}
        </Badge>
      </>
    );
  };

  const situacaoFormatter = (cell, row) => {
    let situacao = "";
    let color = "secondary";
    switch (row.situacao) {
      case "A":
        situacao = "Ativo";
        color = "success";
        break;
      case "C":
        situacao = "Cancelada";
        color = "danger";
        break;
      case "P":
        situacao = "Ponderada";
        color = "warning";
        break;
    }
    return (
      <>
        <Badge color={color} className="badge-lg" pill>
          {situacao}
        </Badge>
      </>
    );
  };

  function goToEdit(id) {
    // console.log('ABRINDO EDITAR PUBLICACAO');
    // console.log({props});
    // console.log({anuncio});
    props.history.push(`/admin/publicacoes/${new Number(id)}/edit`, {
      onBackAnuncio: onBackAnuncio,
      anuncio: anuncio,
    });
  }

  async function onSaveEditarIdCanal(publicacao) {
    try {
      // console.log('ALTERANDO PUBLICACAO ID CANAL');
      // console.log(publicacao);
      await api.put(
        `common/empresas/${publicacao?.empresa_id}/publicacoes/${publicacao.id}/updateIdRedeSocial`,
        { ...publicacao }
      );
      notify("success", "ID Canal Alterado com sucesso!");
      setShowEditarIdCanal(false);
      //updateErros(null)
      //await afterSave()
    } catch (error) {
      //setSaving(false)
      console.log(error);
      notify("danger", "Não foi possível alterar o id do canal");
      setShowEditarIdCanal(false);
      //updateErros(error)
    }
  }

  async function onSaveVincular(publicacao) {
    try {
      // console.log('ALTERANDO PUBLICACAO ID CANAL');
      // console.log(publicacao);
      await api.put(
        `common/empresas/${publicacao?.empresa_id}/publicacoes/${publicacao.id}/vincularAnuncio`,
        { ...publicacao }
      );
      notify("success", "Publicação vinculada com sucesso!");
      //updateErros(null)
      //await afterSave()
    } catch (error) {
      //setSaving(false)
      //console.log({error})
      //console.log(error.message)
      if (error.response.data[0].message) {
        notify("danger", error.response.data[0].message);
      } else {
        notify("danger", "Não foi possível vincular a publicação");
      }
      //publicacao.anuncio_id = null;
      //updateErros(error)
    }
    setShowVincular(false);
    props.loadPublicacoes();
  }

  function goToEditIdCanal(publicacao) {
    setPublicacao(publicacao);
    setShowEditarIdCanal(true);
  }

  function goToVincularPublicacao(publicacao) {
    setPublicacao(publicacao);
    setShowVincular(true);
  }

  const RemotePagination = ({
    loading,
    data,
    page,
    sizePerPage,
    onTableChange,
    totalSize,
  }) => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize,
          alwaysShowAllBtns: true,
          showTotal: true,
          withFirstAndLast: true,
          paginationTotalRenderer: (from, to, size) => {
            const margin = {
              paddingLeft: "4px",
            };
            return (
              <span
                className="react-bootstrap-table-pagination-total"
                style={margin}
              >
                Exibindo {from} a {to} do total de {totalSize}.
              </span>
            );
          },
          sizePerPageRenderer: ({
            options,
            currSizePerPage,
            onSizePerPageChange,
          }) => (
            <div className="dataTables_length" id="datatable-basic_length">
              <label>
                Exibir{" "}
                {
                  <select
                    name="datatable-basic_length"
                    value={currSizePerPage}
                    aria-controls="datatable-basic"
                    className="form-control form-control-sm"
                    onChange={(e) => onSizePerPageChange(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                }{" "}
                itens.
              </label>
            </div>
          ),
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              remote
              loading={loading}
              keyField="id"
              //   noDataIndication={() => <NoDataIndication />}
              bootstrap4={true}
              bordered={false}
              data={data}
              onSort={(e) => console.log("iuou", e)}
              columns={columns}
              noDataIndication="Nenhuma publicação encontrada"
              onTableChange={onTableChange}
              overlay={overlayFactory({
                spinner: true,
                styles: {
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(150, 150, 150, 0.5)",
                  }),
                },
              })}
              {...paginationTableProps}
            />
          </div>
        )}
      </PaginationProvider>
    </div>
  );

  return (
    <>
      {syncFacebookResponseAlert}
      {showSincronizarLeads && (
        <SweetAlert
          custom={loading}
          warning={!loading}
          showCancel={!loading}
          showConfirm={!loading}
          confirmBtnText={loading ? "Sincronizando..." : "Sincronizar"}
          confirmBtnBsStyle="primary"
          title={loading ? "Sincronizando leads..." : "Sincronizar leads"}
          onConfirm={syncFacebook}
          onCancel={() => setShowSincronizarLeads(false)}
          preConfirm={() => alert()}
        >
          {!loading &&
            `Deseja sincronizar os leads do playnee com sua fonte de captação?`}
          <Spinner
            hidden={!loading}
            className="mr-2"
            color="primary"
            size="lg"
          />
        </SweetAlert>
      )}
      <EditarIdCanal
        confirmButtonText="Salvar"
        publicacao={publicacao}
        anuncio={anuncio}
        show={showEditarIdCanal}
        onHidden={() => {
          setShowEditarIdCanal(false);
        }}
        onSync={syncFacebook}
        onConfirm={(data) => {
          onSaveEditarIdCanal(data);
        }}
      />
      <VincularAnuncio
        confirmButtonText="Vincular"
        publicacao={publicacao}
        anuncio={anuncio}
        show={showVincular}
        onHidden={() => {
          setShowVincular(false);
        }}
        onConfirm={(data) => {
          onSaveVincular(data);
        }}
      />
      <Preview
        isOpen={showPreview}
        showHistorico={true}
        close={() => {
          setShowPreview(!showPreview);
          setPublicacaoPreviewId(null);
        }}
        publicacaoId={publicacaoPreviewId}
        onChangePublicacao={search}
      />
      {publicacoes && pageProperties && (
        <RemotePagination
          data={publicacoes}
          page={pageProperties.page}
          sizePerPage={pageProperties.perPage}
          totalSize={pageProperties.total}
          onTableChange={props.onTableChange}
          loading={pageProperties.loading}
        />
      )}
    </>
  );
};
