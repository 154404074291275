import { ListGroupItem } from "reactstrap";
import styled from "styled-components";

interface Props {
  isSelected: boolean;
}

export const Container = styled(ListGroupItem)<Props>`
  cursor: pointer;
  padding: 8px 4px;
  min-height: 72px;
  font-size: 0.85rem;

  background-color: ${({ isSelected }) => (isSelected ? "#cecece40" : "#fff")};

  .contact {
    &--info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
    }

    &-name {
      margin-right: 8px;
      font-weight: 600;
    }

    &-badge {
      color: #fff;
    }

    &-message {
      textoverflow: ellipsis;
      overflow: hidden;
      whitespace: nowrap;
      font-size: .75rem;
      font-weight: normal;
    }
  }
`;
